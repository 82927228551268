import styled from 'styled-components';

export const FaqContent = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
`;

export const Faq = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px;
    margin-top: 70px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        border-radius: 30px 30px 0 0;
    }

    h2 {
        color: var(--black);
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-family: var(--font-bold);
        margin-bottom: 24px;
        max-width: 90%;
    }
`;

export const FAQItem = styled.div`
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid #88A8A4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;

    @media only screen and (min-width: 960px) {
        border: 1px solid #88A8A4;
        padding: 22px 23px 19px 23px;
        margin: 0;

        &:first-of-type {
            border-radius: 10px 10px 0px 0px;
            margin-top: 16px;
        }

        &:not(:first-of-type) {
            border-top: none;
        }

        &:last-of-type {
            border-radius: 0px 0px 10px 10px;
        }
    }
`

export const FAQItemQuestion = styled.div<{ $isToggled: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom:${({ $isToggled }) => ($isToggled ? `5px` : `0`)};

    p {
        color: var(--black);
        font-size: 1rem;
        line-height: 1.125rem;
        font-family: var(--font-bold);
        max-width: 65%;
    }
    img {
        width: 14px;
        height: 14px;
        transition: transform 0.3s ease;
        transform: rotate(${({ $isToggled }) => ($isToggled ? `180deg` : `0deg`)});
    }

`

export const FAQItemAnswer = styled.div<{ $isShown: boolean }>`
    width: 100%;
    color: var(--black);
    font-size: 0.875rem;
    line-height: 1rem;
    transition: all 0.3s ease;
    display: ${({ $isShown }) => ($isShown ? `block` : `none`)};
    overflow: hidden;
`


export const FaqBottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 78px 36px 36px 36px;
    background-color: #EBF3EF;
    margin-top: -32px;
    position: relative;
    z-index: 2;

    @media only screen and (min-width: 960px) {
        border-radius: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-bottom: 80px;
    }

    div {
        display: flex;
        flex-direction: column;

        h2 {
            color: var(--black);
            font-size: 1.125rem;
            font-family: var(--font-bold);
        }
        p {
            font-size: 1rem;
            color: var(--black);
            line-height: 1.125rem;
            margin-bottom: 26px;

            @media only screen and (min-width: 960px) {
                margin-bottom: 0px;
            }
        }
    }



`;

export const FaqButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: transparent;
    color: var(--primary);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    border: 2px solid var(--primary);
    margin-bottom: 10px;
    max-width: 420px;

    @media only screen and (min-width: 960px) {
        margin-bottom: 0px;
    }
`;

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`