import styled from 'styled-components';

interface CampaignInfosHubItemProps {
    $specieBg: string
}

export const CampaignInfosHubItem = styled.div<CampaignInfosHubItemProps>`
    width: 100%;
    background-color: var(--white);
    padding: 19px 12px;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    background-image: url(${({ $specieBg }) => $specieBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;

    h2 {
        color: #2D473E;
        font-size: 1.5rem;
        font-family: var(--font-bold);
        width: 100%;
        margin-bottom: 8px;
        max-width: 50%;
        line-height: 1.313rem;
    }
    p {
        color: #88A8A4;
        font-size: 1.125rem;
        max-width: 45%;
    }
`;