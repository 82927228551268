import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import checkIcon from '../../assets/icons/check-icon.png'
import notCheckIcon from '../../assets/icons/not-check-icon.png'
import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import PasswordChangedModalContent from '../../components/PasswordChangedModalContent'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import * as S from './style'

enum PasswordButtonState {
    READY,
    INVALID,
    SENDING
}

interface NewPasswordAttributes {
    newPassword: string
    confirmPassword: string
    currentPassword: string
    documentInputDisabled: boolean
    bullets: {
        length: boolean
        hasUpperCase: boolean
        hasLowerCase: boolean
        hasNumber: boolean
    }
    passwordButton: {
        currentState: PasswordButtonState
    }
}

function NewPassword () {
    const [state, setState] = useState<NewPasswordAttributes>({
        newPassword: '',
        confirmPassword: '',
        currentPassword: '',
        documentInputDisabled: true,
        bullets: {
            length: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
        },
        passwordButton: {
            currentState: PasswordButtonState.INVALID
        }
    })
    const { showBottomModal } = useBottomModal()

    const validate = useCallback((newState: NewPasswordAttributes) => {
        if (!newState.newPassword) {
            newState.bullets.length = false
            newState.bullets.hasUpperCase = false
            newState.bullets.hasLowerCase = false
            newState.bullets.hasNumber = false
            setState(newState)
            return
        }
        newState.passwordButton.currentState = PasswordButtonState.READY
        newState.passwordButton.currentState = PasswordButtonState.READY
        newState.bullets.length = newState.newPassword.length >= 6 && newState.newPassword.length <= 12
        newState.bullets.hasUpperCase = newState.newPassword.match(/[A-Z]/) !== null
        newState.bullets.hasLowerCase = newState.newPassword.match(/[a-z]/) !== null
        newState.bullets.hasNumber = newState.newPassword.match(/[0-9]/) !== null

        const passwordIsNotEquals = newState.newPassword !== newState.confirmPassword
        if (!newState.bullets.length ||
            !newState.bullets.hasUpperCase ||
            !newState.bullets.hasLowerCase ||
            !newState.bullets.hasNumber ||
            passwordIsNotEquals) {
            newState.passwordButton.currentState = PasswordButtonState.INVALID
        }
        setState(newState)
    }, [])

    const handleNewPasswordRequest = useCallback(async () => {
        try {
            await api.patch('/me/password', {
                password: state?.currentPassword,
                newPassword: state?.newPassword
            })
            showBottomModal(<PasswordChangedModalContent />)
        } catch (err) {
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
    }, [showBottomModal, state])

    const handleUpdateState = useCallback((state: NewPasswordAttributes) => {
        setState(state)
        validate(state)
    }, [validate])

    return <S.NewPasswordContent>
        <Header element={<ReturnPageButton />} />
        <S.NewPassword>
            <h2>Cadastre sua nova senha.</h2>
            <p>
                Preencha os campos abaixo e crie sua nova senha.
            </p>
            <InputFloatingLabel inputTitle='Digite sua senha atual' inputType='password' onValueChange={(value: string) => handleUpdateState({ ...state, currentPassword: value })} />
            <InputFloatingLabel inputTitle='Crie sua senha' inputType='password' onValueChange={(value: string) => handleUpdateState({ ...state, newPassword: value })} />
            <InputFloatingLabel inputTitle='Confirme a senha' inputType='password' onValueChange={(value: string) => handleUpdateState({ ...state, confirmPassword: value })} />
            <S.NewPasswordRules>
                <S.NewPasswordRulesTitle>
                    A senha deve conter:
                </S.NewPasswordRulesTitle>
                <S.NewPasswordRulesContent>
                    <S.NewPasswordRulesContentBlock>
                        <div>
                            <img src={state.bullets.length ? checkIcon : notCheckIcon} alt="Regra 1 para prosseguir com a senha: De 6 a 12 caracteres" />
                            <p>De 6 a 12 caracteres</p>
                        </div>
                        <div>
                            <img src={state.bullets.hasUpperCase ? checkIcon : notCheckIcon} alt="Regra 2 para prosseguir com a senha: Ao menos uma letra maicúscula" />
                            <p>Ao menos uma letra maicúscula</p>
                        </div>
                    </S.NewPasswordRulesContentBlock>
                    <S.NewPasswordRulesContentBlock>
                        <div>
                            <img src={state.bullets.hasLowerCase ? checkIcon : notCheckIcon} alt="Regra 3 para prosseguir com a senha: Ao menos uma letra minúscula" />
                            <p>Ao menos uma letra minúscula</p>
                        </div>
                        <div>
                            <img src={state.bullets.hasNumber ? checkIcon : notCheckIcon} alt="Regra 4 para prosseguir com a senha: Ao menos um número" />
                            <p>Ao menos um número</p>
                        </div>
                    </S.NewPasswordRulesContentBlock>
                </S.NewPasswordRulesContent>
            </S.NewPasswordRules>
            <S.NewPasswordSupport>
                Precisa de ajuda? <Link to="/fale-conosco-logged">Fala com a gente!</Link>
            </S.NewPasswordSupport>
            <S.NewPasswordButton onClick={handleNewPasswordRequest}>Enviar</S.NewPasswordButton>
        </S.NewPassword>
        <Footer />
    </S.NewPasswordContent>
}

export default NewPassword