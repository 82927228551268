
import isValidPhone from '@brazilian-utils/is-valid-phone';

class PhoneValidatorAdapter {
  isValid (phoneNumber) {
    return isValidPhone(phoneNumber)
  }
}

export default PhoneValidatorAdapter;
