import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import DesktopBanner from '../../components/DesktopBanner'
import FooterComplete from '../../components/FooterComplete'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import MessageSentModalContent from '../../components/MessageSentModalContent'
import SelectFloatingLabel from '../../components/SelectFloatingLabel'
import TextAreaFloatingLabel from '../../components/TextAreaFloatingLabel'
import { useAuth } from '../../contexts/AuthContext'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import * as S from './style'

const subjectOptions = [
    {
        optionTitle: 'Dúvidas',
        optionValue: 'questions'
    },
    {
        optionTitle: 'Sugestões',
        optionValue: 'suggestions'
    },
    {
        optionTitle: 'Reclamações',
        optionValue: 'complaints'
    }
]

enum State {
    READY,
    SENDING,
    SUCCESS
}

interface MessageStatus {
    state: State,
    message: {
        name: string
        email: string
        phone: string
        assunto: string
        msg: string
    }
}

function ContactUsLogged () {
    const [status, setStatus] = useState<MessageStatus>({
        state: State.READY,
        message: {
            name: '',
            email: '',
            phone: '',
            assunto: '',
            msg: ''
        }
    })
    const { showBottomModal } = useBottomModal()
    const { user } = useAuth()

    const handleContactUsRequest = useCallback(async () => {
        try {
            setStatus((status) => ({
                ...status,
                state: State.SENDING
            }))
            await api.post('/message', status.message)
            setStatus((status) => ({
                ...status,
                state: State.READY,
            }))
            showBottomModal(<MessageSentModalContent />)
        } catch (err) {
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
    }, [showBottomModal, status.message])

    useEffect(() => {
        if (!user) return
        setStatus((status) => ({
            ...status,
            message: {
                ...status.message,
                email: user.email,
                name: user.name,
                phone: user.phone
            }
        }))
    }, [user])

    return <S.ContactUsLoggedContainer>
        <Header element={<MenuButton />} logo={false} pageTitle='Fale conosco' showDesktopWidgets borderBottom />
        <DesktopBanner title='Fale conosco' />
        <S.ContactUsLogged>
            <h2 className='title'>Dúvidas, criticas ou sugestões? Envie sua mensagem pra gente.</h2>
            <S.ContactUsLoggedContent>
                <S.ContactUsLoggedContentForm>
                    <InputFloatingLabel value={status.message.email} inputTitle='E-mail' inputType='text' onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, email: value } }))} />
                    <SelectFloatingLabel selectTitle='Assunto' selectOptions={subjectOptions} onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, assunto: value } }))} />
                    <TextAreaFloatingLabel value={status.message.msg} textAreaTitle='Mensagem' onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, msg: value } }))} />
                    <S.ContactUsLoggedButton onClick={handleContactUsRequest}>Enviar</S.ContactUsLoggedButton>
                </S.ContactUsLoggedContentForm>

                <S.ContactUsLoggedContentContactInfos>
                    <h2>Ou entre em contato:</h2>
                    <p>Telefone: <span>0800 591 2841</span></p>
                    <p>E-mail: <Link to={'mailto:contato@openfarmcargill.com.br'}>contato@openfarmcargill.com.br</Link></p>
                </S.ContactUsLoggedContentContactInfos>
            </S.ContactUsLoggedContent>
        </S.ContactUsLogged>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
    </S.ContactUsLoggedContainer>
}

export default ContactUsLogged