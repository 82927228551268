import styled from 'styled-components';

export const SelectFloatingLabel = styled.div`
    border: 1px solid #88A8A4;
    border-radius: 28px;
    padding: 26px 24px 10px 24px;
    width: 100%;
    margin-bottom: 24px;
    position: relative;
    height: 56px;
    h3 {
        position: absolute;
        color: var(--black);
        font-size: 0.75rem;
        top: 10px;
        left: 24px;
        transition: all 0.3s ease-in-out;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: var(--black);
        font-size: 1rem;
        width: 100%;
        border-radius: 0px;
        z-index: 10;
        position: relative;
        background: transparent;
        font-family: var(--font-bold);
        cursor: pointer;
    }

    img {
        position: absolute;
        right: 22px;
        top: 24px;
        width: 14px;
    }
`;