import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import SideMenu from "../../components/SideMenu";

interface SideMenuContextProps {
    isSideMenuOpen: boolean;
    toggleSideMenu: () => void;
}

interface SideMenuProviderProps {
    children: ReactNode
}

const SideMenuContext = createContext<SideMenuContextProps | undefined>(undefined)


function SideMenuProvider ({ children }: SideMenuProviderProps) {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  
    const toggleSideMenu = useCallback(() =>{
        setIsSideMenuOpen(prevState => !prevState);
        !isSideMenuOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
        
    }, [isSideMenuOpen])
  
    return <SideMenuContext.Provider value={{ isSideMenuOpen, toggleSideMenu }}>
        <SideMenu />
        {children}
    </SideMenuContext.Provider>
};

export const useSideMenu = () => {
    const context = useContext(SideMenuContext);
    if (!context) {
        throw new Error('useSideMenu must be used within a MenuProvider');
    }
    return context;
};

export default SideMenuProvider