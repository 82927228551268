import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import Loader from '../Loader'
import * as S from './style'

enum PageState {
    LOADING,
    READY
}

interface RegulamentoState {
    currentState: PageState
    data: any
}

function RegulamentoContent () {
    const [state, setState] = useState<RegulamentoState>({
        currentState: PageState.LOADING,
        data: undefined
    })
    const { closeBottomModal } = useBottomModal()

    useEffect(() => {
        (async () => {
            try {
              const response = await api.get('/regulations')
              const regulationIndex = response.data.findIndex((c: { id: number }) => c.id === 1)
              setState({
                currentState: PageState.READY,
                data: response.data[regulationIndex].content
            })
            } catch (error) {
              console.error(error)
              toast.error('Não foi possível carregar o regulamento. Por favor, tente novamente.', {
                theme: 'colored'
              })
            }
          })()
    }, [])

    if (state.currentState === PageState.LOADING) return <Loader />

    return <S.RegulamentoContent>
        <S.RegulamentoContentTitle>Regulamento</S.RegulamentoContentTitle>
        <S.RegulamentoContentText dangerouslySetInnerHTML={{ __html: state.data }}></S.RegulamentoContentText>
        <S.RegulamentoContentButtons>
            <button className='button-outlined' onClick={closeBottomModal}>Entendi!</button>
        </S.RegulamentoContentButtons>
    </S.RegulamentoContent>
}

export default RegulamentoContent