import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import Loader from '../../components/Loader'
import LoaderWithBackdrop from '../../components/LoaderWithBackdrop'
import { RepublicaFetchAddress } from '../../data/use-case/RepublicaFetchAddress'
import api from '../../services/api'
import { MaskCEP, MaskCNPJ, MaskDate, MaskUF } from '../../utils/mask'
import * as S from './style'

enum State {
    LOADING,
    READY,
    SENDING,
    SUCCESS,
    ERROR
}

interface NewFilialAttributes {
    currentState: State,
    data: {
        cnpj: string
        name: string
        email: string
        abertura: string
        cep: string
        logradouro: string
        numero: string
        complemento: string
        bairro: string
        cidade: string
        estado: string
    }
}

const fetchAddress = new RepublicaFetchAddress()

function NewFilial () {
    const [state, setState] = useState<NewFilialAttributes>({
        currentState: State.READY,
        data: {
            cnpj: '',
            name: '',
            email: '',
            abertura: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: ''
        }
    })
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const handleNewFilialRequest = useCallback(async () => {
        try {
            setState((state) => ({ ...state, currentState: State.SENDING }))
            await api.post('/filiais', state.data)
            toast.success('Sua filial foi cadastrada com sucesso', { theme: 'colored' })
            navigate('/filiais')
        } catch (err) {
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
        setState((state) => ({ ...state, currentState: State.READY }))
    }, [navigate, state.data])

    const handleChangeCEP = useCallback(async (cep: string) => {
        setState((state) => ({ ...state, data: { ...state.data, cep }}))

        if (cep.length === 9) {
            setLoading(true)
            try {
                const response = await fetchAddress.handle(cep)
                setState((state) => ({
                    ...state,
                    data: {
                        ...state.data,
                        bairro: response.bairro,
                        cidade: response.cidade,
                        estado: response.uf,
                        logradouro: response.logradouro
                    }
                }))
            } catch (error) {
                console.error(error)
            }
            setLoading(false)
        }
    }, [])

    if (state.currentState === State.LOADING) return <Loader />

    return <S.NewAddressContent>
        <Header element={<ReturnPageButton />} logo={false} pageTitle='Cadastrar nova filial' />
        <S.NewAddress>
            <InputFloatingLabel value={state.data.cnpj} inputTitle='CNPJ' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, cnpj: value } })} mask={MaskCNPJ} />
            <InputFloatingLabel value={state.data.name} inputTitle='Nome' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, name: value } })} />
            <InputFloatingLabel value={state.data.email} inputTitle='Email' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, email: value } })} />
            <InputFloatingLabel value={state.data.abertura} inputTitle='Data de abertura' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, abertura: value } })} mask={MaskDate} />
            <InputFloatingLabel value={state.data.cep} inputTitle='CEP' inputType='text' onValueChange={handleChangeCEP} mask={MaskCEP} />
            <InputFloatingLabel value={state.data.logradouro} inputTitle='Rua' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, logradouro: value } })} />
            <S.NewAddressBlock>
                <InputFloatingLabel value={state.data.numero} inputTitle='Número' inputType='number' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, numero: value } })} />
                <InputFloatingLabel value={state.data.complemento} inputTitle='Complemento' inputType='text' maxLength={20} onValueChange={(value: string) => setState({ ...state, data: { ...state.data, complemento: value } })} />
            </S.NewAddressBlock>
            <InputFloatingLabel value={state.data.bairro} inputTitle='Bairro' inputType='text' maxLength={20} onValueChange={(value: string) => setState({ ...state, data: { ...state.data, bairro: value } })} />
            <InputFloatingLabel value={state.data.cidade} inputTitle='Cidade' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, cidade: value } })} />
            <InputFloatingLabel value={state.data.estado} inputTitle='Estado' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, estado: value } })} mask={MaskUF} />
            <S.NewAddressButton onClick={handleNewFilialRequest} disabled={state.currentState === State.SENDING}>{state.currentState === State.SENDING ? 'Enviando' : 'Enviar'}</S.NewAddressButton>
        </S.NewAddress>
        {loading && <LoaderWithBackdrop />}
    </S.NewAddressContent>
}

export default NewFilial