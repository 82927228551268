import styled, { keyframes } from 'styled-components';

export const BottomModalBackdrop = styled.div<{ $isBottomModalOpen: boolean }>`
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 49;
    background-color: rgba(12, 43, 33, 0.5);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0px;
    display: ${({ $isBottomModalOpen }) => ($isBottomModalOpen ? 'flex' : 'none')};
    animation: ${({ $isBottomModalOpen }) => ($isBottomModalOpen ? slideInAnimation : slideOutAnimation)} 0.3s ease forwards;

    @media only screen and (min-width: 960px) {
        animation: ${({ $isBottomModalOpen }) => ($isBottomModalOpen ? fadeInAnimation : fadeOutAnimation)} 0.3s ease forwards;
    }
`;

export const BottomModal = styled.div`
    max-height: calc(100vh - 70px) ;
    padding: 36px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 50;
    background-color: var(--white);
    border-radius: 30px 30px 0px 0px;
    overflow-y: auto;
    scrollbar-width: none;
    max-width: 900px;
    transition: all 0.3s ease-in-out;

    @media only screen and (min-width: 960px) {
        margin: auto;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        bottom: auto;
        width: 485px;
        max-width: 485px;
        min-height: 355px;
        height: auto;
        max-height: 700px;
        padding: 36px 32px;
        border-radius: 30px;
    }
`;

const slideInAnimation = keyframes`
    from {
        display: none;
        transform: translateY(100%);
    }
    to {
        display: flex;
        transform: translateY(0);
    }
`;

const slideOutAnimation = keyframes`
    from {
        display: flex;
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
        display: none;
    }
`;

const fadeInAnimation = keyframes`
    from {
        display: none;
        opacity: 0;
    }
    to {
        display: flex;
        opacity: 1;
    }
`;

const fadeOutAnimation = keyframes`
    from {
        display: flex;
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
`;