import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBottomModal } from '../../contexts/BottomModalContext';
import openFarmLogoSquare from './assets/openfarm-logo-green-square.png';
import * as S from './style';

function AddShortcutBottomModalContent () {
    const { closeBottomModal } = useBottomModal();
    const navigate = useNavigate();

    const handleShowMeHow = useCallback(() => {
        navigate('./adicionar-atalho');
        closeBottomModal();
    }, [closeBottomModal, navigate])
    return <S.AddShortcutBottomModalContent>
        <img src={openFarmLogoSquare} alt="Logotipo do produto OpenFarm" />
        <h2>
            Adicione o Open Farm® na sua tela inicial!
        </h2>
        <S.AddShortcutBottomModalContentButtons>
            <button className='button-green' onClick={handleShowMeHow}>Me mostra como</button>
            <button className='button-outlined' onClick={closeBottomModal}>Agora não</button>
        </S.AddShortcutBottomModalContentButtons>
    </S.AddShortcutBottomModalContent>
}

export default AddShortcutBottomModalContent