import DesktopBanner from '../../components/DesktopBanner';
import FooterComplete from '../../components/FooterComplete';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import RegulamentoContent from '../../components/RegulamentoContent';
import Toolbar from '../../components/Toolbar';
import * as S from './style';

function Regulamento () {
    return <S.RegulamentoContent>
        <Header element={<MenuButton />} borderBottom showDesktopWidgets />
        <DesktopBanner title='Regulamento' />
        <S.Regulamento>
            <RegulamentoContent />
        </S.Regulamento>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
        <Toolbar />
    </S.RegulamentoContent>
}

export default Regulamento