import styled from 'styled-components';

export const UpdateProfileContent = styled.section`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: var(--black);
    overflow: auto;
`;

export const UpdateProfile = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;
    h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 24px;
        max-width: 305px;
    }
`;

export const UpdateProfileBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
        max-width: 48%;
    }
`

export const UpdateProfileButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: var(--primary);
    color: var(--white);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    margin-top: auto;
`;
