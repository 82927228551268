import { useCallback, useEffect, useRef, useState } from 'react';
import toolComment from '../../assets/icons/tool-comment-icon.png';
import toolLike from '../../assets/icons/tool-like-icon.png';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { RemoteCreateComment } from '../../data/use-case/RemoteCreateComment';
import { RemoteLikePost } from '../../data/use-case/RemoteLikePost';
import { PostCommentsProps } from '../../domain/use-case/FetchPosts';
import BackButton from '../Header/BackButton';
import * as S from './style';

const createComment = new RemoteCreateComment()
const likePost = new RemoteLikePost()

interface PostDetailsModalContentProps {
    postComments: PostCommentsProps[]
    postLikes: number
    postId: number
}

function PostDetailsModalContent ({ postComments, postLikes, postId }: PostDetailsModalContentProps) {
    const [isInputActive, setIsInputActive] = useState<boolean>(false)
    const { closeBottomModal } = useBottomModal()
    const addCommentInputRef = useRef<HTMLInputElement>(null)
    const commentsListRef = useRef<HTMLDivElement>(null)
    const [commentsList, setCommentsList] = useState<PostCommentsProps[]>([])
    const [likes, setLikes] = useState<number>(0)

    const handleAddComment = useCallback(async () => {
        const newCommentContent = addCommentInputRef.current?.value

        if (newCommentContent) {
            const post = await createComment.handle({
                content: newCommentContent,
                postId
            })
            setCommentsList(c => [...c, post])
        }
    }, [postId])

    const handleLikePost = useCallback(async () => {
        const count = await likePost.handle({ postId })
        setLikes(likes => likes += count)
    }, [postId])

    useEffect(() => {
        setCommentsList(postComments)
        setLikes(postLikes)
    }, [postComments, postLikes])

    return <>
        <S.PostDetailsModalContentHeader>
            <BackButton onClick={() => closeBottomModal()} />
        </S.PostDetailsModalContentHeader>
        <S.PostDetailsModalContentContainer>
            <S.PostDetailsModalContentTopSection>
                <h2>Comentários</h2>
                <S.PostDetailsModalContentTools>
                    <div onClick={handleLikePost}>
                        <img src={toolLike} alt="Ícone de like (curtir post)" />
                        <p>{likes}</p>
                    </div>
                    <div>
                        <img src={toolComment} alt="Ícone de comentário" />
                        <p>{commentsList.length}</p>
                    </div>
                </S.PostDetailsModalContentTools>
            </S.PostDetailsModalContentTopSection>
            <S.PostDetailsModalContentCommentsList ref={commentsListRef}>
                {commentsList.map((comment, index) => (
                    <S.PostDetailsModalContentComment key={index}>
                        <h2>@{comment.owner.name}</h2>
                        <p>{comment.content}</p>
                    </S.PostDetailsModalContentComment>
                ))}
            </S.PostDetailsModalContentCommentsList>
            <S.PostDetailsModalContentAddComment
                placeholder='Adicione um comentário....'
                ref={addCommentInputRef}
                onFocus={() => setIsInputActive(true)}
                onBlur={() => setIsInputActive(false)}
            />
            <S.PostDetailsModalContentAddCommentButton $isVisible={isInputActive} onClick={handleAddComment} />
        </S.PostDetailsModalContentContainer>
    </>

}

export default PostDetailsModalContent