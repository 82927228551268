import styled from 'styled-components';

export const Footer = styled.footer`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
    img {
        width: 240px;
    }
`;