import styled from 'styled-components';

export const Home = styled.section`
    width: 100%;
    background-color: var(--black);
    color: var(--white);
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 70px;

    @media only screen and (min-width: 960px) {
        background-color: var(--gray-bg);
    }
`;

export const HomeHub = styled.section`
    width: 100%;
    background-color: var(--gray-bg);
    border-radius: 30px 30px 0px 0px;
    margin-top: 24px;
    padding-bottom: 62px;

    @media only screen and (min-width: 960px) {
        margin-top: 0px;
        position: relative;
        top: -30px;
    }
`

export const HomeHubTopCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 36px 36px 0px 36px;
    row-gap: 24px;
    width: 100%;
    
    @media only screen and (min-width: 960px) {
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }

    .group {
        column-gap: 10px;
        display: flex;

        @media only screen and (min-width: 960px) {
            column-gap: 0px;
            gap: 16px;
        }

        &>div {
            flex: 1 50%;

            @media only screen and (min-width: 960px) {
                flex: none;
                width: 320px;
            }
        }
    }

    .first-card,
    .second-card,
    .third-card,
    .fourth-card {
        border-radius: 20px;
        box-shadow: 0px 3px 16px #001D4129;
        height: 154px;

        @media only screen and (min-width: 960px) {
            width: 320px;
        }
    }

    .first-card {
        grid-area: 1 / 1 / 2 / 3;
        height: auto;
    }
    .second-card {
        grid-area: 2 / 1 / 3 / 2;
    }
    .third-card {
        grid-area: 2 / 2 / 3 / 3;
    }
    .fourth-card {
        grid-area: 3 / 1 / 4 / 3;
    }
`

export const HomeSwipperHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 36px;
    margin-top: 8px;
`;

export const HomeSwipperHeaderTitle = styled.div`
    color: var(--black);
    font-size: 1.125rem;
    font-family: var(--font-bold);
`;

export const HomeSwipperHeaderActionTitle = styled.a`
    color: var(--primary);
    cursor: pointer;
    font-size: 1.125rem;
    font-family: var(--font-bold);
    text-decoration: none;
`;

export const FAQContainer = styled.div`
    width: 100%;
    padding: 0 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    >h2 {
        font-family: var(--font-bold);
        margin-bottom: 14px;
        color: var(--black);
        font-size: 1.125rem;
        width: 100%;
    }
    >h3 {
        margin-bottom: 32px;
        color: var(--black);
        font-size: 1rem;
        line-height: 1.125rem;
        width: 100%;
    }
    
`

export const FAQItem = styled.div`
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid #88A8A4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
`

export const FAQItemQuestion = styled.div<{ $isToggled: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black);
    font-size: 1rem;
    line-height: 1.125rem;
    font-family: var(--font-bold);
    cursor: pointer;
    margin-bottom:${({ $isToggled }) => ($isToggled ? `8px` : `0`)};
    gap: 5px;

    img {
        width: 14px;
        height: 14px;
        transition: transform 0.3s ease;
        transform: rotateX(${({ $isToggled }) => ($isToggled ? `180deg` : `0deg`)});
    }

`

export const FAQItemAnswer = styled.div<{ $isShown: boolean }>`
    width: 100%;
    color: var(--black);
    font-size: 0.875rem;
    line-height: 1rem;
    transition: all 0.3s ease;
    display: ${({ $isShown }) => ($isShown ? `block` : `none`)};
    overflow: hidden;
`

export const FAQButton = styled.button`
    width: 100%;
    color: var(--white);
    font-size: 1.125rem;
    line-height: 1.438rem;
    border-radius: 28px;
    background-color: var(--primary);
    padding: 16px;
    margin: 0 auto;
    margin-top: 22px;
    text-align: center;
    font-family: var(--font-bold);
    max-width: 418px;
`