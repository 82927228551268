import styled from 'styled-components';

export const AddShortcutBottomModalContent = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    gap: 24px;

    img {
        margin: 32px auto 0px auto;
        width: 88px;
    }
    h2 {
        color: var(--black);
        font-size: 1.5rem;
    }
`;

export const AddShortcutBottomModalContentButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 8px;

    button {
        width: 100%;
        font-size: 1.125rem;
        padding: 17px;
        font-family: var(--font-bold);
        border-radius: 28px;
    }

    .button-green {
        background-color: var(--primary);
        color: var(--white)
    }
    .button-outlined {
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
`;