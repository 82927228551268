import { useBottomModal } from '../../contexts/BottomModalContext'
import * as S from './style'

function MessageAnalysisModalContent() {
    const { closeBottomModal } = useBottomModal()
    
    return <S.MessageAnalysisModalContent>
        <h2>Mensagem em análise</h2>
        <p>
            Vamos verificar a sua publicação para ver se ela está de acordo com as nossas regras, ok!
        </p>
        <S.MessageAnalysisModalContentButtons>
            <button className='button-outlined' onClick={closeBottomModal}>Continuar</button>
        </S.MessageAnalysisModalContentButtons>
    </S.MessageAnalysisModalContent>
}

export default MessageAnalysisModalContent