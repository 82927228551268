import { ReactElement, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAuth } from "../../contexts/AuthContext"
import { useSpecies } from "../../contexts/SpeciesContext"
import HomeItemsSwipper from "../../pages/Home/HomeItemsSwipper"
import api from "../../services/api"
import HomeItemsSwipperSkeleton from "../HomeItemsSwipperSkeleton"
import * as S from './style'

enum State {
  LOADING,
  READY
}

interface ShowcaseProps {
  catalog: any
  catalogName: string
  link: string
  catalogType: string
}

function Showcase ({ catalog, catalogName, link, catalogType }: ShowcaseProps): ReactElement {
  const [state, setState] = useState<State>(State.LOADING)
  const [showcase, setShowcase] = useState<any>()
  const { token } = useAuth()
  const { specieSelected } = useSpecies()

  useEffect(() => {
      (async () => {
          if (!catalog) return
          if (!specieSelected) return
          if (!token) return
          const config = {
              params: { catalogId: catalog },
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          }
          try {
              const response = await api.get(`${catalogType === 'benefit' ? '/catalog' : '/catalog/reinvest'}` , config)
              setShowcase(response.data)
              setState(State.READY)
          } catch (error) {
              toast.error(`Não foi possível carregar o catálogo ${catalogName}. Por favor, tente novamente.`, {
                  position: "bottom-right",
                  theme: 'colored'
              });
          }
      })()
  }, [catalog, catalogName, catalogType, specieSelected, token])

  if (state === State.LOADING) return <>
      <S.HomeSwipperHeader>
        <S.HomeSwipperHeaderTitle>Carregando catálogo {catalogName}...</S.HomeSwipperHeaderTitle>
      </S.HomeSwipperHeader>
      <HomeItemsSwipperSkeleton />
    </>
  return <>
      <S.HomeSwipperHeader>
          <S.HomeSwipperHeaderTitle>Catálogo {catalogName}</S.HomeSwipperHeaderTitle>
          <S.HomeSwipperHeaderActionTitle href={link} target="_blank">Explorar</S.HomeSwipperHeaderActionTitle>
      </S.HomeSwipperHeader>
      <HomeItemsSwipper catalog={catalog} items={showcase.products} link={link} />
  </>
}

export default Showcase