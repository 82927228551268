import { useCallback, useState } from 'react'
import { useBottomModal } from '../../contexts/BottomModalContext'
import AddShortcutBottomModalContent from '../AddShortcutBottomModalContent'
import * as S from './style'

function CookiesModalContent () {
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
    const { closeBottomModal, showBottomModal } = useBottomModal()

    const handleCloseModal = useCallback(() => {
        localStorage.setItem('modal1', 'true')
        closeBottomModal()
        const timeout = setTimeout(() => {
            showBottomModal(<AddShortcutBottomModalContent />)
        }, 300);

        return () => clearTimeout(timeout)
    }, [closeBottomModal, showBottomModal])

    return <S.CookiesModalContent>
        <S.CookiesWarning $isActive={!isSettingsOpen}>
            <p>
                A Open Farm® tem a intenção de melhorar a experiência e a interação que você tem no nosso site. Uma forma de fazer isso é através dos cookies ou técnicas similares que armazenam informações sobre a visita ao site. Nós também compartilhamos informações sobre o seu uso do nosso site com nossos parceiros de mídia social, publicidade e análise.
            </p>
            <S.CookiesButtons>
                <button className='button-green' onClick={handleCloseModal}>Aceitar cookies</button>
                <button className='button-outlined' onClick={() => setIsSettingsOpen(true)}>Definições de cookies</button>
            </S.CookiesButtons>
        </S.CookiesWarning>
        <S.CookiesSettings $isActive={isSettingsOpen}>
            <ul>
                <li>
                    <S.CookiesSettingsText>
                        <h3>Cookies obrigatórios</h3>
                        <p>Estes cookies são necessários para o funcionamento do site, permitindo a execução de funções básicas. tais como aceder a área seguras de webistes ou lembrar as suas preferências.</p>
                    </S.CookiesSettingsText>
                </li>
                <li>
                    <S.CookiesSettingsText>
                        <h3>Cookies funcionais</h3>
                        <p>Estes cookies permitem-nos analisar a forma como utiliza o site para avaliar e melhorar nosso desempenho. Também podem ser utilizados para melhorar a experiência de navegação do cliente neste site. Por exemplo: recordar os seus dados de acesso, guardar artigos no cesto de compras ou fornecer informações sobre a forma como o nosso site é utilizado.</p>
                    </S.CookiesSettingsText>
                    <S.CookiesSettingsSwitch>
                        <input type='checkbox' />
                        <div className='switch-knob'></div>
                        <div className='switch-layer'></div>
                    </S.CookiesSettingsSwitch>
                </li>
                <li>
                    <S.CookiesSettingsText>
                        <h3>Cookies de publicidade</h3>
                        <p>Estes cookies são utilizados para apresentar anúncios baseados nas suas preferências. Podemos partilhar estas informações com os anunciantes ou utilizá-las para avaliar melhor os seus interesses. Por exemplo, os cookies de publicidade podem ser utilizados para partilhar dados com os anunciantes, por forma a direcionar as campanhas publicitárias tendo em conta os seus interesses, para permitir que partilhe determinadas páginas nas redes sociais ou que publique comentários em nossos sites.</p>
                    </S.CookiesSettingsText>
                    <S.CookiesSettingsSwitch>
                        <input type='checkbox' />
                        <div className='switch-knob'></div>
                        <div className='switch-layer'></div>
                    </S.CookiesSettingsSwitch>
                </li>
            </ul>
            <S.CookiesButtons>
                <button className='button-green' onClick={handleCloseModal}>Atualizar cookies</button>
                <button className='button-outlined' onClick={() => setIsSettingsOpen(false)}>Voltar</button>
            </S.CookiesButtons>
        </S.CookiesSettings>
    </S.CookiesModalContent>
}

export default CookiesModalContent