import { toast } from "react-toastify";
import { FetchAddress } from "../../domain/use-case/FetchAddress";
import api from "../../services/api";

export class RepublicaFetchAddress implements FetchAddress {
    async handle (cep: string): Promise<FetchAddress.Result> {
        const cepFormatted = cep.replace('-', '')
        let response
        try {
            response = await api.get(`cep?cep=${cepFormatted}`)
            if (!response.data.uf && !response.data.cidade) {
                toast.error('CEP não encontrado.', {
                    position: "bottom-right",
                    theme: 'colored'
                });
            }
        } catch (error) {
            console.error(error)
            toast.error('Ocorreu um erro interno ao buscar o CEP. Por favor, tente novamente.', {
                position: "bottom-right",
                theme: 'colored'
            });
        }
        return {
            bairro: response?.data.bairro,
            cidade: response?.data.cidade,
            logradouro: response?.data.logradouro,
            uf: response?.data.uf
        }
    }
}