import { useSpecies } from '../../../../contexts/SpeciesContext';
import * as S from './style';

function CampaignInfosHubItem () {
    const { specieSelected } = useSpecies()
    if (!specieSelected) return <></>
    return <S.CampaignInfosHubItem $specieBg={specieSelected.background}>
        <h2>Como funciona</h2>
        <p>Entenda todos os detalhes da campanha!</p>
    </S.CampaignInfosHubItem>
}

export default CampaignInfosHubItem