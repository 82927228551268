import styled from 'styled-components';
import markedIcon from '../../assets/icons/marked-icon.png';

export const Signup = styled.section`
    width: 100%;
    max-width: 485px;
    margin: 0 auto;
    background-color: var(--black);
`

export const SignupStepper = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @media only screen and (min-width: 960px) {
        height: auto;
        min-height: auto;
        border-radius: 30px;
    }
`

export const SignupStepperViewTitle = styled.h2`
    font-family: var(--font-bold);
    margin-bottom: 32px;
    color: var(--black);
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 100%;
`

export const SignupStepperTrail = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 0 15px;

    @media only screen and (max-width: 390px) {
        padding: 0;
    }
    
    >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 11px;
        width: 55px;

        p {
            font-size: 0.75rem;
            color: var(--black);
            line-height: 0.938rem;
            text-align: center;
        }
    }
`

export const SignupStepperViewContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    align-items: flex-start;
    justify-content: flex-start;
`

export const SignupStepperFirstView = styled.div<{ $isVisible: boolean }>`
    width: 100%;
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: all 0.3s ease;

    .signup-stepper-trail-first-dot,
    .signup-stepper-trail-second-dot,
    .signup-stepper-trail-third-dot,
    .signup-stepper-trail-fourth-dot {
        border-radius: 50%;
        background-color: #88A8A4;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: 5;
    }
    .signup-stepper-trail-first-dot {
        background-color: var(--white);
        border: 3.2px solid var(--primary);
    }
    .signup-stepper-trail-first-line,
    .signup-stepper-trail-second-line,
    .signup-stepper-trail-third-line {
        position: absolute;
        content: '';
        width: 65px;
        right: calc(50% + 2.5px);
        top: 6.5px;
        height: 2px;
        background-color: #88A8A4;
        z-index: -1;
    }
`

export const SignupStepperFirstViewPasswordRules = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
`

export const SignupStepperFirstViewPasswordRulesTitle = styled.h2`
    font-family: var(--font-bold);
    color: var(--black);
    font-size: 1rem;
    line-height: 0.875rem;
    width: 100%;
    margin-bottom: 3px;
    letter-spacing: -0.4px;
`

export const SignupStepperFirstViewPasswordRulesContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
    }
`

export const SignupStepperFirstViewPasswordRulesContentBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media only screen and (min-width: 960px) {
        width: 50%;
        max-width: 50%;
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
    }
    img {
        width: 16px;
        min-width: 16px;
    }
    p {
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--black);
    }
`

export const SignupStepperFirstViewStatute = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 25px;
    margin-bottom: 24px;

    input {
        appearance: none;
        width: 24px;
        height: 24px;
        border: 1px solid #88A8A4;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-out;
        position: relative;

        &:checked {
            background-color: var(--primary);
            border: none;

            &::after {
                content: '';
                background-image: url(${markedIcon});
                background-position: center;
                background-size: 10px;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                color: var(--white);
                top: 0;
                left: 0;
            }
        }
    }
    p {
        font-size: 1rem;
        line-height: 1.125rem;
        color: var(--black);

        button {
            font-family: var(--font-bold);
            background: none;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--black);
        }
    }
`

export const SignupStepperSecondView = styled.div<{ $isVisible: boolean }>`
    width: 100%;
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: all 0.3s ease;

    .signup-stepper-trail-first-dot,
    .signup-stepper-trail-second-dot,
    .signup-stepper-trail-third-dot,
    .signup-stepper-trail-fourth-dot {
        border-radius: 50%;
        background-color: #88A8A4;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: 5;
    }
    .signup-stepper-trail-first-dot {
        background-color: var(--primary);
    }
    .signup-stepper-trail-second-dot {
        background-color: var(--white);
        border: 3.2px solid var(--primary);
    }
    .signup-stepper-trail-first-line,
    .signup-stepper-trail-second-line,
    .signup-stepper-trail-third-line {
        position: absolute;
        content: '';
        width: 65px;
        right: calc(50% + 2.5px);
        top: 6.5px;
        height: 2px;
        background-color: #88A8A4;
        z-index: -1;
    }
    .signup-stepper-trail-first-line {
        background-color: var(--primary);
        top: 3.3px;
        width: 62px;
        right: calc(50% + 5.5px);
    }
    .signup-stepper-view-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* margin-bottom: 145px; */
    }
`

export const SignupStepperThirdView = styled.div<{ $isVisible: boolean }>`
    width: 100%;
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: all 0.3s ease;

    .signup-stepper-trail-first-dot,
    .signup-stepper-trail-second-dot,
    .signup-stepper-trail-third-dot,
    .signup-stepper-trail-fourth-dot {
        border-radius: 50%;
        background-color: #88A8A4;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: 5;
    }
    .signup-stepper-trail-first-dot,
    .signup-stepper-trail-second-dot {
        background-color: var(--primary);
    }
    .signup-stepper-trail-third-dot {
        background-color: var(--white);
        border: 3.2px solid var(--primary);
    }
    .signup-stepper-trail-first-line,
    .signup-stepper-trail-second-line,
    .signup-stepper-trail-third-line {
        position: absolute;
        content: '';
        width: 65px;
        right: calc(50% + 2.5px);
        top: 6.5px;
        height: 2px;
        background-color: #88A8A4;
        z-index: -1;
    }
    .signup-stepper-trail-first-line {
        background-color: var(--primary)
    }
    .signup-stepper-trail-second-line {
        background-color: var(--primary);
        top: 3.3px;
        width: 62px;
        right: calc(50% + 5.5px);
    }
    .signup-stepper-view-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* margin-bottom: 145px; */
    }
`

export const SignupStepperFourthView = styled.div<{ $isVisible: boolean }>`
    width: 100%;
    display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: all 0.3s ease;

    .signup-stepper-trail-first-dot,
    .signup-stepper-trail-second-dot,
    .signup-stepper-trail-third-dot,
    .signup-stepper-trail-fourth-dot {
        border-radius: 50%;
        background-color: #88A8A4;
        width: 15px;
        height: 15px;
        position: relative;
        z-index: 5;
    }
    .signup-stepper-trail-first-dot,
    .signup-stepper-trail-second-dot,
    .signup-stepper-trail-third-dot {
        background-color: var(--primary);
    }
    .signup-stepper-trail-fourth-dot {
        background-color: var(--white);
        border: 3.2px solid var(--primary);
    }
    .signup-stepper-trail-first-line,
    .signup-stepper-trail-second-line,
    .signup-stepper-trail-third-line {
        position: absolute;
        content: '';
        width: 65px;
        right: calc(50% + 2.5px);
        top: 6.5px;
        height: 2px;
        background-color: #88A8A4;
        z-index: -1;
    }
    .signup-stepper-trail-first-line,
    .signup-stepper-trail-second-line {
        background-color: var(--primary)
    }
    .signup-stepper-trail-third-line {
        background-color: var(--primary);
        top: 3.3px;
        width: 62px;
        right: calc(50% + 5.5px);
    }
    .confirmation-message {
        color: var(--black);
        font-size: 0.875rem;
        line-height: 1rem;

        &:nth-of-type(2) {
            margin-top: 242px;
            margin-bottom: 25px;
        }

        strong {
            font-family: var(--font-bold);
        }
    }
`

export const SignupStepperConfirmationDigits = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    margin-bottom: 24px;
`

export const SignupStepperConfirmationDigit = styled.input`
    padding: 12px 15px;
    border: 1px solid #88A8A4;
    color: var(--black);
    font-family: var(--font-bold);
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 5px;
    max-width: 50px;
`

export const SignupStepperResendCodeButton = styled.button`
        padding: 16px;
        width: 100%;
        font-size: 1.125rem;
        font-family: var(--font-bold);
        text-align: center;
        border-radius: 28px;
        background-color: var(--primary);
        border: none;
        color: var(--white);
        margin-bottom: 12px;
        transition: all 0ms.3s ease-in-out;

        &[disabled] {
            background-color:#EBF3EF;
            color: #88A8A4;
            cursor: not-allowed;
            transition: all 0ms.3s ease-in-out;
        }
`

export const SignupWarnings = styled.div`
    width: 100%;
    color: red;
    font-size: 0.75rem;
    margin-top: 14px;
    position: relative;
    top: -32px;
    left: 16px;
`

export const SignupStepperViewContinueButton = styled.button`
    padding: 16px;
    width: 100%;
    font-size: 1.125rem;
    font-family: var(--font-bold);
    text-align: center;
    border-radius: 28px;
    background-color: var(--primary);
    border: none;
    color: var(--white);
    margin-top: auto;

    &[disabled] {
        background-color: #EBF3EF;
        color: #88A8A4;
        cursor: not-allowed; 
    }

`