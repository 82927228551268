import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import grayArrow from '../../assets/gray-arrow-icon.png'
import { useAuth } from "../../contexts/AuthContext"
import { useBottomModal } from "../../contexts/BottomModalContext"
import { useSpecies } from "../../contexts/SpeciesContext"
import api from "../../services/api"
import { maskPoints } from "../../utils/mask"
import BalanceModalContent from "../BalanceModalContent"
import Loader from "../Loader"
import { default as UserPointsBlockLink } from "../UserPointsBlockLink"
import * as S from './style'

enum State {
  LOADING,
  READY
}

interface UserPointsState {
  currentState: State
  balance: any
}

function UserPoints () {
  const [isUserPointsExpanded, setIsUserPointsExpanded] = useState<boolean>(false)
  const { userCan, user } = useAuth()
  const navigate = useNavigate()
  const { showBottomModal } = useBottomModal()
  const { specieSelected, isCatalogLeiteSelected } = useSpecies()
  const [state, setState] = useState<UserPointsState>({
      currentState: State.LOADING,
      balance: undefined
  })
  const [hasLeiteLojaBalance, setHasLeiteLojaBalance] = useState<boolean>(false)

  const handleUserPointsExpand = useCallback(() => {
      setIsUserPointsExpanded(prevState => !prevState);
  }, [])

  const reinvestPoints = useMemo(() => {
      if (!specieSelected) return 0
      if (!state.balance) return 0
      return state.balance.categories[specieSelected.catalog]
  }, [specieSelected, state.balance])

  const reinvestPointsLeiteLoja = useMemo(() => {
    if (!specieSelected) return 0
    if (!state.balance) return 0
    return state.balance.categories[81554]
  }, [specieSelected, state.balance])

  const benefitsPoints = useMemo(() => {
      if (!state.balance) return 0
      return state.balance.categories[81544]
  }, [state.balance])

  const totalPoints = useMemo(() => {
      return reinvestPoints + benefitsPoints
  }, [benefitsPoints, reinvestPoints])

  useEffect(() => {
      (async () => {
          setState((state) => ({
              ...state,
              currentState: State.LOADING
          }))
          try {
              const response = await api.get('/balance')
              setState((state) => ({
                  ...state,
                  balance: response.data
              }))
          } catch (err) {
          }
          setState((state) => ({
              ...state,
              currentState: State.READY
          }))
      })()
  }, [])

  useEffect(() => {
      if (!specieSelected) return
      if (specieSelected.catalog === 81543 && specieSelected.childrens.length) setHasLeiteLojaBalance(true)
  }, [specieSelected])

  if (state.currentState === State.LOADING) return <Loader />

    return <>
        {userCan('update:points') && <>
            <S.UserPointsMobile>
                <div className='user-name-container'>
                    Bem-vindo!
                    <span>{user?.name.split(' ')[0]}</span>
                </div>
                <S.UserPoints $isExpanded={isUserPointsExpanded} $isCatalogLeiteSelected={isCatalogLeiteSelected}>
                        <div className='background'></div>
                        <S.UserPointsContainer $isExpanded={isUserPointsExpanded}>
                            <div className='total-points'>
                                Você tem no total
                                <div className='total-points-number'>{maskPoints(totalPoints)} pts</div>
                            </div>
                            <S.ToggleUserPoints onClick={handleUserPointsExpand}>
                                <S.ToggleUserPointsArrow $isToggled={isUserPointsExpanded} >
                                    <img src={grayArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                                </S.ToggleUserPointsArrow>
                            </S.ToggleUserPoints>
                        </S.UserPointsContainer>
                        {isCatalogLeiteSelected ? <>
                            <UserPointsBlockLink title='Conta Reinvestimento Leite Fábrica' isVisible={isUserPointsExpanded} points={reinvestPoints} />
                            {hasLeiteLojaBalance && <UserPointsBlockLink title='Conta Reinvestimento Leite Loja' isVisible={isUserPointsExpanded} points={reinvestPointsLeiteLoja} />}
                        </> : 
                            <UserPointsBlockLink title='Conta Reinvestimento' isVisible={isUserPointsExpanded} points={reinvestPoints} />
                        }
                    <UserPointsBlockLink title='Conta Benefícios' isVisible={isUserPointsExpanded} points={benefitsPoints} />
                    <S.UserPointsButtons $isVisible={isUserPointsExpanded}>
                        {userCan('read:button-troca-pontos') && <button className='button-green' onClick={() => navigate('/transfira-seus-pontos')}>Transfira seus pontos</button>}
                        <button className='button-outlined' onClick={() => showBottomModal(<BalanceModalContent />)}>Entenda seu saldo</button>
                    </S.UserPointsButtons>
                </S.UserPoints>
            </S.UserPointsMobile>
        </>}
    </>
}

export default UserPoints