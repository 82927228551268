import * as S from './style'

interface UserCategoryHubItemExtendedProps {
    subtitle?: string
    kpis?: any[]
    specieSelectedInfos: any
}

function UserCategoryHubItemExtended ({ subtitle, specieSelectedInfos }: UserCategoryHubItemExtendedProps) {

    if (!specieSelectedInfos) return <></>

    return <S.UserCategoryHubItemExtended>
        <S.UserCategoryHubItemExtendedHeader>
            Categoria {subtitle && `(${subtitle})`}
        </S.UserCategoryHubItemExtendedHeader>
        <S.UserCategoryHubItemExtendedBody>
            {specieSelectedInfos.home.badge && <img src={specieSelectedInfos.home.badge} alt="ícone de atual categoria do usuário" />}
            <S.UserCategoryHubItemExtendedKPIs>
                {specieSelectedInfos.kpis.map((kpi: any, index: number) => (
                    <S.UserCategoryHubItemExtendedProgress key={index}>
                        <S.UserCategoryHubItemExtendedProgressInfos color={specieSelectedInfos.home.color}>
                            <p>{kpi.name}</p>
                            <span id='user_category_progress'>{kpi.value}%</span>
                        </S.UserCategoryHubItemExtendedProgressInfos>
                        <S.UserCategoryHubItemExtendedProgressBar color={specieSelectedInfos.home.color} $progress={kpi.value} />
                    </S.UserCategoryHubItemExtendedProgress>
                ))}
            </S.UserCategoryHubItemExtendedKPIs>
        </S.UserCategoryHubItemExtendedBody>
    </S.UserCategoryHubItemExtended>
}

export default UserCategoryHubItemExtended