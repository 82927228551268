import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/LoaderWithBackdrop/style';
import { RemoteFetchPosts } from '../../../data/use-case/RemoteFetchPosts';
import { FetchPosts } from '../../../domain/use-case/FetchPosts';
import * as S from './style';

const fetchPosts = new RemoteFetchPosts()

enum BlogPostsStatus {
    LOADING,
    READY
}

interface BlogPosts {
    currentStatus: BlogPostsStatus
    data: FetchPosts.Result[]
}

function HomeBlogSwipper (): ReactElement {
    const [blogPosts, setBlogPosts] = useState<BlogPosts>({
        currentStatus: BlogPostsStatus.LOADING,
        data: []
    })

    useEffect(() => {
        (async () => {
            setBlogPosts({
                data: [],
                currentStatus: BlogPostsStatus.LOADING
            })
            const data = await fetchPosts.handle({ amount: 3 })
            setBlogPosts({
                currentStatus: BlogPostsStatus.READY,
                data
            })
        })()
    }, [])

    const navigate = useNavigate()

    if (blogPosts.currentStatus === BlogPostsStatus.LOADING) return <Loader />

    return <S.HomeBlogSwipper>
        <S.HomeBlogSwipperPosts>
            {blogPosts.data.map((post, index) => (
                <S.HomeBlogSwipperPost key={index} onClick={() => navigate('/blog')}>
                    <S.HomeBlogSwipperPostImage src={post.image} />
                    <S.HomeBlogSwipperPostTools>
                        <S.HomeBlogSwipperPostToolLike />
                        <S.HomeBlogSwipperPostToolComment />
                        <S.HomeBlogSwipperPostDate >{post.createdAt.toLocaleDateString('pt-br')}</S.HomeBlogSwipperPostDate>
                    </S.HomeBlogSwipperPostTools>
                    <S.HomeBlogSwipperPostUser>@{post.owner.name}</S.HomeBlogSwipperPostUser>
                    <S.HomeBlogSwipperPostText>{post.content}</S.HomeBlogSwipperPostText>
                </S.HomeBlogSwipperPost>
            ))}
        </S.HomeBlogSwipperPosts>
    </S.HomeBlogSwipper>
}

export default HomeBlogSwipper