import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import welcomeImageDesktop from './assets/welcome-image-desktop.png';
import welcomeImageMobile from './assets/welcome-image-mobile.png';
import welcomeImageNormal from './assets/welcome-image-normal.png';
import * as S from './style';

function Welcome() {
    const navigate = useNavigate()
    
    return <S.Welcome>
        <Header /> 
        <div>
            <picture>
                    <source media="(max-width: 359px)" srcSet={welcomeImageMobile} />
                    <source media="(max-width: 519px)" srcSet={welcomeImageNormal} />
                    <img src={welcomeImageDesktop} alt="Imagem de um homem sorrindo e concentrado, interagindo com um tablet" />
            </picture>
            <h2>Seja muito bem-vindo!</h2>
            <p>É muito bom ter você com a gente. Vamos juntos abrir caminhos para uma experiência ainda mais proveitosa, com recompensas para você e desenvolvimento para o seu negócio!</p>
            <button onClick={() => navigate('/inicio')}>Entrar</button>
        </div>
    </S.Welcome>
}

export default Welcome

