
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import * as S from './style'

interface confirmDeletePostProps {
  postId: number
  onDeletePost: (postId: number) => void
}

function ConfirmDeletePost({ postId, onDeletePost }:confirmDeletePostProps) {
  const { closeBottomModal } = useBottomModal()

  const confirmDeletePost = useCallback(async (postId: number) => {
    try {
        await api.delete(`/posts/${postId}`)
        onDeletePost(postId)
        toast.success('Post deletado com sucesso.', { theme: 'colored', position: 'bottom-right' })
    } catch (error) {
        console.log(error)
        toast.error('Houve um problema ao tentar deletar o post. Por favor, tente novamente.', { theme: 'colored', position: 'bottom-right' })
    }
    closeBottomModal()
  }, [closeBottomModal, onDeletePost])
  return <S.ConfirmDeletePost>
    <h2>Tem certeza? Esta ação não poderá ser desfeita.</h2>
    <S.BalanceModalContentButtons>
    <button className='button' onClick={() => confirmDeletePost(postId)}>Confirmar</button>
    <button className='button-outlined' onClick={closeBottomModal}>Cancelar</button>
    </S.BalanceModalContentButtons>
  
  </S.ConfirmDeletePost>
}

export default ConfirmDeletePost