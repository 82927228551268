import { ReactElement, useCallback, useEffect, useState } from 'react'
import closeButton from '../../assets/icons/close.png'
import popupImage from './assets/popup-image.png'
import * as S from './style'

function GoogleFormsModal (): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleConfirmClick = useCallback(() => {
    localStorage.setItem('isGoogleFormFilled', 'true')
    handleCloseModal()
  }, [handleCloseModal])

  useEffect(() => {
    if (localStorage.getItem('isGoogleFormFilled') !== 'true') {
      setIsModalOpen(true)
    }
  }, [])

  return <S.GoogleFormsModalBackdrop $isModalOpen={isModalOpen}>
    <S.GoogleFormsModal>
      <div className='close-button' onClick={handleCloseModal}>
        <img src={closeButton} alt="Botão de fechar modal" />
      </div>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfDcQwDhA440xGYxkYLNFFdBTk6bNRwLxOe77c4X8xOM2DyZw/viewform"
      target="_blank"
      rel="noreferrer"
      onClick={handleConfirmClick}
      >
        <img src={popupImage} alt="Logotipo da OpenFarm" />
      </a>
    </S.GoogleFormsModal>
  </S.GoogleFormsModalBackdrop>
}

export default GoogleFormsModal
