import styled from 'styled-components';

export const CookiesModalContent = styled.div`
    width: 100%;
    border-radius: inherit;
    gap: 26px;
`;

export const CookiesWarning = styled.div<{ $isActive: boolean }>`
    width: 100%;
    height: ${({ $isActive }) => ($isActive ? '100%' : '0px')};
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
    flex-direction: column;
    p {
        color: var(--black);
        font-size: 0.875rem;
    }
`;

export const CookiesButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 26px;
    
    button {
        width: 100%;
        font-size: 1.125rem;
        padding: 17px;
        font-family: var(--font-bold);
        border-radius: 28px;
    }
    
    .button-green {
        background-color: var(--primary);
        color: var(--white)
    }
    .button-outlined {
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
`;

export const CookiesSettings = styled.div<{ $isActive: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: ${({ $isActive }) => ($isActive ? '100%' : '0px')};
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        list-style: none;

        li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0px;
            border-bottom: 1px solid #88A8A4;
            gap: 5px;

            &:last-of-type {
                border: none;
                margin-bottom: 0px;
            }
        }
    }
`;

export const CookiesSettingsText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    h3 {
        font-family: var(--font-bold);
        font-size: 1.125rem;
        font-size: 1.125rem;
        color: var(--black);
    }
    p {
        color: var(--black);
        font-size: 0.75rem;
    }
`

export const CookiesSettingsSwitch = styled.div`
    position: relative;
    width: 52px;
    min-width: 52px;
    height: 28px;
    overflow: hidden;
    border: 1px solid #88A8A4;
    border-radius: 200px;

    input {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;

        &:checked + .switch-knob:before {
            content: "";
            left: 26px;
        }
        &:checked ~ .switch-layer {
            background-color: var(--green-neon);
        }
    }

    .switch-knob {
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 2px;
            left: 2px;
            width: 22px;
            height: 22px;
            background-color: var(--primary);
            border-radius: 50%;
            transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
        }
    }

    .switch-layer {
        border-radius: inherit;
        transition: all 0.3s ease-in-out;
        width: 100%;
        background-color: var(--white);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 28px;
    }
`