import { LikePost } from "../../domain/use-case/LikePost";
import api from "../../services/api";

export class RemoteLikePost implements LikePost {
  async handle (params: LikePost.Params): Promise<number> {
    const response = await api.patch(`/posts/${params.postId}/like`)
    if (response.data) {
      return 1
    }
    return -1
  }
}