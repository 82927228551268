import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import grayArrow from '../../assets/gray-arrow-icon.png'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import Loader from '../../components/Loader'
import Toolbar from '../../components/Toolbar'
import api from '../../services/api'
import * as S from './style'

enum State {
    LOADING,
    READY
}

interface WalletAttributes {
    currentState: State
    data: Array<{
        name: string,
        email: string,
        cnpj: string,
        abertura: string,
        cep: string,
        estado: string,
        cidade: string,
        bairro: string,
        logradouro: string,
        numero: string,
        complemento: string,
    }>
}

function Filiais () {
    const [ClientShown, setClientShown] = useState<number | null>(null)
    const [state, setState] = useState<WalletAttributes>({
        currentState: State.LOADING,
        data: []
    })
    const navigate = useNavigate()

    const toggleClient = useCallback((index: number) => {
        setClientShown(ClientShown === index ? null : index);
    }, [ClientShown])

    useEffect(() => {
        (async () => {
            setState((state) => ({ ...state, currentState: State.LOADING }))
            const response = await api.get(`/filiais`)
            setState({
                currentState: State.READY,
                data: response.data.filiais
            })
        })()
    }, [])

    if (state.currentState === State.LOADING) return <Loader />

    return <S.WalletContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Carteira' />
        <S.Wallet>
            <h2>
                Veja suas filiais.
            </h2>
            <div>Você tem <span>{state.data.length}</span> filiais.</div>
            <button onClick={() => navigate('/filiais/nova')}>Adicionar nova filial</button>
        </S.Wallet>
        <S.WalletBottomSection>
            <h2>Confira a lista completa:</h2>
            <div>
                {state.data.map((client, index) => (
                    <S.WalletItem key={index}>
                        <S.WalletItemHeader onClick={() => toggleClient(index)} $isToggled={ClientShown === index}>
                            <p>{client.name}</p>
                            <img src={grayArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                        </S.WalletItemHeader>
                        <S.WalletItemContent $isShown={ClientShown === index}>
                            <div>
                                <p>CNPJ</p>
                                <span>{client.cnpj}</span>
                            </div>
                            <div>
                                <p>Email</p>
                                <span>{client.email}</span>
                            </div>
                            <div>
                                <p>Data de abertura</p>
                                <span>{new Date(client.abertura).toLocaleDateString('pt-br')}</span>
                            </div>
                            <div>
                                <p>CEP</p>
                                <span>{client.cep}</span>
                            </div>
                            <div>
                                <p>Estado</p>
                                <span>{client.estado}</span>
                            </div>
                            <div>
                                <p>Cidade</p>
                                <span>{client.cidade}</span>
                            </div>
                            <div>
                                <p>Bairro</p>
                                <span>{client.bairro}</span>
                            </div>
                            <div>
                                <p>Logradouro</p>
                                <span>{client.logradouro}</span>
                            </div>
                            <div>
                                <p>Numero</p>
                                <span>{client.numero}</span>
                            </div>
                            <div>
                                <p>Complemento</p>
                                <span>{client.complemento}</span>
                            </div>
                        </S.WalletItemContent>
                    </S.WalletItem>
                ))}
            </div>

            <S.WalletButton onClick={() => window.scrollTo(0, 0)}>
                Voltar ao topo
            </S.WalletButton>
        </S.WalletBottomSection>
        <Toolbar />
    </S.WalletContent>
}

export default Filiais