import openfarmLogo from '../../assets/openfarm-logo.png'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import Loader from '../../components/Loader'
import Toolbar from '../../components/Toolbar'
import { useSpecies } from '../../contexts/SpeciesContext'
import midBanner from './assets/how-it-works-mid-banner.png'
import topBannerImage from './assets/how-it-works-top-banner-image.png'
import topImage from './assets/login-image-1-900.png'
import inovacaoBadge from './assets/openfarm-badge-inovacao.png'
import pessoasBadge from './assets/openfarm-badge-pessoas.png'
import sustentabilidadeBadge from './assets/openfarm-badge-sustentabilidade.png'
import * as S from './style'

function HowItWorks () {
    const { specieSelected } = useSpecies()

    if (!specieSelected) return <Loader />

    return <S.HowItWorksContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Como funciona' />
        <S.HowItWorks>
            <S.HowItWorksTopSection>
                <img src={topImage} alt="Imagem de um homem sorrindo e concentrado, interagindo com um tablet" />
                <h2>Sua empresa será classificada</h2>
                {specieSelected?.catalog === 81541 ?
                    <p>de acordo com o <strong>volume de produção</strong>. A tecnologia afetará a classificação:<br /><br />
                        ● Se o valor for menor que o mínimo da categoria, o cliente desce uma categoria;<br />
                        ● Se o valor for maior ou igual ao mínimo da próxima categoria, o cliente sobe uma categoria.<br />
                    </p>
                :
                    <p>de acordo com o <strong>% de participação da Cargill Nutrição Animal no total de animais tratados do seu negócio </strong> – Market Share:</p>
                }
                <S.HowItWorksTopSectionHomeLink to="/inicio">
                    <img src={openfarmLogo} alt="Logotipo do produto Openfarm" />
                </S.HowItWorksTopSectionHomeLink>
            </S.HowItWorksTopSection>
            <S.HowItWorksSwipper>
                {specieSelected.carrosel.map((card, index) => (
                    <S.HowItWorksSwipperRanking key={index}>
                        <img src={card.badge} alt="Imagem do selo da categoria" />
                        <h2>{specieSelected.categories[index]}</h2>
                        <p>{card.range}</p>
                    </S.HowItWorksSwipperRanking>
                ))}
            </S.HowItWorksSwipper>
            <S.HowItWorksTopBanner>
                <div>
                    <h2>A cada 3 meses</h2>
                    <strong>este % será reavaliado e você poderá mudar de categoria,</strong> alterando também os indicadores e benefícios do Programa.
                </div>
                <img src={topBannerImage} alt="Imagem de uma máscara floral com o texto: Maior a categoria, maiores os benefícios!" />
            </S.HowItWorksTopBanner>
            <S.HowItWorksMidSection>
                <h2>De acordo com a sua categoria</h2>
                <p>você garante um % de retorno, que será convertido em pontos no Programa. Veja:</p>
                <S.HowItWorksMidSectionTable>
                    <thead>
                        <tr>
                            {specieSelected.categories.map((category, index) => (
                                <th key={index}>{category}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {specieSelected.tabela.map((t, tableIndex) => (
                            <tr key={tableIndex}>
                                {t.content.map((content, index) => index === 0 ? <S.Marker color={t.color} key={index}>{content}</S.Marker> : <td key={index}>{content}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </S.HowItWorksMidSectionTable>
                <S.HowItWorksMidSectionLabel>
                    {specieSelected.tabela.map((t, index) => <S.Block color={t.color} key={index}>
                        <div></div>
                        <p>{t.title}<span> {t.subTitle}</span></p>
                    </S.Block>)}
                </S.HowItWorksMidSectionLabel>
                <S.HowItWorksMidSectionWarning>
                {specieSelected.catalog === 81541 ?
                    'Não será concedido rebate para volume abaixo de 70%, ingredientes, medicamentos e produtos devolvidos.'
                :
                    'Não será concedido rebate para % de Market Share abaixo de 50%, ingredientes e medicamentos.'
                }
                </S.HowItWorksMidSectionWarning>
            </S.HowItWorksMidSection>
            <S.HowItWorksMidBanner src={midBanner} alt="Imagem de um trator e alguns outros itens de premiação, com o texto: Troque seus pontos por benefícios exclusivos na Open Farm® " />
            <S.HowItWorksBottomSection>
                <h2>Open Farm® Awards: abrindo caminho para você crescer.</h2>
                <p>Gostariamos de prestigiar ainda mais os nossos clientes e o Open Farm® Awards veio para premiar os melhores projetos dentro do Open Farm® . Seus projetos serão avaliados anualmente, de acordo com cada categoria, Sustentabilidade,  Inovação e tecnologia e Pessoas. Dessa maneira, conseguimos reinvestir ainda mais nos premiados.</p>
                <S.HowItWorksBottomSectionBadges>
                    <img src={sustentabilidadeBadge} alt="Imagem do selo Openfarm Sustentabilidade" />
                    <img src={inovacaoBadge} alt="Imagem do selo de Openfarm Inovação" />
                    <img src={pessoasBadge} alt="Imagem do selo de Openfarm Pessoas" />
                </S.HowItWorksBottomSectionBadges>
            </S.HowItWorksBottomSection>
        </S.HowItWorks>
        <Toolbar />
    </S.HowItWorksContent>
}

export default HowItWorks