import { ReactElement, useCallback, useEffect, useState } from 'react';
import greenArrow from '../../assets/green-arrow-icon.png';
import BalanceModalContent from '../../components/BalanceModalContent';
import DesktopBanner from '../../components/DesktopBanner';
import FooterComplete from '../../components/FooterComplete';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import Toolbar from '../../components/Toolbar';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { useSpecies } from '../../contexts/SpeciesContext';
import api from '../../services/api';
import * as S from './style';

function Shop () {
  const { showBottomModal } = useBottomModal()
  const { specieSelected } = useSpecies()

  const [link, setLink] = useState<string>('')
  const handleRedirect = useCallback(async () => {
    const response = await api.get(`/cargill-catalogo/${specieSelected.catalog}`)
    setLink(response.data.url)
  }, [specieSelected])

  const [linkBene, setLinkBene] = useState<string>('')
  const handleRedirectBeneficios = useCallback(async () => {
    const response = await api.get(`/cargill-catalogo/81544`)
    setLinkBene(response.data.url)
  }, [])

  const handleShowModal = useCallback((component: ReactElement, event: any) => {
    event.stopPropagation()
    showBottomModal(component)
  }, [showBottomModal])

  useEffect(() => {
    handleRedirect()
    handleRedirectBeneficios()
  }, [handleRedirect, handleRedirectBeneficios])

  return <S.GoldBoxContent>
    <Header element={<MenuButton />} borderBottom showDesktopWidgets />
    <DesktopBanner title='Loja' />
    <S.GoldBox>
      <h2>Em qual conta você deseja resgatar?</h2>
      <S.GoldBoxOptions>
        <S.GoldBoxOptionsOption href={link} target="_blank">
          <p>Conta Reinvestimento</p>
          <img src={greenArrow} alt="ícone de seta apontando para direita (indicando opção Conta Reinvestimento)" />
        </S.GoldBoxOptionsOption>
        <S.GoldBoxOptionsOption href={linkBene} target="_blank">
          <p>Conta Benefício</p>
          <img src={greenArrow} alt="ícone de seta apontando para direita (indicando opção Conta Benefício)" />
        </S.GoldBoxOptionsOption>
      </S.GoldBoxOptions>
      <S.GoldBoxContentButton onClick={(event) => handleShowModal(<BalanceModalContent />, event)}>
        Entenda a diferença
      </S.GoldBoxContentButton>
    </S.GoldBox>
    <S.FooterCompleteContainer>
      <FooterComplete />
    </S.FooterCompleteContainer>
    <Toolbar />
  </S.GoldBoxContent>
}

export default Shop