import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useBottomModal } from '../../contexts/BottomModalContext'
import { useSpecies } from '../../contexts/SpeciesContext'
import api from '../../services/api'
import SelectSpeciesModalContent from '../SelectSpeciesModalContent'
import blogIconGreen from './assets/blog-icon-green.svg'
import blogIconWhite from './assets/blog-icon-white.svg'
import goldBoxIconGreen from './assets/goldBox-icon-green.svg'
import goldBoxIconWhite from './assets/goldBox-icon-white.svg'
import homeIconGreen from './assets/home-icon-green.svg'
import homeIconWhite from './assets/home-icon-white.svg'
import speciesIconWhite from './assets/species-icon-white.svg'
import storeIconWhite from './assets/store-icon-white.svg'
import walletIconGreen from './assets/wallet-icon-green.png'
import walletIconWhite from './assets/wallet-icon-white.png'
import * as S from './style'

function Toolbar () {
  const [activeOption, setActiveOption] = useState<string | null>(null);
  const [goldBoxLink, setGoldBoxLink] = useState<string>('')
  const location = useLocation();
  const { showBottomModal } = useBottomModal()
  const { userCan } = useAuth()
  const { specieSelected } = useSpecies()

  const handlePathnameToOption = useCallback((pathname: string) => {
    switch (pathname) {
      case '/inicio':
        return 'inicio';
      case '/loja':
        return 'loja';
      case '/gold-box':
        return 'gold-box';
      case '/blog':
        return 'blog';
      case '/carteira':
        return 'carteira';
      default:
        return null;
    }
  }, []);

  useEffect(() => {
    const { pathname } = location;
    setActiveOption(handlePathnameToOption(pathname));
  }, [location, handlePathnameToOption]);

  useEffect(() => {
    (async () => {
      if (!specieSelected) return
      const response = await api.get(`/cargill-gold-box/${specieSelected.catalog}`)
      setGoldBoxLink(response.data.url)
    })()
  }, [specieSelected]);

  return <S.Toolbar>
    <S.ToolbarLinkOption to="/inicio" $active={activeOption === 'inicio'} >
      <img src={activeOption === 'inicio' ? homeIconGreen : homeIconWhite} alt="Ícone indicando opção de navegação para a página Início" />
      Início
    </S.ToolbarLinkOption>
    {userCan('read:shop') && <S.ToolbarLinkOption to="/loja" $active={activeOption === 'loja'} >
      <img src={storeIconWhite} alt="Ícone indicando opção de navegação para a página Loja" />
      Loja
    </S.ToolbarLinkOption>}
    {userCan('read:wallet') && <S.ToolbarLinkOption to="/carteira" $active={activeOption === 'carteira'} >
      <img src={activeOption === 'carteira' ? walletIconGreen : walletIconWhite} alt="Ícone indicando opção de navegação para a carteira" />
      Carteira
    </S.ToolbarLinkOption>}
    {userCan('read:gold-box') && <S.ToolbarLinkOptionAnchor href={goldBoxLink} target="_blank" $active={activeOption === 'gold-box'} >
      <img src={activeOption === 'gold-box' ? goldBoxIconGreen : goldBoxIconWhite} alt="Ícone indicando opção de navegação para a página GoldBox" />
      Gold Box
    </S.ToolbarLinkOptionAnchor>}
    <S.ToolbarLinkOption to="/blog" $active={activeOption === 'blog'} >
      <img src={activeOption === 'blog' ? blogIconGreen : blogIconWhite} alt="Ícone indicando opção de navegação para a página Blog" />
      Blog
    </S.ToolbarLinkOption>
    {userCan('read:species') && <S.ToolbarOption onClick={() => showBottomModal(<SelectSpeciesModalContent />)}>
      <img src={speciesIconWhite} alt="Ícone indicando opção de navegação para a página Espécies" />
      Espécies
    </S.ToolbarOption>}
  </S.Toolbar>
}

export default Toolbar