import { ChangeEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmDeletePost from '../../components/ConfirmDeletePost';
import DesktopBanner from '../../components/DesktopBanner';
import FooterComplete from '../../components/FooterComplete';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import InputFloatingLabel from '../../components/InputFloatingLabel';
import MessageAnalysisModalContent from '../../components/MessageAnalysisModalContent';
import PostDetailsModalContent from '../../components/PostDetailsModalContent';
import TextAreaFloatingLabel from '../../components/TextAreaFloatingLabel';
import Toolbar from '../../components/Toolbar';
import { useAuth } from '../../contexts/AuthContext';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { RemoteCreateNewPost } from '../../data/use-case/RemoteCreateNewPost';
import { RemoteFetchPosts } from '../../data/use-case/RemoteFetchPosts';
import { FetchPosts } from '../../domain/use-case/FetchPosts';
import api from '../../services/api';
import trashIcon from './assets/trash-icon.png';
import * as S from './style';

const fetchPosts = new RemoteFetchPosts()
const createNewPost = new RemoteCreateNewPost()

enum BlogPostsStatus {
    LOADING,
    READY
}

interface BlogPosts {
    currentStatus: BlogPostsStatus
    data: FetchPosts.Result[]
}

function Blog () {
    const [isFeedSelected, setIsFeedSelected] = useState<boolean>(true)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null)
    const [file, setFile] = useState<File>()
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [embedVideoLink, setEmbedVideoLink] = useState<string>('')
    const [isPostButtonActive, setIsPostButtonActive] = useState<boolean>(false)
    const [isPostButtonDisabled, setIsPostButtonDisabled] = useState<boolean>(true)
    const [blogPosts, setBlogPosts] = useState<BlogPosts>({
        currentStatus: BlogPostsStatus.LOADING,
        data: []
    })
    const fileInputRef = useRef<HTMLInputElement>(null)
    const { showBottomModal } = useBottomModal()
    const { userCan } = useAuth()
    const [content, setContent] = useState<string>('')

    const handleSwitchChange = useCallback(() => {
        setIsFeedSelected(isFeedSelected ? false : true)
    }, [isFeedSelected])

    const resetPostContent = useCallback(() => {
        setSelectedImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setSelectedFileName(null)
        setVideoUrl('')
        setEmbedVideoLink('')
        setIsPostButtonActive(false)
    }, [])

    const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setSelectedImage(reader.result);
                }
            };
            reader.readAsDataURL(file)
            setSelectedFileName(file.name)
            setFile(file)
            setIsPostButtonActive(true)
            if (fileInputRef.current) {
                fileInputRef.current.value = ''
            }
        }
    }, []);

    const handleEmbedVideoLink = useCallback((url: string) => {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        var match = url.match(regExp)

        if (url.length < 8) return
        if (match && match[2].length === 11) {
            setEmbedVideoLink(`https://www.youtube.com/embed/${match[2]}`)   
            setIsPostButtonActive(true)
            return
        }
        toast.error('Por favor, cole um link do YouTube válido.', { theme: 'colored', position: 'bottom-right' })
        setEmbedVideoLink('')
    }, [])

    const handleChangeVideoLink = useCallback((value: string) => {
        resetPostContent()
        setVideoUrl(value)
        handleEmbedVideoLink(value)
    }, [handleEmbedVideoLink, resetPostContent])

    const handleImageClick = useCallback(() => {
        if (selectedImage) resetPostContent()
    }, [resetPostContent, selectedImage])

    const handlePost = useCallback(async () => {
        try {
            await createNewPost.handle({
                image: file,
                videoUrl: embedVideoLink,
                content
            })
            showBottomModal(<MessageAnalysisModalContent />)
            resetPostContent()
            setContent('')
        } catch (err) {
            toast.error("Ocorreu um erro, por favor tente novamente mais tarde", { theme: 'colored' })
        }
    }, [content, embedVideoLink, file, resetPostContent, showBottomModal])

    const handleApprove = useCallback(async (index: number) => {
        try {
            const post = blogPosts.data[index]
            await api.patch(`/posts/${post.id}/approve`)
            const newData = [...blogPosts.data]
            newData[index].postStatus = 'APPROVED'
            setBlogPosts({ ...blogPosts, data: newData })
        } catch (err) {
            toast.error('Houve um problema ao tentar aprovar o post', { theme: 'colored', position: 'bottom-right' })
        }
    }, [blogPosts])

    const handleDisaprove = useCallback(async (index: number) => {
        try {
            const post = blogPosts.data[index]
            await api.patch(`/posts/${post.id}/reprove`)
            const newData = blogPosts.data.filter(p => p.id !== post.id)
            setBlogPosts({ ...blogPosts, data: newData })
        } catch (err) {
            console.log(err)
            toast.error('Houve um problema ao tentar reprovar o post', { theme: 'colored', position: 'bottom-right' })
        }
    }, [blogPosts])

    const handleDeletePost = useCallback((postId: number) => {
        const postsFiltered = blogPosts.data.filter(p => p.id !== postId)
        setBlogPosts({
            data: postsFiltered,
            currentStatus: BlogPostsStatus.READY
        })
    }, [blogPosts.data])

    const handleConfirmDeletePost = useCallback(async (postId: number) => {
        showBottomModal(<ConfirmDeletePost postId={postId} onDeletePost={handleDeletePost} />)
    }, [handleDeletePost, showBottomModal])

    const handleShowModal = useCallback((component: ReactElement, event: any) => {
        event.stopPropagation()
        showBottomModal(component)
      }, [showBottomModal])

    useEffect(() => {
        if (!content) {
            setIsPostButtonDisabled(true)
            return
        }
        if (!embedVideoLink && !file) {
            setIsPostButtonDisabled(true)
            return
        }
        setIsPostButtonDisabled(false)
    }, [content, file, embedVideoLink])

    useEffect(() => {
        (async () => {
            setBlogPosts({
                data: [],
                currentStatus: BlogPostsStatus.LOADING
            })
            const data = await fetchPosts.handle({ amount: 3 })
            setBlogPosts({
                currentStatus: BlogPostsStatus.READY,
                data
            })
        })()
    }, [])

    return <S.BlogContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Blog' borderBottom showDesktopWidgets />
        <DesktopBanner title='Blog' />
        <S.Blog>
            <h2>Fique por dentro das novidades que movem o Programa.</h2>
            <S.BlogToggleContent>
                {userCan('post:blog') && <S.BlogToggleContentSwitch>
                    <S.BlogToggleContentSwitchButton onClick={handleSwitchChange} $isSelected={isFeedSelected}>
                        Últimos posts
                    </S.BlogToggleContentSwitchButton>
                    <S.BlogToggleContentSwitchButton onClick={handleSwitchChange} $isSelected={!isFeedSelected}>
                        Postar
                    </S.BlogToggleContentSwitchButton>
                </S.BlogToggleContentSwitch>}
                <S.BlogToggleContentBlock $isShown={isFeedSelected}>
                    <S.BlogPosts>
                        {blogPosts.data.map((post, index) => (
                            <S.BlogPost key={index} postStatus={post.postStatus}>
                                {userCan('delete:blog') && <S.DeletePostButton onClick={() => handleConfirmDeletePost(post.id)}>
                                    <img src={trashIcon} alt='Ação: excluir post' />
                                </S.DeletePostButton>}
                                {post.image && <S.BlogPostImage src={post.image} onClick={(event) => handleShowModal(<PostDetailsModalContent postId={post.id} postComments={post.comments} postLikes={post.likes} />, event)} />}
                                {post.videoUrl && <S.BlogPostVideo>
                                    <iframe
                                        src={post.videoUrl}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    />
                                </S.BlogPostVideo>}
                                <S.BlogPostTools onClick={(event) => handleShowModal(<PostDetailsModalContent postId={post.id} postComments={post.comments} postLikes={post.likes} />, event)}>
                                    <S.BlogPostToolLike />
                                    <S.BlogPostToolComment />
                                    <S.BlogPostDate >{post.createdAt.toLocaleDateString('pt-br')}</S.BlogPostDate>
                                </S.BlogPostTools>
                                <S.BlogPostUser onClick={(event) => handleShowModal(<PostDetailsModalContent postId={post.id} postComments={post.comments} postLikes={post.likes} />, event)}>@{post.owner.name}</S.BlogPostUser>
                                <S.BlogPostText onClick={(event) => handleShowModal(<PostDetailsModalContent postId={post.id} postComments={post.comments} postLikes={post.likes} />, event)}>{post.content}</S.BlogPostText>

                                <div className="controlls">
                                    <button onClick={() => handleApprove(index)} className='approve'>Aprovar</button>
                                    <button onClick={() => handleDisaprove(index)} className='disaprove'>Desaprovar</button>
                                </div>
                            </S.BlogPost>
                        ))}
                    </S.BlogPosts>
                    <S.BlogToggleContentBlockButtons>
                        <S.BlogButtonOulined>Mostrar mais</S.BlogButtonOulined>
                    </S.BlogToggleContentBlockButtons>
                </S.BlogToggleContentBlock>
                <S.BlogToggleContentBlock $isShown={!isFeedSelected}>
                    <p>Selecione uma imagem ou cole o link do vídeo do YouTube e depois escreva uma legenda sobre ele.</p>
                    <S.BlogInputContainers>
                        <S.BlogInputContainer onClick={resetPostContent}>
                            <S.BlogInputHidden ref={fileInputRef} onChange={handleFileChange} />
                            <S.BlogInputButton $isFileSelected={selectedFileName ? true : false} $disabled={!!embedVideoLink}>
                                <p>Escolher foto</p>
                                <span>{selectedFileName}</span>
                                <div>Selecionar</div>
                            </S.BlogInputButton>
                        </S.BlogInputContainer>
                        <S.BlogInputContainer>
                                <InputFloatingLabel inputType='text' inputTitle='Cole o link do vídeo do YouTube' onValueChange={handleChangeVideoLink} value={videoUrl} disabled={!!selectedImage} inputCleared={!!selectedImage} />
                        </S.BlogInputContainer>
                    </S.BlogInputContainers>
                    {(selectedImage || embedVideoLink) && <S.BlogPostContent>
                        {selectedImage && <S.BlogPostContentPreview onClick={handleImageClick}>
                            <img src={selectedImage} alt="Selected" />
                            <img className='remove-icon' src={trashIcon} alt="Ação: remover imagem" />
                        </S.BlogPostContentPreview>}
                        {embedVideoLink && <S.BlogPostContentPreview>
                        <iframe
                            src={embedVideoLink}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                        <img className='remove-icon' src={trashIcon} alt="Ação: remover imagem" />
                        </S.BlogPostContentPreview>}
                        <S.BlogPreviewBlock>
                            <TextAreaFloatingLabel textAreaTitle='Mensagem' onValueChange={setContent} />
                            <S.BlogToggleContentBlockButtons>
                                <S.BlogButtonFilled
                                    $isActive={isPostButtonActive}
                                    onClick={handlePost}
                                    disabled={isPostButtonDisabled}>
                                        Postar
                                </S.BlogButtonFilled>
                            </S.BlogToggleContentBlockButtons>
                        </S.BlogPreviewBlock>
                    </S.BlogPostContent>
                    }
                </S.BlogToggleContentBlock>
            </S.BlogToggleContent>
        </S.Blog>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
        <Toolbar />
    </S.BlogContent>
}

export default Blog