import { useBottomModal } from '../../contexts/BottomModalContext'
import * as S from './style'

function BalanceModalContent() {
    const { closeBottomModal } = useBottomModal()
    return <S.BalanceModalContent>
        <h2>Entenda seu saldo</h2>
        <p>
            <span>Conta “Reinvestimento”:</span><br />
            trata-se do saldo reservado para <strong>resgatar produtos, serviços e ferramentas atrelados a reinvestimentos no próprio negócio.</strong> Cada espécie terá sua pontuação. É de no mínimo <strong>75% do crédito aportado a cada mês,</strong> podendo chegar a 100% caso haja transferência da conta “Benefícios.
            <br /><br />
            <span>Conta “Benefícios”:</span><br />
            trata-se do saldo reservado para <strong>resgatar produtos para usufruto próprio. É de no máximo 25% do crédito aportado a cada mês.</strong> Importante destacar a possibilidade de transferir esses pontos para a conta “Reinvestimento”. Aqui trata-se da soma do valor reservado a benefícios para cada uma das espécies.
        </p>
        <S.BalanceModalContentButtons>
            <button className='button-outlined' onClick={closeBottomModal}>Entendi!</button>
        </S.BalanceModalContentButtons>
    </S.BalanceModalContent>
}

export default BalanceModalContent