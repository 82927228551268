import styled from 'styled-components';

export const SelectSpeciesModalContent = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    gap: 24px;
    h2 {
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-family: var(--font-bold);
    }
`;

export const SelectSpeciesModalContentButtons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
`;

export const SelectSpeciesModalContentButton = styled.button<{ $isSelected: boolean }>`
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.438rem;
    padding: 6px;
    font-family: var(--font-bold);
    border-radius: 28px;
    color: ${({ $isSelected }) => ($isSelected ? 'var(--primary)' : 'var(--black)')};
    border: ${({ $isSelected }) => ($isSelected ? '2px solid var(--primary)' : '2px solid #88A8A4')};
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--white);

    .checkbox {
        border-radius: 50%;
        border: ${({ $isSelected }) => ($isSelected ? '1px solid var(--primary)' : '1px solid #88A8A4')};
        width: 24px;
        height: 24px;
        margin-right: 10px;
        position: relative;
        margin-left: auto;

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 7px);
            left: calc(50% - 7px);
            width: 14px;
            height: 14px;
            background-color: ${({ $isSelected }) => ($isSelected ? 'var(--primary)' : 'transparent')};
            border-radius: 50%;
        }
    }

`

export const SelectSpeciesModalContentButtonImage = styled.div`
    width: 44px;
    height: 44px;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green-neon);
    margin-right: 9px;

    img {
        max-width: 35px;
        max-height: 24px;
    }
`