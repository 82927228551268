import styled from 'styled-components';

export const MyInfosContent = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
    overflow: auto;
`;

export const MyInfos = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;
    gap: 24px;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        border-radius: 30px 30px 0 0;
        min-height: unset;
        padding-bottom: 72px;
        flex-direction: row;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 24px;
        max-width: 305px;
    }
`;

export const MyInfosBlock = styled.div`
    width: 100%;
    border-radius: 10px;
    padding: 24px 0px 0px 0px;
    border: 1px solid #DBE4EF;
    display: flex;
    flex-direction: column;

    h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 24px;
        padding: 0px 12px;
    }
`;

export const MyInfosBlockContent = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    padding: 0px 12px;
`;

export const MyInfosUserName = styled.h3`
    width: 100%;
    font-size: 1.125rem;
    color: var(--primary);
    font-family: var(--font-bold);
    line-height: 1.438rem;
    margin-bottom: 12px;
`;

export const MyInfosBlockContentItem = styled.div`
    width: 100%;
    color: var(--black);
    font-size: 1rem;
    line-height: 1.125rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        max-width: 154px;
        font-family: var(--font-bold);
        text-align: right;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: -0.4px;
    }
`;

export const MyInfosBlockLink = styled.div`
    cursor: pointer;
    width: 100%;
    color: var(--black);
    font-size: 1rem;
    line-height: 1.125rem;
    font-family: var(--font-bold);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 12px 20px 12px;
    border-top: 1px solid #88A8A4;

    img {
        width: 14px;
        height: 14px;
        transform: rotate(270deg);
    }
`;

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`
