import styled from 'styled-components';

export const AddShortcutContent = styled.section`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: var(--black);
`;

export const AddShortcut = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;
`;


export const DeviceInstructionsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #88A8A4;

    h2 {
        font-family: var(--font-bold);
        margin-bottom: 32px;
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        width: 100%;
    }
    h3 {
        margin-bottom: 32px;
        color: #88A8A4;
        font-size: 1rem;
        line-height: 0.875rem;
        letter-spacing: -0.4px;
        width: 100%;
    }
`

export const DeviceInstructionsItem = styled.div`
    width: 100%;
    padding: 24px 0px;
    border-top: 1px solid #88A8A4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

export const DeviceInstructionsItemHeader = styled.div<{ $isToggled: boolean }>`
    width: 100%;
    cursor: pointer;
    div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--primary);
        font-size: 1.125rem;
        line-height: 1.438rem;
        font-family: var(--font-bold);
        margin-bottom: 4px;

        img {
            width: 14px;
            height: 14px;
            transition: transform 0.3s ease;
            transform: rotateX(${({ $isToggled }) => ($isToggled ? `180deg` : `0deg`)});
        }
    }

    p {
        width: 100%;
        margin-bottom:${({ $isToggled }) => ($isToggled ? `24px` : `0`)};
        font-size: 0.875rem;
        color: #11281F;
        font-family: var(--font-bold);
    }

`

export const DeviceInstructionsItemContent = styled.div<{ $isShown: boolean }>`
    width: 100%;
    color: var(--black);
    font-size: 0.875rem;
    line-height: 1rem;
    transition: all 0.3s ease;
    display: ${({ $isShown }) => ($isShown ? `flex` : `none`)};
    flex-direction: column;
    gap: 32px;
    overflow: hidden;
`

export const DeviceInstructionsItemContentInstruction = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    justify-content: flex-start;

    >div {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        color: #11281F;
        font-family: var(--font-bold);
        font-size: 1rem;
        letter-spacing: -0.4px;
        line-height: 0.875rem;

        div {
            background-color: var(--green-neon);
            width: 32px;
            min-width: 32px;
            height: 32px;
            color: #11281F;
            font-size: 1.125rem;
            font-family: var(--font-bold);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }
    img {
        width: 100%;
        max-width: 100%;
    }
`