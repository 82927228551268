import styled from 'styled-components';

export const Backdrop = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: rgba(0,0,0,0.7);
    pointer-events: none;
    z-index: 1000;
`

export const Loader = styled.div`
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--green-neon);
    animation: l15 1s infinite linear;
    position: relative;
    top: calc(50vh - 25px);
    left: calc(50vw - 25px);

    &::before,
    &::after {
        content: "";
        grid-area: 1/1;
        margin: 2px;
        border: inherit;
        border-radius: 50%;
        animation: l15 2s infinite;
    }

    &::after {
        margin: 8px;
        animation-duration: 3s;
    }
    
    @keyframes l15{ 
        100%{transform: rotate(1turn)}
    }
`;