import styled from 'styled-components';

export const HomeItemsSwipper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto; 
    overflow-y: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HomeItemsSwipperItemsContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 36px;
    gap: 10px;

    @media only screen and (min-width: 960px) {
        gap: 16px;
    }
`;

export const HomeItemsSwipperItem = styled.button`
    width: 154px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 12px 12px 20px;
    border-radius: 20px;
    box-shadow: 0px 3px 16px #001D4129;
    background-color: var(--white);
    text-decoration: none;

    @media only screen and (min-width: 960px) {
        width: 202px;
        padding: 24px 12px 20px 12px;
    }
`;

export const HomeItemsSwipperItemImage = styled.img`
    width: 100%;
    max-width: 130px;
    height: 130px;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 14px;
    object-fit: contain;
`;

export const HomeItemsSwipperItemTitle = styled.h2`
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    color: #2D473E;
    font-size: 0.875rem;
    line-height: 1rem;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 8px;
    width: 100%;
    font-family: 'Gilroy-Medium',sans-serif;

    @media only screen and (min-width: 960px) {
        text-align: left;
    }
`;

export const HomeItemsSwipperItemPrice = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: var(--font-bold);
    margin-top: auto;
    p {
        color: #2D473E;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    span {
        color: var(--primary);
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 0 auto;
        text-wrap: nowrap;

        @media only screen and (min-width: 960px) {
            margin: 0;
            font-size: 1.5rem;
        }

        sup {
            font-size: 0.875rem;
        }
    }
`;
