import styled from 'styled-components';

export const GoldBoxContent = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
`;

export const GoldBox = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        min-height: unset;
        padding-bottom: 72px;
        min-height: auto;
    }

    h2 {
        font-family: var(--font-bold);
        margin-bottom: 32px;
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        width: 100%;
    }
`;


export const GoldBoxOptions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #88A8A4;
    border-radius: 8px;
    margin-bottom: 32px;
    max-width: 638px;
`

export const GoldBoxOptionsOption = styled.a`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #88A8A4;
    background: none;
    text-decoration: none;

    p {
        font-size: 1rem;
        line-height: 0.875rem;
        letter-spacing: -0.4px;
        color: #11281F;
        font-family: var(--font-bold);
    }

    img {
        width: 14px;
        transform: rotate(270deg)
    }

    &:last-of-type {
        border-bottom: none;
    }
`

export const GoldBoxContentButton = styled.button`
    width: 100%;
    color: var(--primary);
    font-size: 1.125rem;
    line-height: 1.438rem;
    background: none;
    font-family: var(--font-bold);
    text-align: left;

    @media only screen and (min-width: 960px) {
        margin-bottom: 48px;
    }
`

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`
