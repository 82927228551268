import { useCallback } from 'react'
import { useBottomModal } from '../../contexts/BottomModalContext'
import { useSpecies } from '../../contexts/SpeciesContext'
import * as S from './style'

function SelectSpeciesModalContent () {
    const { species, changeSpecie, specieSelected } = useSpecies()
    const { closeBottomModal } = useBottomModal()

    const handleChangeSpecie = useCallback((specie: any) => {
        changeSpecie(specie.id)
        closeBottomModal()
    }, [changeSpecie, closeBottomModal])

    if (!species) return <></>

    return <S.SelectSpeciesModalContent>
        <h2>
            Selecione uma espécie
        </h2>
        <S.SelectSpeciesModalContentButtons>
            {species.map((specie) => <>
                <S.SelectSpeciesModalContentButton key={specie.id} onClick={() => handleChangeSpecie(specie)} $isSelected={specie.id === specieSelected.id}>
                    <S.SelectSpeciesModalContentButtonImage>
                        <img src={specie.icon} alt={'Ícone representando a categoria ' + specie.name} />
                    </S.SelectSpeciesModalContentButtonImage>
                    {specie.name}
                    <div className='checkbox'></div>
                </S.SelectSpeciesModalContentButton>
            </>
            )}
        </S.SelectSpeciesModalContentButtons>
    </S.SelectSpeciesModalContent>
}

export default SelectSpeciesModalContent