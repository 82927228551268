import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import Loader from '../../components/Loader'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import { MaskDate, MaskPhone } from '../../utils/mask'
import * as S from './style'

enum State {
  LOADING,
  READY,
  SENDING,
  SUCCESS,
  ERROR
}

interface UpdateProfileAttributes {
  currentState: State,
  data: {
    cpfRespLegal: string
    document: string
    birthday: string
    phone: string
    email: string
    name: string
    respLegal: string
  }
}

function UpdateProfile () {
  const { user, userCan } = useAuth()
  const [state, setState] = useState<UpdateProfileAttributes>({
    currentState: State.READY,
    data: {
      cpfRespLegal: '',
      document: '',
      birthday: '',
      phone: '',
      email: '',
      name: '',
      respLegal: ''
    }
  })
  const handleUpdateProfileRequest = useCallback(async () => {
    try {
      setState((state) => ({ ...state, currentState: State.SENDING }))
      await api.put('/short/update', state.data)
      toast.success('Seus dados foram atualizado com sucesso', { theme: 'colored' })
    } catch (err) {
      toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
    }
    setState((state) => ({ ...state, currentState: State.READY }))
  }, [state])

  useEffect(() => {
    (async () => {
      const response = await api.get(`/me`)
      setState({
        currentState: State.READY,
        data: {
          cpfRespLegal: response.data.cpfRespLegal,
          document: response.data.document,
          birthday: new Date(response.data.birthday).toLocaleDateString('pt-br'),
          phone: response.data.cellphone,
          email: response.data.email,
          name: response.data.name,
          respLegal: response.data.respLegal
        }
      })
    })()
  }, [user])

  if (state.currentState === State.LOADING) return <Loader />

  return <S.UpdateProfileContent>
    <Header element={<ReturnPageButton />} logo={false} pageTitle='Atualizar meus dados' />
    <S.UpdateProfile>
      {!userCan('update:company') && <InputFloatingLabel value={state.data.name} inputTitle='Nome' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, name: value } })} />}
      {userCan('update:company') && <InputFloatingLabel value={state.data.respLegal} inputTitle='Nome do responsável legal' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, respLegal: value } })} />}
      {!userCan('update:company') && <InputFloatingLabel value={state.data.document} inputTitle='CPF' inputType='text' disabled />}
      {userCan('update:company') && <InputFloatingLabel value={state.data.cpfRespLegal} inputTitle='CPF' inputType='text' disabled />}
      <InputFloatingLabel value={state.data.birthday} inputTitle='Data de nascimento' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, birthday: value } })} mask={MaskDate} />
      <InputFloatingLabel value={state.data.phone} inputTitle='Celular' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, phone: value } })} mask={MaskPhone} />
      <InputFloatingLabel value={state.data.email} inputTitle='Email' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, email: value } })} />
      <S.UpdateProfileButton onClick={handleUpdateProfileRequest} disabled={state.currentState === State.SENDING}>{state.currentState === State.SENDING ? 'Enviando' : 'Enviar'}</S.UpdateProfileButton>
    </S.UpdateProfile>
  </S.UpdateProfileContent>
}

export default UpdateProfile