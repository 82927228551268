import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import SelectFloatingLabel from '../../components/SelectFloatingLabel'
import TextAreaFloatingLabel from '../../components/TextAreaFloatingLabel'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import { MaskPhone } from '../../utils/mask'
import * as S from './style'

const subjectOptions = [
    {
        optionTitle: 'Dúvidas',
        optionValue: 'Dúvidas'
    },
    {
        optionTitle: 'Sugestões',
        optionValue: 'Sugestões'
    },
    {
        optionTitle: 'Reclamações',
        optionValue: 'Reclamações'
    }
]

enum State {
    READY,
    SENDING,
    SUCCESS
}

interface MessageStatus {
    state: State,
    message: {
        name: string
        email: string
        phone: string
        assunto: string
        msg: string
    }
}

function ContactUs () {
    const [status, setStatus] = useState<MessageStatus>({
        state: State.READY,
        message: {
            name: '',
            email: '',
            phone: '',
            assunto: subjectOptions[0].optionValue,
            msg: ''
        }
    })
    const { closeBottomModal } = useBottomModal()
    const navigate = useNavigate()

    const handleContactUsRequest = useCallback(async () => {
        try {
            setStatus((status) => ({
                ...status,
                state: State.SENDING
            }))
            await api.post('/message', status.message)
            setStatus((status) => ({
                state: State.READY,
                message: {
                    name: '',
                    email: '',
                    phone: '',
                    assunto: subjectOptions[0].optionValue,
                    msg: ''
                }
            }))
            toast.success('Sua mensagem foi enviada', { theme: 'colored' })
        } catch (err) {
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
        closeBottomModal()
        navigate('/login')
    }, [closeBottomModal, navigate, status.message])

    return <S.ContactUsContent>
        <Header element={<ReturnPageButton />} />
        <S.ContactUs>
            <h2>Fale conosco.</h2>
            <p>Dúvidas, criticas ou sugestões? Envie sua mensagem pra gente.</p>
            <InputFloatingLabel value={status.message.name} inputTitle='Nome completo' inputType='text' onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, name: value } }))} />
            <InputFloatingLabel value={status.message.email} inputTitle='E-mail' inputType='text' onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, email: value } }))} />
            <InputFloatingLabel value={status.message.phone} inputTitle='Celular' inputType='text' onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, phone: value } }))} mask={MaskPhone} />
            <SelectFloatingLabel selectTitle='Assunto' selectOptions={subjectOptions} onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, assunto: value } }))} />
            <TextAreaFloatingLabel value={status.message.msg} textAreaTitle='Mensagem' onValueChange={(value: string) => setStatus((state) => ({ ...state, message: { ...state.message, msg: value } }))} />
            <S.ContactUsButton onClick={handleContactUsRequest} disabled={status.state === State.SENDING}>{status.state === State.SENDING ? 'Enviando' : 'Enviar'}</S.ContactUsButton>
        </S.ContactUs>
        <S.FooterContainer>
            <Footer />
        </S.FooterContainer>
    </S.ContactUsContent>
}

export default ContactUs