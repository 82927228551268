import { useMemo } from 'react'
import DesktopBanner from '../../components/DesktopBanner'
import FooterComplete from '../../components/FooterComplete'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import HowItWorksMidSectionTable from '../../components/HowItWorksMidSectionTable'
import Loader from '../../components/Loader'
import Toolbar from '../../components/Toolbar'
import { useSpecies } from '../../contexts/SpeciesContext'
import avesBg from './assets/aves-bg.png'
import corteBg from './assets/corte-bg.png'
import midBannerDesktop from './assets/how-it-works-mid-banner-desktop.png'
import midBannerMobile from './assets/how-it-works-mid-banner-mobile.png'
import topBannerImage from './assets/how-it-works-top-banner-image.png'
import leiteBg from './assets/leite-bg.png'
import inovacaoBadge from './assets/openfarm-badge-inovacao.png'
import pessoasBadge from './assets/openfarm-badge-pessoas.png'
import sustentabilidadeBadge from './assets/openfarm-badge-sustentabilidade.png'
import suinosBg from './assets/suinos-bg.png'
import * as S from './style'

const speciesPageData: Record<number, any> = {
   1: {
      id: 3,
      topImage: suinosBg,
      header: 'Sua empresa será classificada',
      categoryRulesBlock: '<p>de acordo com o <strong>% de participação da Cargill Nutrição Animal no total de animais tratados do seu negócio –</strong> <br /> Market Share:</p>',
      name: 'Suínos',
      table: {
         specieKpis: {
            upperKpis: [
               {
                  name: "Percentual",
                  values: ["50% a 59,9%", "60% a 69,9%", "70% a 79,9%", "80% a 99,9%", "100%"],
                  warning: ""
               }
            ],
            lowerKpis: [
               {
                  name: "Programas Nutricionais",
                  values: ["0,5%", "1,5%", "2%", "2,5%", "2,5%"],
                  warning: ""
               },
               {
                  name: "Rações Neopigg",
                  values: ["0,2%", "0,6%", "0,8%", "1%", "1%"],
                  warning: ""
               }
            ]
         },
         childrensKpis: []
      },
      rebateWarning: '<p><span>Rebate: </span>percentual de retorno, que será convertido em pontos no Programa.</p>Não será concedido rebate para % de Market Share abaixo de 50%, ingredientes e medicamentos.'
   },
   2: {
      id: 2,
      topImage: avesBg,
      header: 'Sua empresa será classificada de acordo com o volume de produção.',
      categoryRulesBlock: '<h3>A tecnologia afetará a classificação:</h3><div class="list"><p>• se o valor for menor que o mínimo da categoria, o cliente desce uma categoria</p><p>• se o valor for maior ou igual ao mínimo da próxima categoria, o cliente sobe uma categoria.</p></div>',
      name: 'Aves',
      table: {
         specieKpis: {
            upperKpis: [
               {
                  name: "CFE Total",
                  values: ["70", "80", "80", "90", "100"],
                  warning: ""
               },
               {
                  name: "Tec.",
                  values: ["50", "60", "70", "80", "100"],
                  warning: ""
               }
            ],
            lowerKpis: [
               {
                  name: "Aditivos (Por fora)",
                  values: ["0,5%", "0,7%", "0,8%", "1%", "1,2%"],
                  warning: ""
               },
               {
                  name: "Planilhas Nutricionais",
                  values: ["0,3%", "0,4%", "0,5%", "0,7%", "0,9%"],
                  warning: ""
               }
            ]
         },
         childrensKpis: []
      },
      rebateWarning: '<p><span>Tec.:</span> todo produto NC e PX que contenha ingredientes além de vitaminas e minerais.</p><p><span>Rebate:</span> percentual de retorno, que será convertido em pontos no Programa.</p>Não será concedido rebate para volume abaixo de 70%, ingredientes, medicamentos e produtos devolvidos.'
   },
   3: {
      id: 4,
      topImage: corteBg,
      header: 'Sua empresa será classificada',
      categoryRulesBlock: '<p>de acordo com o <strong>% de participação da Cargill Nutrição Animal no total de animais tratados do seu negócio –</strong> Market Share:</p>',
      name: 'Corte',
      table: {
         specieKpis: {
            upperKpis: [
               {
                  name: "Share",
                  values: ["50%", "60%", "70%", "80%", "100%"],
                  warning: ""
               }
            ],
            lowerKpis: [
               {
                  name: "Linha máxima",
                  values: ["0,5%", "1,5%", "2,0%", "2,5%", "2,5%"],
                  warning: ""
               },
               {
                  name: "Linha performa",
                  values: ["0,4%", "0,6%", "0,8%", "1,0%", "1,5%"],
                  warning: ""
               },
               {
                  name: "Linha pasto",
                  values: ["0,2%", "0,4%", "0,6%", "0,8%", "1,0%"],
                  warning: ""
               }
            ]
         },
         childrensKpis: []
      },
      rebateWarning: '<p><span>Rebate:</span> percentual de retorno, que será convertido em pontos no Programa.</p>Não será concedido rebate para % de Market Share abaixo de 50%, ingredientes e medicamentos.'
   },
   4: {
      id: 1,
      topImage: leiteBg,
      header: 'Sua empresa será classificada de acordo com o volume de produção.',
      categoryRulesBlock: '<h3>A tecnologia afetará a classificação:</h3><div class="list"><p>• se o valor for menor que o mínimo da categoria, o cliente desce uma categoria</p><p>• se o valor for maior ou igual ao mínimo da próxima categoria, o cliente sobe uma categoria.</p></div><span>O cliente Open Farm deve ter fábrica interligada.</span>',
      name: 'Leite',
      table: {
         specieKpis: {
            title: 'Fábrica',
            upperKpis: [
               {
                  name: "CFE Total",
                  values: ["60", "70", "80", "90", "100"],
                  warning: ""
               },
               {
                  name: "Tec.",
                  values: ["20", "30", "40", "50", "60"],
                  warning: ""
               }
            ],
            lowerKpis: [
               {
                  name: "Aditivos",
                  values: ["0,2%", "0,5%", "1%", "1,5%", "2%"],
                  warning: ""
               },
               {
                  name: "Nutrição Dairy",
                  values: ["0,2%", "0,5%", "1%", "1,5%", "2%"],
                  warning: ""
               },
               {
                  name: "Espec. Lacteas",
                  values: ["0%", "0%", "0,2%", "0,5%", "0,8%"],
                  warning: ""
               },
               {
                  name: "Espec. Nutricionais",
                  values: ["0%", "0%", "0,2%", "0,5%", "0,8%"],
                  warning: ""
               }
            ],
         },
         childrensKpis: [
            {
               title: 'Loja',
               upperKpis: [
                  {
                     name: "Volume",
                     values: ["10", "20", "30", "40", "50"],
                     warning: ""
                  }
               ],
               lowerKpis: [
                  {
                     name: "Aditivos",
                     values: ["0,2%", "0,5%", "1%", "1,5%", "2%"],
                     warning: "somente adsorvente."
                  },
                  {
                     name: "Nutrição Dairy",
                     values: ["0,2%", "0,5%", "1%", "1,5%", "2%"],
                     warning: ""
                  },
                  {
                     name: "Espec. Lacteas",
                     values: ["0%", "0%", "0,2%", "0,5%", "0,8%"],
                     warning: ""
                  },
                  {
                     name: "Espec. Nutricionais",
                     values: ["0%", "0%", "0,2%", "0,5%", "0,8%"],
                     warning: ""
                  }
               ]
            }
         ]
      },
      rebateWarning: '<p><span>Tec.:</span> todo produto NC e PX que tenha no mínimo 3 aditivos adicionados.</p><p><span>Rebate:</span> percentual de retorno, que será convertido em pontos no Programa.</p>Não será concedido rebate para percentual de CFE abaixo de 50% (cinquenta), ingredientes, medicamentos e produtos devolvidos. Será excluída Gordura Bypass.'
   },
   5: {
      id: 3,
      topImage: suinosBg,
      header: 'Sua empresa será classificada',
      categoryRulesBlock: '<p>de acordo com o <strong>% de participação da Cargill Nutrição Animal no total de animais tratados do seu negócio –</strong> <br /> Market Share:</p>',
      name: 'Suínos',
      table: {
         specieKpis: {
            upperKpis: [
               {
                  name: "Percentual da Fase Reprodutiva",
                  values: ["50%", <>50%<br />50%</>, <>70%<br />50%</>, <>100%<br />50%</>, <>100%<br />50%<br />50%</>],
                  warning: ""
               }
            ],
            lowerKpis: [
               {
                  name: "Programas Nutricionais",
                  values: ["0,5%", "1,0%", "1,5%", "2,0%", "2,0%"],
                  warning: ""
               },
               {
                  name: "Rações",
                  values: ["0,2%", "0,6%", "0,8%", "1,0%", "1,0%"],
                  warning: ""
               }
            ]
         },
         childrensKpis: []
      },
      rebateWarning: '<p><span>Rebate: </span>percentual de retorno, que será convertido em pontos no Programa.</p>Não será concedido rebate para % de Market Share abaixo de 50%, ingredientes e medicamentos.'
   }
}

function HowItWorksNew () {
   const { specieSelected } = useSpecies()

   const activeSpecie = useMemo(() => {
      if (!specieSelected) return speciesPageData[1]
      if (!speciesPageData[specieSelected.id]) return speciesPageData[1]
      return speciesPageData[specieSelected.id]
   }, [specieSelected])

   if (!specieSelected) return <Loader />

   return <S.HowItWorksContent>
      <Header element={<MenuButton />} logo={false} pageTitle='Como funciona' borderBottom showDesktopWidgets />
      <DesktopBanner title='Como funciona' />
      <S.HowItWorks>
         <S.HowItWorksTopSection>
            <div className='top-banner'>
               <img src={activeSpecie.topImage} alt="Imagem represetando um animal da espécie selecionada" />
               <div className="top-banner-mask"></div>
               <S.SpecieName>{activeSpecie.name}</S.SpecieName>
            </div>
            <h2>{activeSpecie.header}</h2>
            <div dangerouslySetInnerHTML={{ __html: activeSpecie.categoryRulesBlock }}></div>
         </S.HowItWorksTopSection>
         <S.HowItWorksMidSection>
            <HowItWorksMidSectionTable specieData={activeSpecie} />
            <S.HowItWorksMidSectionWarning dangerouslySetInnerHTML={{ __html: activeSpecie.rebateWarning }} />
         </S.HowItWorksMidSection>
         <S.HowItWorksTopBanner>
            <div>
               <h2>A cada 3 meses</h2>
               <strong>este % será reavaliado e você poderá mudar de categoria,</strong> alterando também os indicadores e benefícios do Programa.
            </div>
            <img src={topBannerImage} alt="Imagem de uma máscara floral com o texto: Maior a categoria, maiores os benefícios!" />
         </S.HowItWorksTopBanner>
         <S.HowItWorksMidBanner>
            <source media="(min-width: 960px)" srcSet={midBannerDesktop}/>
            <img src={midBannerMobile} alt="Imagem de um trator e alguns outros itens de premiação, com o texto: Troque seus pontos por benefícios exclusivos na Open Farm®"/>
         </S.HowItWorksMidBanner>
         <S.HowItWorksBottomSection>
            <h2>Open Farm® Awards: abrindo caminho para você crescer.</h2>
            <p>Todo ano, seu negócio será avaliado de acordo com os quesitos tecnologia e inovação. <br /><br />De acordo com o resultado, um plano de ação será elaborado para priorizar e suportar a evolução do seu negócio nas áreas que necessitam de atenção.<strong> Isso é abrir caminho para uma nova experiência e para o seu crescimento.</strong></p>
            <S.HowItWorksBottomSectionBadges>
               <img src={sustentabilidadeBadge} alt="Imagem do selo Openfarm Sustentabilidade" />
               <img src={inovacaoBadge} alt="Imagem do selo de Openfarm Inovação" />
               <img src={pessoasBadge} alt="Imagem do selo de Openfarm Pessoas" />
            </S.HowItWorksBottomSectionBadges>
         </S.HowItWorksBottomSection>
      </S.HowItWorks>
      <S.FooterCompleteContainer>
         <FooterComplete />
      </S.FooterCompleteContainer>
      <Toolbar />
   </S.HowItWorksContent>
}

export default HowItWorksNew