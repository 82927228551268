import { ReactElement, useCallback } from 'react'
import cargillLogo from '../../assets/cargil-logo.png'
import { useAuth } from "../../contexts/AuthContext"
import { useBottomModal } from "../../contexts/BottomModalContext"
import PoliticaDePrivacidadeContent from "../PoliticaDePrivacidadeContent"
import RegulamentoContent from "../RegulamentoContent"
import TermosDeUsoContent from "../TermosDeUsoContent"
import * as S from './style'

function FooterComplete() {
  const { showBottomModal } = useBottomModal()
  const { userCan } = useAuth()

  const handleShowModal = useCallback((component: ReactElement, event: any) => {
    event.stopPropagation()
    showBottomModal(component)
  }, [showBottomModal])

  return <S.FooterComplete>
    <S.FooterCompleteNav>
      <ul>
          <li onClick={(event) => handleShowModal(<RegulamentoContent />, event)}>Regulamentos</li>
          <li onClick={(event) => handleShowModal(<PoliticaDePrivacidadeContent />, event)}>Política de Privacidade</li>
          {userCan('read:terms') && <li onClick={(event) => handleShowModal(<TermosDeUsoContent />, event)}>Termos de Uso</li>}
      </ul>
    </S.FooterCompleteNav>
    <div>
      <p>© 2023 Cargill, Incorporated. Todos os direitos reservados.</p>
      <img src={cargillLogo} alt="Logotipo da empresa Cargill" />
    </div>
  </S.FooterComplete>
}

export default FooterComplete