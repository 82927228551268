import axios from 'axios'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import PasswordChangeRequestModalContent from '../../components/PasswordChangeRequestModalContent'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import { MaskDocument } from '../../utils/mask'
import * as S from './style'

enum PageState {
    READY,
    SENDING
}

interface ForgotPasswordState {
    currentState: PageState
    document: string
}

function ForgotPassword () {
    const { showBottomModal } = useBottomModal()
    const [state, setState] = useState<ForgotPasswordState>({
        currentState: PageState.READY,
        document: ''
    })

    const handleForgotPasswordRequest = useCallback(async () => {
        try {
            setState((state) => ({
                ...state,
                currentState: PageState.SENDING
            }))
            await api.post('/session/recovery/', { email: state.document })
            showBottomModal(<PasswordChangeRequestModalContent />)
        } catch (err) {
            if (axios.isAxiosError(err)) {
                switch (err.response?.status) {
                    case 403:
                        toast.error('Este email não está cadastrado', { theme: 'colored' })
                        return
                }
            }
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
    }, [showBottomModal, state.document])

    return <S.ForgotPasswordContent>
        <Header element={<ReturnPageButton />} />
        <S.ForgotPassword>
            <h2>Esqueceu sua senha?</h2>
            <p>
                Fale para a gente o seu CNPJ ou CPF que enviaremos as instruções de recuperação no e-mail cadastrado.
            </p>
            <InputFloatingLabel inputTitle='CNPJ ou CPF' inputType='text' mask={MaskDocument} onValueChange={(document: string) => setState((state) => ({ ...state, document }))} />
            <S.ForgotPasswordSupport>
                Precisa de ajuda? <Link to="/fale-conosco">Fala com a gente!</Link>
            </S.ForgotPasswordSupport>
            <S.ForgotPasswordButton onClick={handleForgotPasswordRequest}>Enviar</S.ForgotPasswordButton>
        </S.ForgotPassword>
        <Footer />
    </S.ForgotPasswordContent>
}

export default ForgotPassword