import whiteArrow from '../../assets/white-arrow-icon.png';
import { maskPoints } from '../../utils/mask';
import * as S from './style';

interface UserPointsBlockLinkProps {
  points: number
  title: string
  isVisible: boolean
}

function UserPointsBlockLink({ points, title, isVisible }: UserPointsBlockLinkProps) {
  return <S.UserPointsBlockLink to="/transfira-seus-pontos" $isVisible={isVisible}>
  <div>
      {title}
      <div className='block-number'>{maskPoints(points)} pts</div>
  </div>
  <img src={whiteArrow} alt="Ícone de seta para a direita, indicando um link que pode ser clicado" />
</S.UserPointsBlockLink>
}

export default UserPointsBlockLink