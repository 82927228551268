import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import BackButton from '../../components/Header/BackButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import { useAuth } from '../../contexts/AuthContext'
import { MaskDocument } from '../../utils/mask'
import loginImage1360 from './assets/login-image-1-360.png'
import loginImage1900 from './assets/login-image-1-900.png'
import loginImage1 from './assets/login-image-1.png'
import loginImage2360 from './assets/login-image-2-360.png'
import loginImage2900 from './assets/login-image-2-900.png'
import loginImage2 from './assets/login-image-2.png'
import * as S from './style'


function Login () {
    const [showUsernameScreen, setShowUsernameScreen] = useState<boolean>(true)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { doLogin } = useAuth()

    const handleUsernameChange = useCallback((value: string) => {
        setUsername(value)
    }, [])

    const handlePasswordChange = useCallback((value: string) => {
        setPassword(value)
    }, [])

    const handleLogin = useCallback(async () => {
        await doLogin(username, password)
    }, [doLogin, password, username])


    return <S.Login >
        <Header element={!showUsernameScreen && <BackButton onClick={() => setShowUsernameScreen(true)} />} />
        {showUsernameScreen ?
            <S.LoginContent>
                <picture>
                    <source media="(max-width: 359px)" srcSet={loginImage1360} />
                    <source media="(min-width: 900px)" srcSet={loginImage1900} />
                    <img src={loginImage1} alt="Imagem de um homem sorrindo e concentrado, interagindo com um tablet" />
                </picture>
                <S.LoginContentInputsContainer>
                    <p>Informe seu CNPJ ou CPF.</p>
                    <S.LoginContentInputsContainerBlock>
                        <InputFloatingLabel
                            inputType="text"
                            inputTitle='CNPJ ou CPF'
                            onValueChange={handleUsernameChange}
                            mask={MaskDocument}
                            value={username}
                        />
                        <button onClick={() => setShowUsernameScreen(false)}>Continuar</button>
                    </S.LoginContentInputsContainerBlock>
                </S.LoginContentInputsContainer>
            </S.LoginContent>
            :
            <S.LoginContent>
                <picture>
                    <source media="(max-width: 359px)" srcSet={loginImage2360} />
                    <source media="(min-width: 900px)" srcSet={loginImage2900} />
                    <img src={loginImage2} alt="Imagem de um homem sorrindo e concentrado, interagindo com um tablet" />
                </picture>
                <S.LoginContentInputsContainer>
                    <p>Informe sua senha.</p>
                    <S.LoginContentInputsContainerBlock>
                        <InputFloatingLabel
                            inputType="password"
                            inputTitle='Senha'
                            onValueChange={handlePasswordChange}
                            inputCleared={true}
                        />
                        <button onClick={handleLogin}>Entrar</button>
                    </S.LoginContentInputsContainerBlock>
                    <S.LoginContentTextAction>
                        <Link to="/recuperar-senha">Esqueci minha senha</Link>
                    </S.LoginContentTextAction>
                </S.LoginContentInputsContainer>
            </S.LoginContent>
        }
        <Footer />
    </S.Login >
}

export default Login