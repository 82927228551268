import * as S from './style';

function HomeItemsSwipperSkeleton() {
    const items = Array.from({ length: 9 })

    return (
        <S.HomeItemsSwipperSkeleton>
            <S.HomeItemsSwipperSkeletonItemsContent>
                {items.map((_, i) => (
                    <S.HomeItemsSwipperSkeletonItem key={i}>
                        <S.HomeItemsSwipperSkeletonItemImage />
                        <S.HomeItemsSwipperSkeletonItemTitle />
                        <S.HomeItemsSwipperSkeletonItemTitle />
                        <S.HomeItemsSwipperSkeletonItemPrice>
                            <p>A partir de</p>
                            <S.HomeItemsSwipperSkeletonItemPriceText />
                        </S.HomeItemsSwipperSkeletonItemPrice>
                    </S.HomeItemsSwipperSkeletonItem>
                ))}
            </S.HomeItemsSwipperSkeletonItemsContent>
        </S.HomeItemsSwipperSkeleton>
    )
}

export default HomeItemsSwipperSkeleton