import styled from 'styled-components';
import sendArrow from './assets/send-arrow.png';

export const PostDetailsModalContentHeader = styled.div`
    width: 100%;
    display: flex;
    padding: 24px 36px;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 900px;
`;

export const PostDetailsModalContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 25px;
    margin: 0 auto;
    max-height: 600px;
    
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PostDetailsModalContentTopSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h2 {
        font-size: 1.125rem;
        line-height: 1.125rem;
        color: var(--black);
        font-family: var(--font-bold);

    }
`;

export const PostDetailsModalContentTools = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        img {
            width: 21px;
            min-width: 21px;
        }
        p {
            font-size: 1.125rem;
            line-height: 1.125rem;
            color: #88A8A4;
        }

    }
`;

export const PostDetailsModalContentCommentsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: var(--white);
    justify-content: flex-start;
    align-items: flex-start;
`

export const PostDetailsModalContentComment = styled.div<{ $newComment?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    max-width: calc(100vh - 70px);
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #88A8A4;
    gap: 8px;

    h2 {
        font-family: var(--font-bold);
        font-size: 0.875rem;
        line-height: 0.75rem;
        color: ${({ $newComment }) => ($newComment ? 'var(--primary)' : '#2D473E')};
        width: 100%;
    }
    p {
        font-size: 0.75rem;
        line-height: 0.75rem;
        color: ${({ $newComment }) => ($newComment ? 'var(--primary)' : '#2D473E')};
        width: 100%;
    }
`

export const PostDetailsModalContentAddComment = styled.input`
    width: 100%;
    padding: 25px 74px 25px 36px;
    background-color: #2D473E;
    color: var(--white);
    font-family: var(--font-bold);
    font-size: 1rem;
    line-height: 0.875rem;
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 900px;

    &::placeholder {
        font-size: 1rem;
        line-height: 0.75rem;
        font-family: 'Gilroy-Medium',sans-serif;
        color: rgba(255, 255, 255, 0.7);
    }
`

export const PostDetailsModalContentAddCommentButton = styled.button<{ $isVisible: boolean }>`
    width: 24px;
    height: 24px;
    position: fixed;
    right: 36px;
    bottom: 23px;
    opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
    background-image: url(${sendArrow});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
`


