import styled from 'styled-components';
import gillBackgroundImage from './assets/gill-background-image.png';

export const GillHubItem = styled.div`
    width: 100%;
    background-color: #11281E;
    padding: 12px;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    background-image: url(${gillBackgroundImage});
    background-position: top right;
    background-size: 108px 95px;
    background-repeat: no-repeat;

    @media only screen and (min-width: 960px) {
        padding-bottom: 24px;
    }

    h2 {
        color: var(--green-neon);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        width: 100%;

        @media only screen and (min-width: 960px) {
            margin-bottom: 10px;
        }
    }
    p {
        color: var(--white);
        font-size: 0.75rem;
        font-family: var(--font-bold);
        max-width: 250px;

        @media only screen and (min-width: 960px) {
            font-size: 1rem;
        }
    }
`;