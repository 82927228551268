import styled, { css } from 'styled-components';
import commentIcon from '../../assets/icons/tool-comment-icon.png';
import likeIcon from '../../assets/icons/tool-like-icon.png';

export const BlogContent = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
`;

export const Blog = styled.div`
    padding: 30px 36px 36px 36px;
    border-radius: 30px 30px 0 0;
    background-color: var(--white);
    margin-top: 70px;
    padding-bottom: 80px;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        border-radius: 30px 30px 0 0;
        min-height: unset;
        padding-bottom: 96px;
    }

    >h2 {
        color: #11281F;
        font-family: var(--font-bold);
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 24px;

        @media only screen and (min-width: 960px) {
            font-size: 1.313rem;
        }
    }
`

export const BlogToggleContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export const BlogToggleContentSwitch = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    border: 2px solid var(--primary);
    border-radius: 27px;
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 638px;
`

export const BlogToggleContentSwitchButton = styled.button<{ $isSelected: boolean }>`
    padding: 11px 10px;
    border-radius: 27px;
    width: 49.9%;
    text-align: center;
    font-size: 1rem;
    font-family: var(--font-bold);
    color: ${({ $isSelected }) => ($isSelected ? 'var(--white)' : 'var(--black)')};
    background-color: ${({ $isSelected }) => ($isSelected ? 'var(--primary)' : 'transparent')};
    transition: all 0.3s ease-in-out;
`

export const BlogToggleContentBlock = styled.div<{ $isShown: boolean }>`
    width: 100%;
    display: ${({ $isShown }) => ($isShown ? 'flex' : 'none')};;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    >p {
        font-size: 1rem;
        color: #11281F;
        letter-spacing: -0.4px;
        line-height: 1.125rem;
        margin-bottom: 24px;
        margin-top: 8px;
    }
`

export const BlogPosts = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

export const BlogPreviewBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`

export const BlogToggleContentBlockButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;

    @media only screen and (min-width: 960px) {
        margin-bottom: 0px;
    }
`

export const BlogButtonOulined = styled.button`
    padding: 16px;
    width: 100%;
    border: 2px solid var(--primary);
    font-size: 1.125rem;
    font-family: var(--font-bold);
    text-align: center;
    color: var(--primary);
    border-radius: 28px;
    background: none;
    max-width: 420px;
    margin: 0 auto;
`

export const BlogButtonFilled = styled.button<{ $isActive: boolean }>`
    background-color: ${({ $isActive }) => ($isActive ? 'var(--primary)' : '#EBF3EF')};
    padding: 16px;
    width: 100%;
    font-size: 1.125rem;
    font-family: var(--font-bold);
    text-align: center;
    color: ${({ $isActive }) => ($isActive ? 'var(--white)' : '#88A8A4')};
    border-radius: 28px;
    max-width: 440px;

    &[disabled] {
            background-color: #EBF3EF;
            border: none;
            cursor: not-allowed; 
        }
`

const approved = css`
    .controlls {
        display: none;
    }
`
const waiting = css`
    border: 2px solid var(--primary);
    .controlls {
        display: flex;
        justify-content: space-evenly;
        margin-top: 16px;
        width: 100%;

        .approve, .disaprove {
            background-color: var(--primary);
            border-radius: 8px;
            color: var(--white);
            font-weight: bold;
            padding: 8px 16px;
        }
    }
`

export const BlogPost = styled.div<{ postStatus: string }>`
    width: 318px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 20px;
    box-shadow: 0px 3px 16px #001D4129;
    background-color: var(--white);
    padding-bottom: 18px;
    margin-bottom: 24px;
    position: relative;
    ${({ postStatus }) => postStatus === 'APPROVED' ? approved : waiting}
`;

export const DeletePostButton = styled.button`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dc3545;
    border-radius: 4px;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
        opacity: 0.8;
        transition: all 0.4s ease-in-out
    }

    img {
        width: 45%;
    }
`

export const BlogPostImage = styled.img`
    width: 100%;
    max-height: 200px;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    border-radius: 20px 20px 0 0;
`;

export const BlogPostVideo = styled.div`
    width: 100%;
    max-height: 200px;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    border-radius: 20px 20px 0 0;

    iframe {
        width: 100%;
        border-radius: inherit;
    }
`;

export const BlogPostTools = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 4px;
    width: 100%;
`;

export const BlogPostToolLike = styled.button`
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-image: url(${likeIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
`;

export const BlogPostToolComment = styled.button`
    width: 16px;
    height: 16px;
    background-image: url(${commentIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
`;

export const BlogPostDate = styled.p`
    color: #88A8A4;
    font-size: 0.75rem;
    margin-left: auto;
`;

export const BlogPostUser = styled.h2` 
    color: var(--primary);
    font-size: 0.875rem;
    line-height: 0.75rem;
    text-overflow: ellipsis;
    margin-bottom: 6px;
    padding: 0 12px;
    font-family: var(--font-bold);
    width: 100%;
`;

export const BlogPostText = styled.p`
    display: -webkit-box;
    color: #88A8A4;
    font-size: 0.75rem;
    overflow: auto;
    padding: 0 12px;
    width: 100%;
    max-height: 90px;

    &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
    }
    
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border: 0px none #ffffff;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--green-neon);
    }
    &::-webkit-scrollbar-thumb:active {
        background: var(--green-neon);
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
        background: #fff;
    }
    &::-webkit-scrollbar-track:active {
        background: #fff;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
`;

export const BlogPostContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 990px;
    gap: 16px;
    
    @media only screen and (min-width: 960px) {
        flex-direction: row;
        margin: 0 auto;
    }
`

export const BlogInputContainers = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
        gap: 16px;
    }
`

export const BlogInputContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 600px;

    >div {
        margin-bottom: 0px;
    }
`

export const BlogInputHidden = styled.input.attrs({ type: 'file', accept: 'image/*', })`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
`;

export const BlogInputButton = styled.button<{ $isFileSelected: boolean, $disabled: boolean }>`
    border: ${({ $disabled }) => ($disabled ? 'none' : '1px solid #88a8a4')};
    border-radius: 28px;
    padding: ${({ $isFileSelected }) => ($isFileSelected ? '26px 25px 10px 24px' : '5px')};
    width: 100%;
    background-color: ${({ $disabled }) => ($disabled ? '#EBF3EF' : 'transparent')};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        color: var(--black);
        font-size: ${({ $isFileSelected }) => ($isFileSelected ? '0.75rem' : '1rem')};
        line-height: 1.125rem;
        font-family: 'Gilroy-Medium', sans-serif;
        margin-left: ${({ $isFileSelected }) => ($isFileSelected ? '0px' : '19px')};
        position: ${({ $isFileSelected }) => ($isFileSelected ? 'absolute' : 'static')};
        top: 11px;
        left: 24px;
        transition: all 0.3s ease-in-out;
    }
    span {
        display: ${({ $isFileSelected }) => ($isFileSelected ? 'block' : 'none')};
        font-size: 1rem;
        line-height: 1.125rem;
        font-family: var(--font-bold);
        color: var(--black);
        max-width: 100%;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
    }
    div {
        border-radius: 27px;
        padding: 13px 16px;
        background-color: var(--primary);
        color: var(--white);
        font-family: var(--font-bold);
        font-size: 1rem;
        line-height: 1rem;
        display: ${({ $isFileSelected }) => ($isFileSelected ? 'none' : 'block')};
    }
`

export const BlogPostContentPreview = styled.div`
    width: 100%;
    border: 1px solid #88A8A4;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media only screen and (min-width: 960px) {
        margin-bottom: 0px;
    }
    
    &:hover > .remove-icon {
        opacity: 0.8;
        visibility: visible;
        transition: all 0.4s ease-in-out;
    }

    .remove-icon {
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        width: 60px;
    }

    img {
        cursor: pointer;
    }
    
    iframe, img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: inherit;
        object-fit: contain;
    }
`

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`