import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import BottomModal from "../../components/BottomModal";

interface BottomModalContextProps {
    isBottomModalOpen: boolean
    showBottomModal: ( element: ReactNode ) => void
    closeBottomModal: () => void
}

interface BottomModalProviderProps {
    children: ReactNode
}

const BottomModalContext = createContext<BottomModalContextProps | undefined>(undefined)

function BottomModalProvider ({ children }: BottomModalProviderProps) {
    const [isBottomModalOpen, setIsBottomModalOpen] = useState<boolean>(false)
    const [modalContent, setModalContent] = useState<ReactNode | null>(null)

    const canScroll = useCallback((lockScroll: boolean) => {
        !lockScroll ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [])

    const showBottomModal = useCallback(( element: ReactNode ) => {
        setModalContent(element);
        setIsBottomModalOpen(true);
        canScroll(false)
    }, [canScroll])
  
    const closeBottomModal = useCallback(() =>{
        setIsBottomModalOpen(false);
        canScroll(true)
    }, [canScroll])
  
    return <BottomModalContext.Provider value={{ isBottomModalOpen, showBottomModal, closeBottomModal }}>
        {children}
        <BottomModal>
            {modalContent}
        </BottomModal>
    </BottomModalContext.Provider>
}

export const useBottomModal = () => {
    const context = useContext(BottomModalContext);
    if (!context) {
        throw new Error('useBottomModal must be used within a BottomModalProvider');
    }
    return context;
};

export default BottomModalProvider