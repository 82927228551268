import styled from 'styled-components';

export const BalanceModalContent = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;

    h2 {
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        span {
            font-family: var(--font-bold);
            color: var(--primary);
            font-size: 1rem;
        }
        strong {
            font-family: var(--font-bold);
            font-weight: normal;
        }
    }
`;

export const BalanceModalContentButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 29px;

    button {
        width: 100%;
        font-size: 1.125rem;
        padding: 17px;
        font-family: var(--font-bold);
        border-radius: 28px;
    }
    .button-outlined {
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
`;