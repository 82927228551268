import styled from 'styled-components';

export const InputFloatingLabel = styled.div<{ $isActive: boolean, $isDisabled: boolean }>`
    border: 1px solid #88A8A4;
    border-radius: 28px;
    padding: 19px 24px;
    width: 100%;
    margin-bottom: 24px;
    position: relative;
    height: 56px;
    background-color: ${({ $isDisabled }) => ($isDisabled ? '#EBF3EF' : 'var(--white)')};
    label {
        position: absolute;
        color: var(--black);
        font-size: ${({ $isActive }) => ($isActive ? '0.75rem' : '1rem')};
        font-weight: bold;
        top: ${({ $isActive }) => ($isActive ? '10px' : '19px')};
        left: 24px;
        transition: all 0.3s ease-in-out;
    }

    input {
        color: var(--black);
        font-size: 1rem;
        width: 100%;
        font-family: ${({ $isActive }) => ($isActive ? `var(--font-bold)` : `'Gilroy-Medium',sans-serif`)};
        margin-top: 1px;
        border-radius: ${({ $isActive }) => ($isActive ? '0px' : '28px')};
        z-index: 10;
        position: relative;
        top: 7px;
        background: transparent;
        height: ${({ $isActive }) => ($isActive ? 'auto' : '50px')};

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[disabled] {
            background-color: #EBF3EF;
            border: none;
            cursor: not-allowed; 
        }
    }
`;

export const EyeButton = styled.button`
    aspect-ratio: 1;
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 13px;
    width: 30px;
    z-index: 12;
`