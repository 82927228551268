import styled from 'styled-components';

export const RegulamentoContent = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
`;

export const Regulamento = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        min-height: unset;
        padding-bottom: 72px;
        min-height: auto;
    }

    h2 {
        font-family: var(--font-bold);
        margin-bottom: 32px;
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        width: 100%;
    }
`;

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`
