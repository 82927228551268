import styled from 'styled-components';

export const TransferYourPointsContent = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
    overflow: auto;

    h2 {
        color: var(--black);
        font-size: 1.313rem;
        font-family: var(--font-bold);
        padding: 0px 12px;

        @media only screen and (min-width: 960px) {
            margin-bottom: 24px;
        }
    }
`;

export const TransferYourPoints = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: #EBF3EF;
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;
    gap: 24px;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        border-radius: 30px 30px 0 0;
        min-height: unset;
        padding-bottom: 72px;
    }
`

export const TransferYourPointsBlocks = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
        margin-top: -30px;
        border-radius: 30px 30px 0 0;
        min-height: unset;
        padding-bottom: 72px;
    }
`;

export const TransferYourPointsTopSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 36px 36px 36px;
    background-color: var(--white);
    border-radius: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 5;

    @media only screen and (min-width: 960px) {
        max-width: 638px;
        margin: 0;
        padding: 32px;
    }

    h2 {
        font-family: var(--font-bold);
        margin-bottom: 24px;
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        width: 100%;
    }
`;

export const TransferYourPointsOverview = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    background-color: #2D473E;
    padding: 20px;
    box-shadow: 0px 3px 16px #001D4129;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
        padding: 0px 0px 0px 22px;
    }
`

export const TransferYourPointsOverviewImage = styled.img`
    width: 59px;
    min-width: 59px;
    align-self: center;

    @media only screen and (max-width: 420px) {
        width: 38.62px;
        min-width: 38.62px;
    }
    
`

export const TransferYourPointsOverviewInfos = styled.div`
    width: 100%;
    
`

export const TransferYourPointsOverviewTotal = styled.div`
    width: calc(100% - 24px);
    padding: 20px 0px 14.5px 0px;
    margin: 0px 12px;
    border-bottom: 1px solid rgba(136, 168, 164, 0.47);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    img {
        width: 38.62px;
        min-width: 38.62px;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        p {
            font-size: 0.625rem;
            color: #C0C8C5;
            font-family: var(--font-bold);
            line-height: 0.813rem;
        }
        span {
            font-size: 1.313rem;
            color: var(--green-neon);
            font-family: var(--font-bold);
            line-height: 1.5rem;
            margin-top: -2px;
        }
    }
`

export const TransferYourPointsOverviewOthers = styled.div`
    width: calc(100% - 24px);
    padding: 14.5px 0px 20px 0px;
    margin: 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
        gap: 45px;
    }
`

export const TransferYourPointsOverviewOthersItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        p {
            font-size: 0.625rem;
            color: rgba(255, 255, 255, 0.7);
            font-family: var(--font-bold);
            line-height: 0.813rem;
        }
        span {
            font-size: 1.313rem;
            font-family: var(--font-bold);
            color: var(--green-neon);
            line-height: 1.25rem;
            margin-top: 2px;
        }
    }

    img {
        width: 7px;
    }
`

export const AvisoLimite = styled.p`
    color: var(--white);
    padding: 15px;
    font-size: 0.75rem;

    @media only screen and (min-width: 960px) {
        font-size: 1rem;
    }

    span {
        color: var(--green-neon);
        font-family: var(--font-bold)
    }
`

export const TransferYourPointsBottomSection = styled.div`
    padding: 64px 0px 36px 0px;
    background-color: var(--gray-bg);
    margin-top: -32px;
    width: 100%;

    @media only screen and (min-width: 960px) {
        max-width: 638px;
        margin: 0;
        padding: 0px;
    }
`

export const TransferYourPointsToggleContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export const TransferYourPointsToggleContentSwitch = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border: 2px solid var(--primary);
    border-radius: 27px;
    margin-bottom: 24px;
`

export const TransferYourPointsToggleContentSwitchButton = styled.button<{ $isSelected: boolean }>`
    padding: 12px 40px;
    border-radius: 27px;
    width: 49.9%;
    text-align: center;
    font-size: 1rem;
    font-family: var(--font-bold);
    color: ${({ $isSelected }) => ($isSelected ? 'var(--white)' : 'var(--black)')};
    background-color: ${({ $isSelected }) => ($isSelected ? 'var(--primary)' : 'transparent')};
    transition: all 0.3s ease-in-out;
`

export const TransferYourPointsToggleContentBlock = styled.div<{ $isShown: boolean }>`
    width: 100%;
    display: ${({ $isShown }) => ($isShown ? 'flex' : 'none')};;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    >p {
        font-size: 1rem;
        color: #11281F;
        font-family: var(--font-bold);
        letter-spacing: -0.4px;
        line-height: 1.125rem;
        margin-bottom: 24px;
        margin-top: 26px;
    }
`

export const SelectFloatingLabelContainer = styled.div`
    width: 100%;

    div {
        background-color: var(--white);
    }
`

export const TransferYourPointsToggleContentBlockInput = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
        max-width: 638px;
        margin: 0;
        padding: 0px;
        flex-direction: row;
    }
    
    div {
        background-color: var(--white);
        margin: 0;
    }

    button {
        padding: 16px;
        width: 100%;
        border: 2px solid var(--primary);
        font-size: 1.125rem;
        font-family: var(--font-bold);
        text-align: center;
        color: var(--primary);
        border-radius: 28px;

        @media only screen and (min-width: 960px) {
            max-width: 202px;
        }
    }

    button[disabled] {
        background-color: var(--white);
        color: #88A8A4;
        cursor: not-allowed;
    }

    .green-button {
        background-color: var(--primary);
        border: none;
        color: var(--white);
    }
`

export const TransferYourPointsWarning = styled.div`
    width: 100%;
    padding: 0px 36px;
    transition: all 0.3s ease-in-out;
    p {
        font-size: 0.875rem;
        color: var(--black);
        letter-spacing: -0.4px;
        line-height: 1rem;

        span {
            color: var(--primary);
            font-family: var(--font-bold);
        }

        strong { 
            font-family: var(--font-bold);
        }
    }
`

export const TransferYourPointsToggleContentBlockButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
    margin-top: 21px;

    button {
        font-size: 0.938rem;
        font-family: var(--font-bold);
        color: var(--primary);
    }
`

export const TransferYourPointsToggleTransactions = styled.div`
    width: 100%;
    border-top: 1px solid #88A8A4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export const TransferYourPointsToggleTransactionsItem = styled.div`
    width: 100%;
    padding: 12px 40px;
    border-bottom: 1px solid #88A8A4;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
`

export const TransferYourPointsToggleTransactionsItemDate = styled.p`
    color: #2D473E;
    font-size: 0.875rem;
    line-height: 0.75rem;
`

export const TransferYourPointsToggleTransactionsItemDescription = styled.p`
    color: var(--black);
    font-size: 0.875rem;
    line-height: 0.875rem;
    max-width: 180px;
    font-family: var(--font-bold);
`

export const TransferYourPointsToggleTransactionsItemAmount = styled.p`
    font-family: var(--font-bold);
    color: var(--black);
    font-size: 1.125rem;
    line-height: 1.438rem;
    max-width: 120px;
    color: var(--primary);
`

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`
