import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import whiteArrow from '../../assets/white-arrow-icon.png';
import BalanceModalContent from '../../components/BalanceModalContent';
import DesktopBanner from '../../components/DesktopBanner';
import FooterComplete from '../../components/FooterComplete';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import InputFloatingLabel from '../../components/InputFloatingLabel';
import Loader from '../../components/Loader';
import SelectFloatingLabel, { selectOptionsProps } from '../../components/SelectFloatingLabel';
import TransferSuccessModalContent from '../../components/TransferSuccessModalContent';
import { useAuth } from '../../contexts/AuthContext';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { useSpecies } from '../../contexts/SpeciesContext';
import api from '../../services/api';
import { maskNumber, maskPoints } from '../../utils/mask';
import transferCoins from './assets/transfer-coins-image.png';
import * as S from './style';

interface TransactionAtributes {
    transactionDate: string
    transactionDescription: string
    transactionAmount: number
}

const transactions: TransactionAtributes[] = [
    {
        transactionDate: '10/03/2024',
        transactionDescription: 'Traferência para Benefícios',
        transactionAmount: 500
    },
    {
        transactionDate: '09/03/2024',
        transactionDescription: 'Traferência para Benefícios',
        transactionAmount: 1500
    },
    {
        transactionDate: '05/03/2024',
        transactionDescription: 'Traferência para Benefícios',
        transactionAmount: 300
    }
]

enum TransactionSate {
    LOADING,
    READY,
    SUCCESS,
    SENDING
}

function TransferYourPoints () {
    const [isTransferSelected, setIsTransferSelected] = useState<boolean>(true)
    const [pointsToTransfer, setPointsToTransfer] = useState<number>(0)
    const [isTransferWarningShown, setIsTransferWarningShown] = useState<boolean>(false)
    const [canTransfer, setCanTransfer] = useState<boolean>(false)
    const [hasLeiteLojaBalance, setHasLeiteLojaBalance] = useState<boolean>(false)
    const [transferStatus, setTransferStatus] = useState(TransactionSate.LOADING)
    const [specieIdToTransfer, setSpecieIdToTransfer] = useState<number>(0)
    const [selectedLeiteCatalogToTransfer, setSelectedLeiteCatalogToTransfer] = useState<number>(81543)
    const [balance, setBalance] = useState<any>()
    const { showBottomModal } = useBottomModal()
    const { specieSelected, isCatalogLeiteSelected } = useSpecies()
    const { userCan } = useAuth()

    const benefitsValue = useMemo(() => {
        if (!balance) return 0
        if (!balance.categories[81544]) return 0
        return balance.categories[81544]
    }, [balance])

    const categoryLeiteLojaValue = useMemo(() => {
        if (!balance) return 0
        if (!specieSelected) return 0
        if (!balance.categories[81554]) return 0
        return balance.categories[81554]
    }, [balance, specieSelected])

    const categoryValue = useMemo(() => {
        if (!balance) return 0
        if (!specieSelected) return 0
        if (!balance.categories[specieSelected.catalog]) return 0
        return balance.categories[specieSelected.catalog]
    }, [balance, specieSelected])

    const totalValue = useMemo(() => {
        return benefitsValue + categoryValue + categoryLeiteLojaValue
    }, [benefitsValue, categoryLeiteLojaValue, categoryValue])

    const remainingPoints = useMemo(() => {
        if (!balance) return 0
        return balance.categories[81544] - pointsToTransfer
    }, [balance, pointsToTransfer])

    const maxTransfer = useMemo(() => {
        if (!balance) return 0
        if (!balance.limites) return 0
        const originsO = balance.limites[81544]
        const originsD = balance.limites[specieSelected.catalog]
        if (!originsO) return 0
        if (!originsD) return 0
        const key = Object.keys(originsD).find(k => Object.keys(originsO).includes(k))
        if (!key) return 0
        return originsO[key]
    }, [balance, specieSelected])

    const handleSwitchChange = useCallback(() => {
        setIsTransferSelected(isTransferSelected ? false : true)
    }, [isTransferSelected])

    const handleChangePoints = useCallback((value: string) => {
        const points = Number.parseFloat(value.replace(/\D/g, '')) / 100

        setTransferStatus(TransactionSate.READY)
        setIsTransferWarningShown(true)
        if (!value || points === 0) {
            setIsTransferWarningShown(false)
            setCanTransfer(false)
            return
        }
        if (points > maxTransfer) {
            toast.error('O valor não pode ser maior que o saldo.', {
                position: "bottom-right",
                theme: 'colored'
            });
            setIsTransferWarningShown(false)
            setCanTransfer(false)
            return
        }

        setCanTransfer(true)
        setPointsToTransfer(points)
    }, [maxTransfer])

    const handleChangeLeiteCatalogToTransfer = useCallback((leiteCatalog: string) => {
        setSelectedLeiteCatalogToTransfer(Number.parseInt(leiteCatalog))
    }, [])

    const handleTransfer = useCallback(async () => {
        try {
            setCanTransfer(false)
            await api.post(`/transfer`, {
                "points": pointsToTransfer,
                "toId": specieIdToTransfer
            })
            const response = await api.get('/balance')
            setBalance(response.data)
            setIsTransferWarningShown(false)
            setCanTransfer(false)
            setTransferStatus(TransactionSate.SUCCESS)
            showBottomModal(<TransferSuccessModalContent pointsTransfered={pointsToTransfer} />)
        } catch (error) {
            console.error(error)
        }
    }, [pointsToTransfer, showBottomModal, specieIdToTransfer])

    const handleShowModal = useCallback((component: ReactElement, event: any) => {
        event.stopPropagation()
        showBottomModal(component)
      }, [showBottomModal])

    const floatingLabelOptions = useMemo((): selectOptionsProps[] => {
        if (!specieSelected) return []
        if (hasLeiteLojaBalance) return [{ optionTitle: 'Fábrica', optionValue: '81543'}, { optionTitle: 'Loja', optionValue: '81554'}]
        return [{ optionTitle: 'Fábrica', optionValue: '81543'}]
    }, [hasLeiteLojaBalance, specieSelected])

    useEffect(() => {
        (async () => {
            try {
                const response = await api.get('/balance')
                setBalance(response.data)
                setTransferStatus(TransactionSate.READY)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [])

    useEffect(() => {
        if (!specieSelected) {
            setSpecieIdToTransfer(0)
            return
        }
        if (isCatalogLeiteSelected) {
            setSpecieIdToTransfer(selectedLeiteCatalogToTransfer)
            return
        }
        setSpecieIdToTransfer(specieSelected.catalog)
    }, [isCatalogLeiteSelected, selectedLeiteCatalogToTransfer, specieSelected])

    useEffect(() => {
        if (!specieSelected) return
        if (specieSelected.catalog === 81543 && specieSelected.childrens.length) setHasLeiteLojaBalance(true)
    }, [specieSelected])

    if (transferStatus === TransactionSate.LOADING) return <Loader />
    if (!specieSelected) return <Loader />

    return <S.TransferYourPointsContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Transfira seus pontos' borderBottom showDesktopWidgets />
        <DesktopBanner title='Transfira seus pontos' />
        <S.TransferYourPoints>
            <h2>Transfira seus pontos de Benefícios para sua Conta de Reinvestimentos.</h2>
            <S.TransferYourPointsBlocks>
                <S.TransferYourPointsTopSection>
                    <h2>Seu saldo:</h2>
                    <S.TransferYourPointsOverview>
                        <S.TransferYourPointsOverviewImage src={transferCoins} alt="Imagem de algumas moedas empilhadas" />
                        <S.TransferYourPointsOverviewInfos>
                            <S.TransferYourPointsOverviewTotal>
                                <div>
                                    <p>Você tem no total</p>
                                    <span>{maskPoints(totalValue)} pts</span>
                                </div>
                            </S.TransferYourPointsOverviewTotal>
                            <S.TransferYourPointsOverviewOthers>
                                <S.TransferYourPointsOverviewOthersItem>
                                    <div>
                                        <p>Conta Benefícios</p>
                                        <span>{maskPoints(benefitsValue)} pts</span>
                                    </div>
                                    <img src={whiteArrow} alt="Ícone de seta para a direita, indicando um link que pode ser clicado" />
                                </S.TransferYourPointsOverviewOthersItem>
                                {isCatalogLeiteSelected ? <>
                                    <S.TransferYourPointsOverviewOthersItem>
                                        <div>
                                            <p>Conta Reinvestimento Leite Fábrica</p>
                                            <span>{maskPoints(categoryValue)} pts</span>
                                        </div>
                                    </S.TransferYourPointsOverviewOthersItem>
                                    {hasLeiteLojaBalance && <S.TransferYourPointsOverviewOthersItem>
                                        <div>
                                            <p>Conta Reinvestimento Leite Loja</p>
                                            <span>{maskPoints(categoryLeiteLojaValue)} pts</span>
                                        </div>
                                    </S.TransferYourPointsOverviewOthersItem>}
                                </> : 
                                    <S.TransferYourPointsOverviewOthersItem>
                                        <div>
                                            <p>Conta Reinvestimento</p>
                                            <span>{maskPoints(categoryValue)} pts</span>
                                        </div>
                                    </S.TransferYourPointsOverviewOthersItem>
                                }
                            </S.TransferYourPointsOverviewOthers>
                            <S.AvisoLimite>Você pode transferir até <span>{maxTransfer}</span> pts para a(s) Conta(s) Reinvestimento de <span>{specieSelected.name}</span>.</S.AvisoLimite>
                        </S.TransferYourPointsOverviewInfos>
                    </S.TransferYourPointsOverview>
                </S.TransferYourPointsTopSection>
                <S.TransferYourPointsBottomSection>
                    <S.TransferYourPointsToggleContent>
                        {userCan('read:transfer-history') && <S.TransferYourPointsToggleContentSwitch>
                            <S.TransferYourPointsToggleContentSwitchButton onClick={handleSwitchChange} $isSelected={isTransferSelected}>
                                Transferir
                            </S.TransferYourPointsToggleContentSwitchButton>
                            <S.TransferYourPointsToggleContentSwitchButton onClick={handleSwitchChange} $isSelected={!isTransferSelected}>
                                Histórico
                            </S.TransferYourPointsToggleContentSwitchButton>
                        </S.TransferYourPointsToggleContentSwitch>}
                        <S.TransferYourPointsToggleContentBlock $isShown={isTransferSelected}>
                            <p>Quantos pontos você quer transferir para essa Conta Reinvestimento?</p>
                            {isCatalogLeiteSelected && <S.SelectFloatingLabelContainer>
                                <SelectFloatingLabel selectTitle='Selecione a Conta Reinvestimento' selectOptions={floatingLabelOptions} onValueChange={(leiteCatalog: string) => handleChangeLeiteCatalogToTransfer(leiteCatalog)} />
                            </S.SelectFloatingLabelContainer>}
                            <S.TransferYourPointsToggleContentBlockInput>
                                <InputFloatingLabel
                                    inputTitle='Pontos'
                                    inputType='text'
                                    onValueChange={handleChangePoints}
                                    mask={maskNumber}
                                />
                                <button className='green-button' onClick={handleTransfer} disabled={!canTransfer}>Transferir</button>
                            </S.TransferYourPointsToggleContentBlockInput>
                            {isTransferWarningShown && <S.TransferYourPointsWarning>
                                <p>
                                    O saldo da sua Conta Benefícios será <span>{maskPoints(remainingPoints)} pts</span> após essa transferência.<br /><br />
                                    <strong>ATENÇÃO:</strong> não será possível retornar os pontos depois de transferidos.
                                </p>
                            </S.TransferYourPointsWarning>}
                            <S.TransferYourPointsToggleContentBlockButtons>
                                <button onClick={(event) => handleShowModal(<BalanceModalContent />, event)}>Entenda seu saldo</button>
                            </S.TransferYourPointsToggleContentBlockButtons>
                        </S.TransferYourPointsToggleContentBlock>
                        <S.TransferYourPointsToggleContentBlock $isShown={!isTransferSelected}>
                            <p>Últimas movimentações</p>
                            <S.TransferYourPointsToggleTransactions>
                                {transactions.map((transaction, index) => <S.TransferYourPointsToggleTransactionsItem key={index}>
                                    <div>
                                        <S.TransferYourPointsToggleTransactionsItemDate>
                                            {'Em ' + transaction.transactionDate}
                                        </S.TransferYourPointsToggleTransactionsItemDate>
                                        <S.TransferYourPointsToggleTransactionsItemDescription>
                                            {transaction.transactionDescription}
                                        </S.TransferYourPointsToggleTransactionsItemDescription>
                                    </div>
                                    <S.TransferYourPointsToggleTransactionsItemAmount>
                                        {transaction.transactionAmount + 'pts'}
                                    </S.TransferYourPointsToggleTransactionsItemAmount>
                                </S.TransferYourPointsToggleTransactionsItem>
                                )}
                            </S.TransferYourPointsToggleTransactions>
                            <S.TransferYourPointsToggleContentBlockButtons>
                                <button>Mostrar mais</button>
                            </S.TransferYourPointsToggleContentBlockButtons>
                        </S.TransferYourPointsToggleContentBlock>
                    </S.TransferYourPointsToggleContent>
                </S.TransferYourPointsBottomSection>
            </S.TransferYourPointsBlocks>
        </S.TransferYourPoints>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
    </S.TransferYourPointsContent>
}

export default TransferYourPoints