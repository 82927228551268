import { ReactElement, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import greenArrow from '../../assets/green-arrow-icon.png';
import BalanceModalContent from '../../components/BalanceModalContent';
import DesktopBanner from '../../components/DesktopBanner';
import FooterComplete from '../../components/FooterComplete';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import Toolbar from '../../components/Toolbar';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { useSpecies } from '../../contexts/SpeciesContext';
import api from '../../services/api';
import * as S from './style';

function GoldBox () {
    const { showBottomModal } = useBottomModal()
    const { specieSelected, userHasGoldBox } = useSpecies()
    const token = localStorage.getItem('token')
    const navigate = useNavigate()

    const handleRedirect = useCallback(async () => {
        if (!specieSelected) return 
        const response = await api.get(`/cargill-gold-box/${specieSelected.catalog}`)
        window.location.href = response.data.url
    }, [specieSelected])

    const handleRedirectBeneficios = useCallback(async () => {
        const response = await api.get(`/cargill-gold-box/81544`)
        window.location.href = response.data.url
    }, [])

    const handleShowModal = useCallback((component: ReactElement, event: any) => {
        event.stopPropagation()
        showBottomModal(component)
    }, [showBottomModal])

    useEffect(() => {
        if (!token) {
            navigate('/login')
            return
        }
        if (!userHasGoldBox) {
            navigate('/inicio')
        }
    }, [navigate, token, userHasGoldBox])

    return <S.GoldBoxContent>
        <Header element={<MenuButton />} borderBottom showDesktopWidgets />
        <DesktopBanner title='Gold Box' />
        <S.GoldBox>
            <h2>Em qual conta você deseja resgatar?</h2>
            <S.GoldBoxOptions>
                <S.GoldBoxOptionsOption onClick={handleRedirect}>
                    <p>Conta Reinvestimento</p>
                    <img src={greenArrow} alt="ícone de seta apontando para direita (indicando opção Conta Reinvestimento)" />
                </S.GoldBoxOptionsOption>
                <S.GoldBoxOptionsOption onClick={handleRedirectBeneficios}>
                    <p>Conta Benefício</p>
                    <img src={greenArrow} alt="ícone de seta apontando para direita (indicando opção Conta Benefício)" />
                </S.GoldBoxOptionsOption>
            </S.GoldBoxOptions>
            <S.GoldBoxContentButton onClick={(event) => handleShowModal(<BalanceModalContent />, event)}>
                Entenda a diferença
            </S.GoldBoxContentButton>
        </S.GoldBox>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
        <Toolbar />
    </S.GoldBoxContent>
}

export default GoldBox