import styled from 'styled-components';

export const Welcome = styled.section`
    width: 100%;
    background-color: var(--black);
    color: var(--black);
    max-width: 900px;
    margin: 0 auto;
    margin-top: 70px;
    animation: fadeIn 0.4s ease-in-out;
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        img { 
            max-width: 100%;
            padding: 0;
            width: 100%;
            margin: 0 auto 23px auto;
            max-height: 450px;
        }

        h2 {
            margin: 0;
            color: var(--green-neon);
            font-size: 1.5rem;
            width: 100%;
            font-family: 'Gilroy-Bold', sans-serif;
            margin-bottom: 24px;
            text-align: center;
            padding: 0 36px;
        }

        p {
            width: 100%;
            font-size: 1rem;
            color: var(--white);
            margin-bottom: 62px;
            text-align: center;
            padding: 0 36px;
            max-width: 95%;
        }

        button {
            background-color: var(--primary);
            width: 81.5%;
            max-width: 310px;
            padding: 17px;
            font-size: 1.125rem;
            font-family: 'Gilroy-Bold', sans-serif;
            text-align: center;
            color: var(--white);
            border-radius: 28px;
            margin: 0 auto;
        }
    }
`;