import { FetchPosts, PostCommentsProps } from "../../domain/use-case/FetchPosts";
import api from "../../services/api";

export const parseComment = (comment: any): PostCommentsProps => ({
  content: comment.content,
  owner: {
    name: comment.Owner.name
  }
})

export const parsePost = (post: any): FetchPosts.Result => ({
  id: post.id,
  postStatus: post.postStatus,
  content: post.content,
  image: post.image,
  videoUrl: post.videoUrl,
  createdAt: new Date(post.createdAt),
  likes: post._count.Likes,
  owner: post.Owner,
  comments: post.Comments.map(parseComment)
})

export class RemoteFetchPosts implements FetchPosts {
  async handle (params: FetchPosts.Params): Promise<FetchPosts.Result[]> {
    const response = await api.get(`/posts`, { params })
    return response.data.map(parsePost)
  }
}