import { Link } from "react-router-dom";
import styled from 'styled-components';

export const UserPointsBlockLink = styled(Link) <{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? `flex` : `none`)};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.37);
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.625rem;
    z-index: 12;
    text-decoration: none;
    font-size: 0.75rem;

    @media only screen and (min-width: 960px) {
        display: flex;
        border-top: none;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 13px 12px;
        border-radius: 10px;
        margin-top: 0px;
        font-size: 0.625rem;
    }

    >div {
        display: flex;
        flex-direction: column;
        .block-number {
            color: var(--green-neon);
            font-size: 1.125rem;
        }
    }
    img {
        width: 7px;
    }
`