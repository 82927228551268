import arrowLeft from '../assets/arrow-left.png';
import * as S from './style';

interface BackButtonProps {
    onClick?: React.MouseEventHandler
}

function BackButton({ onClick }:BackButtonProps) {
    return <S.BackButton onClick={onClick}>
        <img src={arrowLeft} alt="ícone de seta apontando para esquerda (botão de voltar)" />
    </S.BackButton>
}

export default BackButton