import { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from './contexts/AuthContext';
import BottomModalProvider from './contexts/BottomModalContext';
import SideMenuProvider from './contexts/SideMenuContext';
import { SpeciesProvider } from './contexts/SpeciesContext';
import Router from './route';
import GlobalStyle from './styles';

function App (): ReactElement {
  return <>
    <BrowserRouter>
      <AuthProvider>
        <SpeciesProvider>
          <BottomModalProvider>
            <SideMenuProvider>
              <Router />
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
            </SideMenuProvider>
          </BottomModalProvider>
        </SpeciesProvider>
      </AuthProvider>
    </BrowserRouter>
  </>
}

export default App;
