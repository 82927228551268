import styled, { keyframes } from 'styled-components';

export const skeletonLoading = keyframes`
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
`;

export const Skeleton = styled.div`
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;

export const HomeItemsSwipperSkeleton = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto; 
    overflow-y: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HomeItemsSwipperSkeletonItemsContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 36px;
    gap: 10px;
    
    @media only screen and (min-width: 960px) {
        gap: 16px;
    }
`;

export const HomeItemsSwipperSkeletonItem = styled.button`
    width: 154px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 20px;
    padding-bottom: 12px;
    border-radius: 20px;
    box-shadow: 0px 3px 16px #001D4129;
    background-color: var(--white);
    text-decoration: none;
    
    @media only screen and (min-width: 960px) {
        width: 202px;
        padding: 24px 12px 20px 12px;
    }
`;

export const HomeItemsSwipperSkeletonItemImage = styled(Skeleton)`
    border-radius: 0.5rem;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 14px;
    width: 90%;
`;

export const HomeItemsSwipperSkeletonItemTitle = styled(Skeleton)`
    border-radius: 0.25rem;
    height: 15px;
    margin-bottom: 4px;
    width: 100%;
`;

export const HomeItemsSwipperSkeletonItemPrice = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: var(--font-bold);
    margin-top: auto;
    p {
        color: #2D473E;
        font-size: 0.875rem;
        line-height: 1rem;
        margin-bottom: 4px;
    }
    span {
        color: var(--primary);
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 0 auto;

        sup {
            font-size: 0.875rem;
        }
    }
`;

export const HomeItemsSwipperSkeletonItemPriceText = styled(HomeItemsSwipperSkeletonItemTitle)`
    height: 19px;
`;