import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import checkIcon from '../../assets/icons/check-icon.png';
import notCheckIcon from '../../assets/icons/not-check-icon.png';
import { Footer } from '../../components/Footer';
import Header from '../../components/Header';
import BackButton from '../../components/Header/BackButton';
import InputFloatingLabel from '../../components/InputFloatingLabel';
import LoaderWithBackdrop from '../../components/LoaderWithBackdrop';
import RegulamentoModalContent from '../../components/RegulamentoContent';
import companySchemaSimple from '../../config/companyForm2Scheme.json';
import companySchema from '../../config/companyFormScheme.json';
import userSchemaSimple from '../../config/userForm2Scheme.json';
import userSchema from '../../config/userFormScheme.json';
import { useAuth } from '../../contexts/AuthContext';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { FormValidator } from '../../data/use-case/FormValidator';
import { RepublicaFetchAddress } from '../../data/use-case/RepublicaFetchAddress';
import api from '../../services/api';
import { MaskCEP, MaskCPF, MaskDate, MaskPhone, MaskUF } from '../../utils/mask';
import * as S from './style';

enum ButtonState {
    READY,
    INVALID,
    SENDING
}

interface SignUpAttributes {
    newPassword: string
    confirmPassword: string
    currentPassword: string
    cpfRespLegal: string
    respLegal: string
    birthday: string
    cellphone: string
    name: string
    document: string
    razaoSocial: string
    companyCreationDate: string
    phone: string
    email: string
    cep: string
    estado: string
    cidade: string
    bairro: string
    logradouro: string
    numero: string
    complemento: string
    documentInputDisabled: boolean
    bullets: {
        length: boolean
        hasUpperCase: boolean
        hasLowerCase: boolean
        hasNumber: boolean
        compare: boolean
    }
    passwordButton: {
        currentState: ButtonState
    }
    personButton: {
        currentState: ButtonState
    }
    companyButton: {
        currentState: ButtonState
    }
    codeButton: {
        currentState: ButtonState
    }
    isRegulationAccepted: boolean
    userErrors: any
    companyErrors: any
}

const fetchAddress = new RepublicaFetchAddress()
const userFormValidator = new FormValidator(userSchema)
const userFormSimpleValidator = new FormValidator(userSchemaSimple)
const companyFormValidator = new FormValidator(companySchema)
const companyFormSimpleValidator = new FormValidator(companySchemaSimple)


function Signup () {
    const { user, userCan } = useAuth()
    const [state, setState] = useState<SignUpAttributes>({
        documentInputDisabled: true,
        bullets: {
            length: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            compare: false
        },
        passwordButton: {
            currentState: ButtonState.INVALID
        },
        personButton: {
            currentState: ButtonState.INVALID
        },
        companyButton: {
            currentState: ButtonState.INVALID
        },
        codeButton: {
            currentState: ButtonState.INVALID
        },
        isRegulationAccepted: false,
        companyCreationDate: new Date(user.companyCreationDate).toLocaleDateString('pt-br'),
        phone: user.phone,
        respLegal: user.respLegal,
        cpfRespLegal: user.cpfRespLegal,
        birthday: user.birthday,
        cellphone: user.cellphone,
        email: user.email,
        userErrors: {},
        companyErrors: {}

    } as SignUpAttributes)
    const [activeView, setActiveView] = useState<number>(1)
    const [countdown, setCountdown] = useState<number>(55)
    const [digits, setDigits] = useState<string[]>(['', '', '', '', '', ''])
    const [isResendButtonDisabled, setIsResendButtonDisabled] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])
    const navigate = useNavigate()
    const { showBottomModal } = useBottomModal()

    const handleUpdatePassword = useCallback(async () => {
        await api.patch('/me/password', {
            password: state?.currentPassword,
            newPassword: state?.newPassword,
            acceptInfo: new Date()
        })
    }, [state])
    const handleUpdateInfo = useCallback(async () => {
        await api.put(`/user/${String(user?.id)}`, {
            ...user,
            cpfRespLegal: state.cpfRespLegal,
            document: state.document,
            respLegal: state.respLegal,
            birthday: state.birthday,
            cellphone: state.cellphone,
            email: state.email
        })
    }, [user, state])
    const handleUpdateCompanyInfo = useCallback(async () => {
        try {
            await api.post(`/user/company`, {
                userId: user?.id,
                document: state.document,
                razaoSocial: state.razaoSocial,
                companyCreationDate: state.companyCreationDate,
                phone: state.phone,
                cep: state.cep,
                estado: state.estado,
                cidade: state.cidade,
                bairro: state.bairro,
                logradouro: state.logradouro,
                numero: state.numero,
                complemento: state.complemento
            })
        } catch (error) {
            toast.error('Ocorreu um erro inesperado.', {
                position: "bottom-right",
                theme: 'colored'
            })
        }
    }, [state, user])

    const handleValidateCode = useCallback(async () => {
        await api.post('/activate/code/validate', {
            code: digits.join(''),
            token: user.activationToken
        })
        await api.post(`/participant`, { userId: user.id })
        await api.post(`/activate/${user.activationToken}`)
    }, [digits, user.activationToken, user.id])

    const commands = useMemo(() => [
        handleUpdatePassword,
        handleUpdateInfo,
        handleUpdateCompanyInfo,
        handleValidateCode
    ], [handleUpdateCompanyInfo, handleUpdateInfo, handleUpdatePassword, handleValidateCode])

    const handleSendCode = useCallback(async () => {
        setCountdown(60);
        setIsResendButtonDisabled(true);
        await api.post(`/send-code`, { userId: user.id })
    }, [user.id])

    const validatePassword = useCallback((newState: SignUpAttributes) => {
        if (!newState.newPassword) {
            newState.bullets.length = false
            newState.bullets.hasUpperCase = false
            newState.bullets.hasLowerCase = false
            newState.bullets.hasNumber = false
            newState.bullets.compare = false
            setState(newState)
            return
        }
        newState.passwordButton.currentState = ButtonState.READY
        newState.bullets.length = newState.newPassword.length >= 6 && newState.newPassword.length <= 12
        newState.bullets.hasUpperCase = newState.newPassword.match(/[A-Z]/) !== null
        newState.bullets.hasLowerCase = newState.newPassword.match(/[a-z]/) !== null
        newState.bullets.hasNumber = newState.newPassword.match(/[0-9]/) !== null
        newState.bullets.compare = newState.newPassword === newState.confirmPassword

        if (!newState.bullets.length ||
            !newState.bullets.hasUpperCase ||
            !newState.bullets.hasLowerCase ||
            !newState.bullets.hasNumber ||
            !newState.isRegulationAccepted ||
            !newState.bullets.compare ||
            !newState.currentPassword) {
            newState.passwordButton.currentState = ButtonState.INVALID
        }
        setState(newState)
    }, [])

    const handleNext = useCallback(async () => {
        try {
            await commands[activeView - 1]()
            if (activeView === 4) return navigate('/bem-vindo')
            if (activeView === 3) {
                handleSendCode()
            }
            setActiveView((prevView) => (prevView + 1))
        } catch (err) {
            console.error(err)
            toast.error('Ocorreu um erro inesperado.', {
                position: "bottom-right",
                theme: 'colored'
            })
        }
    }, [activeView, commands, handleSendCode, navigate])

    const handleBack = useCallback(() => {
        if (activeView === 1) return navigate('/login')
        setActiveView((prevView) => (prevView - 1))
    }, [activeView, navigate])

    const handleDigitChange = useCallback((index: number, value: string) => {
        const newDigits = [...digits]
        newDigits[index] = value

        setDigits(newDigits)
        if (value.length === 1 && index < 5 && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();
        }
        setState((state) => ({ ...state, codeButton: { currentState: !newDigits.includes('') ? ButtonState.READY : ButtonState.INVALID } }))
    }, [digits])

    const handleUpdateCurrentPassword = useCallback((currentPassword: string) => {
        if (!currentPassword) {
            toast.error('Por favor, digite a senha atual.', {
                position: "bottom-right",
                theme: 'colored'
            })
        }
        const newState = { ...state }
        newState.currentPassword = currentPassword
        validatePassword(newState)
    }, [state, validatePassword])

    const handleUpdateNewPassword = useCallback((newPassword: string) => {
        const newState = { ...state }
        newState.newPassword = newPassword
        validatePassword(newState)
    }, [state, validatePassword])

    const handleUpdateConfirmPassword = useCallback((confirmPassword: string) => {
        const newState = { ...state }
        newState.confirmPassword = confirmPassword
        validatePassword(newState)
    }, [state, validatePassword])

    const handleAcceptRegulation = useCallback(() => {
        const newState = { ...state }
        newState.isRegulationAccepted = !state.isRegulationAccepted
        validatePassword(newState)
    }, [state, validatePassword])

    const handleChangePerson = useCallback((newState: SignUpAttributes) => {
        if (userCan('update:company')) {
            newState.personButton.currentState = userFormValidator.validate(newState) ? ButtonState.READY : ButtonState.INVALID
            newState.userErrors = userFormValidator.errors
        } else {
            newState.personButton.currentState = userFormSimpleValidator.validate(newState) ? ButtonState.READY : ButtonState.INVALID
            newState.userErrors = userFormSimpleValidator.errors
        }
        setState(newState)
        return
    }, [userCan])

    const handleChangeCompany = useCallback((newState: SignUpAttributes) => {
        if (userCan('update:company')) {
            newState.companyButton.currentState = companyFormValidator.validate(newState) ? ButtonState.READY : ButtonState.INVALID
            newState.companyErrors = companyFormValidator.errors
        } else {
            newState.companyButton.currentState = companyFormSimpleValidator.validate(newState) ? ButtonState.READY : ButtonState.INVALID
            newState.companyErrors = companyFormSimpleValidator.errors
        }
        setState(newState)
    }, [userCan])

    const handleChangeCEP = useCallback(async (newCep: string) => {
        const newState = { ...state }
        newState.cep = newCep

        if (newCep.length < 9) {
            newState.companyButton.currentState = ButtonState.INVALID
            setState(newState)
            return
        }
        if (newCep.length === 9) {
            setLoading(true)
            try {
                const response = await fetchAddress.handle(newCep)
                if (!response.uf || !response.cidade) {
                    newState.companyButton.currentState = ButtonState.INVALID
                    setLoading(false)
                    return
                }
                newState.estado = response.uf
                newState.cidade = response.cidade
                newState.bairro = response.bairro
                newState.logradouro = response.logradouro
                handleChangeCompany(newState)
            } catch (error) {
                console.error(error)
            }
            setState(newState)
            setLoading(false)
        }
    }, [handleChangeCompany, state])

    const handleShowModal = useCallback((component: ReactElement, event: any) => {
        event.stopPropagation()
        showBottomModal(component)
      }, [showBottomModal])

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown > 0) {
                    return prevCountdown - 1
                } else {
                    setIsResendButtonDisabled(false)
                    return 0
                }
            })
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    useEffect(() => {
        setState((state) => ({ ...state, documentInputDisabled: !!user.document }))
    }, [user.document])

    return <S.Signup>
        <Header element={<BackButton onClick={handleBack} />} />
        <S.SignupStepper>
            <S.SignupStepperFirstView $isVisible={activeView === 1}>
                <S.SignupStepperViewTitle>Para seguir, precisamos de alguns dados.</S.SignupStepperViewTitle>
                <S.SignupStepperTrail>
                    <div>
                        <div className='signup-stepper-trail-first-dot'></div>
                        <p>Dados<br />básicos</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-second-dot'>
                            <div className='signup-stepper-trail-first-line'></div>
                        </div>
                        <p>Sobre<br />você</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-third-dot'>
                            <div className='signup-stepper-trail-second-line'></div>
                        </div>
                        {userCan('update:company') && <p>Sobre a<br />empresa</p>}
                        {!userCan('update:company') && <p>Endereço</p>}
                    </div>
                    <div>
                        <div className='signup-stepper-trail-fourth-dot'>
                            <div className='signup-stepper-trail-third-line'></div>
                        </div>
                        <p>Confirmar<br />celular</p>
                    </div>
                </S.SignupStepperTrail>
                <S.SignupStepperViewContent>
                    <InputFloatingLabel inputTitle='Digite sua senha atual' inputType='password' onValueChange={handleUpdateCurrentPassword} />
                    <InputFloatingLabel inputTitle='Crie sua senha' inputType='password' onValueChange={handleUpdateNewPassword} />
                    <InputFloatingLabel inputTitle='Confirme a senha' inputType='password' onValueChange={handleUpdateConfirmPassword} />
                    <S.SignupStepperFirstViewPasswordRules>
                        <S.SignupStepperFirstViewPasswordRulesTitle>
                            A senha deve conter:
                        </S.SignupStepperFirstViewPasswordRulesTitle>
                        <S.SignupStepperFirstViewPasswordRulesContent>
                            <S.SignupStepperFirstViewPasswordRulesContentBlock>
                                <div>
                                    <img src={state.bullets.length ? checkIcon : notCheckIcon} alt="Regra 1 para prosseguir com a senha: De 6 a 12 caracteres" />
                                    <p>De 6 a 12 caracteres</p>
                                </div>
                                <div>
                                    <img src={state.bullets.hasUpperCase ? checkIcon : notCheckIcon} alt="Regra 2 para prosseguir com a senha: Ao menos uma letra maicúscula" />
                                    <p>Ao menos uma letra maicúscula</p>
                                </div>
                                <div>
                                    <img src={state.bullets.hasLowerCase ? checkIcon : notCheckIcon} alt="Regra 3 para prosseguir com a senha: Ao menos uma letra minúscula" />
                                    <p>Ao menos uma letra minúscula</p>
                                </div>
                            </S.SignupStepperFirstViewPasswordRulesContentBlock>
                            <S.SignupStepperFirstViewPasswordRulesContentBlock>
                               <div>
                                    <img src={state.bullets.hasNumber ? checkIcon : notCheckIcon} alt="Regra 4 para prosseguir com a senha: Ao menos um número" />
                                    <p>Ao menos um número</p>
                                </div>
                                <div>
                                    <img src={state.bullets.compare ? checkIcon : notCheckIcon} alt="Regra 4 para prosseguir com a senha: A nova senha e a confirmação devem ser iguais" />
                                    <p>A nova senha e a confirmação devem ser iguais</p>
                                </div>
                            </S.SignupStepperFirstViewPasswordRulesContentBlock>
 
                        </S.SignupStepperFirstViewPasswordRulesContent>
                    </S.SignupStepperFirstViewPasswordRules>
                    <S.SignupStepperFirstViewStatute>
                        <input type="checkbox" onClick={handleAcceptRegulation} />
                        <p>Li e aceito todos os <button onClick={(event) => handleShowModal(<RegulamentoModalContent />, event)}>regulamentos.</button></p>
                    </S.SignupStepperFirstViewStatute>
                </S.SignupStepperViewContent>
            </S.SignupStepperFirstView>
            <S.SignupStepperSecondView $isVisible={activeView === 2}>
                <S.SignupStepperViewTitle>Para seguir, precisamos<br />de alguns dados.</S.SignupStepperViewTitle>
                <S.SignupStepperTrail>
                    <div>
                        <div className='signup-stepper-trail-first-dot'></div>
                        <p>Dados<br />básicos</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-second-dot'>
                            <div className='signup-stepper-trail-first-line'></div>
                        </div>
                        <p>Sobre<br />você</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-third-dot'>
                            <div className='signup-stepper-trail-second-line'></div>
                        </div>
                        {userCan('update:company') && <p>Sobre a<br />empresa</p>}
                        {!userCan('update:company') && <p>Endereço</p>}
                    </div>
                    <div>
                        <div className='signup-stepper-trail-fourth-dot'>
                            <div className='signup-stepper-trail-third-line'></div>
                        </div>
                        <p>Confirmar<br />celular</p>
                    </div>
                </S.SignupStepperTrail>
                <S.SignupStepperViewContent>
                    <div className='signup-stepper-view-inputs'>
                        {userCan('update:company') && <>
                            <InputFloatingLabel inputTitle='CPF do Responsavel legal' inputType='text' onValueChange={(cpfRespLegal) => handleChangePerson({ ...state, cpfRespLegal })} mask={MaskCPF} value={user?.cpfRespLegal} />
                            <S.SignupWarnings>{state.userErrors.cpfRespLegal}</S.SignupWarnings>
                        </>}
                        {!userCan('update:company') && <InputFloatingLabel inputTitle='CPF' inputType='text' onValueChange={(document) => handleChangePerson({ ...state, document })} mask={MaskCPF} value={user?.document} disabled={state.documentInputDisabled} />}
                        {!userCan('update:company') && <InputFloatingLabel inputTitle='Nome' inputType='text' onValueChange={(name) => handleChangePerson({ ...state, name })} value={user?.name} disabled />}
                        {userCan('update:company') && <>
                            <InputFloatingLabel inputTitle='Nome do responsável legal' inputType='text' onValueChange={(respLegal) => handleChangePerson({ ...state, respLegal })} value={user?.respLegal} />
                            <S.SignupWarnings>{state.userErrors.respLegal}</S.SignupWarnings>
                        </>}
                        <InputFloatingLabel inputTitle='Data de nascimento' inputType='text' onValueChange={(birthday) => handleChangePerson({ ...state, birthday })} mask={MaskDate} value={user?.birthday || ''} />
                        <S.SignupWarnings>{state.userErrors.birthday}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Celular' inputType='text' onValueChange={(cellphone) => handleChangePerson({ ...state, cellphone })} mask={MaskPhone} value={user?.cellphone || ''} />
                        <S.SignupWarnings>{state.userErrors.cellphone}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='E-mail' inputType='email' onValueChange={(email) => handleChangePerson({ ...state, email })} value={user?.email || ''} />
                        <S.SignupWarnings>{state.userErrors.email}</S.SignupWarnings>
                    </div>
                </S.SignupStepperViewContent>
            </S.SignupStepperSecondView>
            <S.SignupStepperThirdView $isVisible={activeView === 3}>
                <S.SignupStepperViewTitle>Para seguir, precisamos<br />de alguns dados.</S.SignupStepperViewTitle>
                <S.SignupStepperTrail>
                    <div>
                        <div className='signup-stepper-trail-first-dot'></div>
                        <p>Dados<br />básicos</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-second-dot'>
                            <div className='signup-stepper-trail-first-line'></div>
                        </div>
                        <p>Sobre<br />você</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-third-dot'>
                            <div className='signup-stepper-trail-second-line'></div>
                        </div>
                        {userCan('update:company') && <p>Sobre a<br />empresa</p>}
                        {!userCan('update:company') && <p>Endereço</p>}
                    </div>
                    <div>
                        <div className='signup-stepper-trail-fourth-dot'>
                            <div className='signup-stepper-trail-third-line'></div>
                        </div>
                        <p>Confirmar<br />celular</p>
                    </div>
                </S.SignupStepperTrail>
                <S.SignupStepperViewContent>
                    <div className='signup-stepper-view-inputs'>
                        {userCan('update:company') && <InputFloatingLabel inputTitle='CNPJ' inputType='text' value={user.document} disabled />}
                        {userCan('update:company') && <InputFloatingLabel inputTitle='Razão social' inputType='text' value={user.razaoSocial} disabled />}
                        {userCan('update:company') && <>
                            <InputFloatingLabel inputTitle='Data de abertura' inputType='text' onValueChange={(companyCreationDate) => handleChangeCompany({ ...state, companyCreationDate })} mask={MaskDate} value={new Date(user.companyCreationDate).toLocaleDateString('pt-br')} />
                            <S.SignupWarnings>{state.companyErrors.companyCreationDate}</S.SignupWarnings>
                        </>}
                        {userCan('update:company') && <>
                            <InputFloatingLabel inputTitle='Telefone' inputType='text' onValueChange={(phone) => handleChangeCompany({ ...state, phone })} mask={MaskPhone} value={user.phone} />
                            <S.SignupWarnings>{state.companyErrors.phone}</S.SignupWarnings>
                        </>}
                        <InputFloatingLabel inputTitle='CEP' inputType='text' onValueChange={handleChangeCEP} mask={MaskCEP} />
                        <S.SignupWarnings>{state.companyErrors.cep}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Estado' inputType='text' onValueChange={(estado) => handleChangeCompany({ ...state, estado })} mask={MaskUF} value={state.estado} />
                        <S.SignupWarnings>{state.companyErrors.estado}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Cidade' inputType='text' onValueChange={(cidade) => handleChangeCompany({ ...state, cidade })} value={state.cidade} />
                        <S.SignupWarnings>{state.companyErrors.cidade}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Bairro' inputType='text' maxLength={20} onValueChange={(bairro) => handleChangeCompany({ ...state, bairro })} value={state.bairro} />
                        <S.SignupWarnings>{state.companyErrors.bairro}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Logradouro' inputType='text' onValueChange={(logradouro) => handleChangeCompany({ ...state, logradouro })} value={state.logradouro} />
                        <S.SignupWarnings>{state.companyErrors.logradouro}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Numero' inputType='number' onValueChange={(numero) => handleChangeCompany({ ...state, numero })} />
                        <S.SignupWarnings>{state.companyErrors.numero}</S.SignupWarnings>
                        <InputFloatingLabel inputTitle='Complemento' inputType='text' maxLength={20} onValueChange={(complemento) => handleChangeCompany({ ...state, complemento })} />
                    </div>
                </S.SignupStepperViewContent>
            </S.SignupStepperThirdView>
            <S.SignupStepperFourthView $isVisible={activeView === 4}>
                <S.SignupStepperViewTitle>Vamos confirmar o seu número?</S.SignupStepperViewTitle>
                <S.SignupStepperTrail>
                    <div>
                        <div className='signup-stepper-trail-first-dot'></div>
                        <p>Dados<br />básicos</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-second-dot'>
                            <div className='signup-stepper-trail-first-line'></div>
                        </div>
                        <p>Sobre<br />você</p>
                    </div>
                    <div>
                        <div className='signup-stepper-trail-third-dot'>
                            <div className='signup-stepper-trail-second-line'></div>
                        </div>
                        {userCan('update:company') && <p>Sobre a<br />empresa</p>}
                        {!userCan('update:company') && <p>Endereço</p>}
                    </div>
                    <div>
                        <div className='signup-stepper-trail-fourth-dot'>
                            <div className='signup-stepper-trail-third-line'></div>
                        </div>
                        <p>Confirmar<br />celular</p>
                    </div>
                </S.SignupStepperTrail>
                <S.SignupStepperViewContent>
                    <S.SignupStepperConfirmationDigits>
                        {digits.map((digit, index) => (
                            <S.SignupStepperConfirmationDigit
                                key={index}
                                ref={(el) => inputRefs.current[index] = el}
                                value={digit}
                                maxLength={1}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleDigitChange(index, e.target.value)}
                            />
                        ))}
                    </S.SignupStepperConfirmationDigits>
                    <p className='confirmation-message'>Informe o código de <strong>6 dígitos</strong> enviado para o seu celular.</p>
                    <p className='confirmation-message'><strong>Não recebeu o código?</strong> Reenvie ou verifique novamente em instantes.</p>
                    <S.SignupStepperResendCodeButton disabled={isResendButtonDisabled} onClick={handleSendCode}>
                        Reenviar código {countdown === 0 ? '' : '(' + countdown + ')'}
                    </S.SignupStepperResendCodeButton>
                </S.SignupStepperViewContent>
            </S.SignupStepperFourthView>
            {activeView === 1 && <S.SignupStepperViewContinueButton
                onClick={handleNext}
                disabled={state.passwordButton.currentState !== ButtonState.READY} >
                Continuar
            </S.SignupStepperViewContinueButton>}
            {activeView === 2 && <S.SignupStepperViewContinueButton
                onClick={handleNext}
                disabled={state.personButton.currentState !== ButtonState.READY}>
                Continuar
            </S.SignupStepperViewContinueButton>}
            {activeView === 3 && <S.SignupStepperViewContinueButton
                onClick={handleNext}
                disabled={state.companyButton.currentState !== ButtonState.READY} >
                Continuar
            </S.SignupStepperViewContinueButton>}
            {activeView === 4 && <S.SignupStepperViewContinueButton
                onClick={handleNext}
                disabled={state.codeButton.currentState !== ButtonState.READY} >
                Confirmar número
            </S.SignupStepperViewContinueButton>}
        </S.SignupStepper>
        {loading && <LoaderWithBackdrop />}
        <Footer />
    </S.Signup>
    
}

export default Signup