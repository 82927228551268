import styled from 'styled-components';

export const RegulamentoContent = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    h2 {
        color: var(--black);
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-family: var(--font-bold);
        margin-bottom: 5px;
    }
    >h2 {
        margin-bottom: 21px;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 5px;
    }
    ol, ul {
        margin-left: 14px;
    }
`;

export const RegulamentoContentTitle = styled.h2`
    color: var(--black);
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-family: var(--font-bold);
    margin-bottom: 21px;

    @media only screen and (min-width: 960px) {
        display: none;
    }
`;

export const RegulamentoContentText = styled.div`
    width: 100%;
    color: var(--black);
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 46px;
`;

export const RegulamentoContentButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media only screen and (min-width: 960px) {
        display: none;
    }

    button {
        width: 100%;
        font-size: 1.125rem;
        padding: 17px;
        font-family: var(--font-bold);
        border-radius: 28px;
    }
    .button-outlined {
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
`;