import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const HowItWorksContent = styled.section`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: var(--black);
`;

export const HowItWorks = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    padding-top: 36px;
    transition: all 0.3s ease;
`;

export const HowItWorksTopSection = styled.div`
    padding: 0 36px;
    width: 100%;
    position: relative;
    >img {
        width: 100%;
        max-width: 100%;
        margin-bottom: 24px;
    }
    h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 4px;
        letter-spacing: -0.6px;
        line-height: 1.313rem;
        width: 100%;
        text-align: center;

        @media only screen and (max-width: 900px) {
            text-align: left;
        }
    }
    p {
        color: #88A8A4;
        font-size: 1rem;
        max-width: 310px;
        margin: 0 auto;
        text-align: left;

        @media only screen and (min-width: 900px) {
            max-width: 85%;
            text-align: center;
        }

        strong {
            font-family: var(--font-bold);
        }
    }
`

export const HowItWorksTopSectionHomeLink = styled(NavLink)`
    position: absolute;
    top: 20px;
    left: 52px;
    width: 84.1px;
    height: 40px;

    img {
        width: 100%;
        height: 100%;
    }
`;

export const HowItWorksSwipper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto; 
    overflow-y: hidden;
    scrollbar-width: none;
    gap: 10px;
    padding: 24px 36px 24px 36px;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HowItWorksSwipperRanking = styled.div`
    width: 154px;
    min-width: 154px;
    height: min-content;
    border-radius: 20px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    box-shadow: 0px 3px 16px #001D4129;
    z-index: 5;

    img {
        width: 60px;
        height: 60px;
        margin-left: auto;
    }

    h2 {
        font-family: var(--font-bold);
        font-size: 1.5rem;
        margin-top: auto;
        margin-bottom: 7px;
        line-height: 1.4rem;

        &:nth-of-type(1) {
            color: var(--iron-ranking);
        }
        &:nth-of-type(2) {
            color: var(--bronze-ranking);
        }
        &:nth-of-type(3) {
            color: var(--silver-ranking);
        }
        &:nth-of-type(4),
        &:nth-of-type(5) {
            color: var(--gold-ranking);
        }
    }

    p {
        color: #2D473E;
        font-size: 1rem;
        font-family: var(--font-bold);
    }
`;

export const HowItWorksTopBanner = styled.div`
    padding: 62px 36px 42px 36px;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -35px;
    margin-bottom: 21px;
    width: 100%;

    div {
        color: var(--white);
        font-size: 1rem;
        line-height: 1.125rem;
        max-width: 310px;

        h2 {
            font-family: var(--font-bold);
            font-size: 1.125rem;
            color: var(--green-neon);
            margin-bottom: 4px;
        }
        strong {
            font-family: var(--font-bold);
        }
    }
    img {
        width: 120px;
        height: 120px;
    }
`;

export const HowItWorksMidSection = styled.div`
    padding: 0 36px;
    width: 100%;
    img {
        width: 100%;
        max-width: 100%;
        margin-bottom: 24px;
    }
    >h2 {
        color: var(--black);
        font-size: 1.125rem;
        font-family: var(--font-bold);
        margin-bottom: 1px;
    }
    >p {
        color: var(--black);
        font-size: 1rem;
        margin-bottom: 24px;
        line-height: 1.125rem;
    }
`

export const HowItWorksMidSectionTable = styled.table`
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    tr {
        border-bottom: 2px solid var(--white);
    }

    thead tr {
        padding-bottom: 8px;

        th {
            text-align: center;
            font-size: 1rem;
            font-family: var(--font-bold);
            max-width: 60px;
            line-height: 1.125rem;
            padding-bottom: 8px;

            &:nth-of-type(1) {
                color: var(--iron-ranking);
            }
            &:nth-of-type(2) {
                color: var(--bronze-ranking);
            }
            &:nth-of-type(3) {
                color: var(--silver-ranking);
            }
            &:nth-of-type(4),
            &:nth-of-type(5) {
                color: var(--gold-ranking);
            }
        }
    }

    tbody tr {
        background-color: rgba(235, 243, 239, 0.5);

        td {
            text-align: center;
            font-size: 0.875rem;
            line-height: 1.125rem;
            margin-bottom: 2px;
            padding: 16px 15px 14px 14px;

            &:first-of-type {
                border-radius: 5px 0px 0px 5px;
                position: relative;
                color: var(--iron-ranking);
            }

            &:nth-of-type(2) {
                color: var(--bronze-ranking);
            }
            &:nth-of-type(3) {
                color: var(--silver-ranking);
            }
            &:nth-of-type(4) {
                color: var(--gold-ranking);
            }
            &:last-of-type {
                border-radius: 0px 5px 5px 0px;
                color: var(--gold-ranking);
            }
        }
    }
`;

interface MarkerProps {
    color: string
}

export const Marker = styled.td<MarkerProps>`
    &:before {
        content: '';
        width: 5px;
        height: 100%;
        background-color: ${(props) => props.color};
        position: absolute;
        left: 0px;
        top: 0px;
        border-radius: inherit;
    }
`

export const HowItWorksMidSectionLabel = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 20px;
`

interface BlockProps {
    color: string
}

export const Block = styled.div<BlockProps>`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;

    div {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background-color: ${(props) => props.color};
    }
    
    p {
        font-size: 0.625rem;
        line-height: 0.75rem;
        color: ${(props) => props.color};;
        font-family: var(--font-bold);

        span {
            color: #96A49E;
            font-family: 'Gilroy-Medium',sans-serif;
        }

    }
`

export const HowItWorksMidSectionWarning = styled.h3`
    color: #FF6F64;
    font-family: var(--font-bold);
    font-size: 1rem;
    width: 100%;
    line-height: 1.125rem;
    margin-top: 27px;
    max-width: 310px;

    @media only screen and (min-width: 900px) {
        max-width: 100%;
    }
`

export const HowItWorksMidBanner = styled.img`
    width: 100%;
    max-width: 100%;
    margin-top: 22px;
`

export const HowItWorksBottomSection = styled.div`
    padding: 0 36px;
    width: 100%;
    margin-top: 40px;

    h2 {
        color: var(--primary);
        font-size: 1.125rem;
        line-height: 1.438rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
        width: 100%;
    }
    p {
        width: 100%;
        color: var(--black);
        font-size: 1rem;
        line-height: 1.125rem;

        strong {
            font-family: var(--font-bold);
        }
    }
`

export const HowItWorksBottomSectionBadges = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 160px;

    @media only screen and (min-width: 900px) {
        justify-content: space-evenly;
    }

    img {
        width: 98.54px;
    }
`;

