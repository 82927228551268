import { useCallback } from 'react'
import greenArrow from '../../assets/green-arrow-icon.png'
import * as S from './style'

export interface selectOptionsProps {
    optionTitle: string
    optionValue: string
}

interface SelectFloatingLabelProps {
    selectOptions: selectOptionsProps[]
    selectTitle: string
    onValueChange?: (value: string) => void
}

function SelectFloatingLabel ({ selectOptions, selectTitle, onValueChange }: SelectFloatingLabelProps) {
    const handleChangeValue = useCallback((evt: any) => {
        if (!onValueChange) return
        onValueChange(selectOptions[evt.target.selectedIndex].optionValue)
    }, [onValueChange, selectOptions])
    return <S.SelectFloatingLabel>
        <h3>{selectTitle}</h3>
        <select onChange={handleChangeValue}>
            {selectOptions.map((option, index) => (
                <option key={index} value={option.optionValue}>{option.optionTitle}</option>
            ))}
        </select>
        <img src={greenArrow} alt="ícone de seta apontando para baixo (indicando mais opções)" />
    </S.SelectFloatingLabel>
}

export default SelectFloatingLabel