import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import api from '../../services/api'
import { useAuth } from '../AuthContext'


export interface Specie {
    id: number
    childrens: any[]
    home: {
        badge: string
        title: string
        color: string
        share: number
    },
    name: string
    background: string
    icon: string
    carrosel: any[]
    tabela: Array<{
        title: string
        color: string
        subTitle?: string
        content: string[]
    }>,
    catalog: number
    kpis: any[]
    categories: string[]
}

interface SpeciesContextType {
    species: Specie[]
    specieSelected: Specie
    changeSpecie: (name: number) => void
    isCatalogLeiteSelected: boolean
    userHasGoldBox: boolean
}

const SpeciesContext = createContext<SpeciesContextType | undefined>(undefined)

export function SpeciesProvider ({ children }: { children: React.ReactNode }) {
    const [specieSelectedId, setSpecieSelectedId] = useState<number>(0)
    const [species, setSpecies] = useState<any[]>([])
    const { user, userCan } = useAuth()

    const specieSelected = useMemo(() => {
        if (!species) return
        return species[specieSelectedId]
    }, [specieSelectedId, species])

    const changeSpecie = useCallback((id: number) => {
        setSpecieSelectedId(species.findIndex(s => s.id === id))
    }, [species])

    
  const isCatalogLeiteSelected = useMemo(() => {
    if (!specieSelected) return false
    if (specieSelected.catalog === 81543) return true
    return false
  }, [specieSelected])

  const userHasGoldBox = useMemo(() => {
    if (!species) return false
    if (!userCan('read:gold-box')) return false
    const hasGoldBox = species.find((specie) => [4, 5].includes(specie.userClassId))
    return !!hasGoldBox 
  }, [species, userCan])

    useEffect(() => {
        (async () => {
            const response = await api.get(`/species-info`)
            setSpecies(response.data)
        })()
    }, [user])

    return <SpeciesContext.Provider value={{ species, specieSelected, changeSpecie, isCatalogLeiteSelected, userHasGoldBox }}>
        {children}
    </SpeciesContext.Provider>
}

export function useSpecies () {
    const context = useContext(SpeciesContext);
    if (!context) {
        throw new Error('useSpecies must be used within a SpeciesProvider')
    }
    return context
}