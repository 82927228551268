import styled from 'styled-components';

export const ContactUsContent = styled.section`
    width: 100%;
    max-width: 484px;
    margin: 0 auto;
    background-color: var(--black);
    overflow: auto;
`;

export const ContactUs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 36px;
    padding-bottom: 72px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;

    @media only screen and (min-width: 960px) {
        border-radius: 30px;
        padding-bottom: 40px;
    }

    h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 24px;
    }
`;

export const ContactUsButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: var(--primary);
    color: var(--white);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    margin-top: auto;
`;

export const ContactUsBottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 36px 36px 36px;
    background-color: #EBF3EF;
    margin-top: -32px;
    position: relative;
    z-index: 2;
    gap: 12px;

    >h2 {
        color: var(--black);
        font-size: 1.125rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        font-size: 1rem;
        color: var(--black);
        line-height: 1.125rem;
        
        span {
            color: var(--primary);
            font-family: var(--font-bold);
        }
    }
`;

export const FooterContainer = styled.div`
    width: 100%;
    margin-top: 24px;

    @media only screen and (max-width: 960px) {
        display: none;
    }
`
