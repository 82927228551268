import { useCallback } from 'react';
import api from '../../../services/api';
import { maskPoints } from '../../../utils/mask';
import productNoImage from './assets/product-no-image.png';
import * as S from './style';

interface itemContent {
    sku: string
    imageUrl: string
    name: string
    price: string
    originalId: string
}

interface HomeItemsSwipperProps {
    items: itemContent[]
    link: string
    catalog: number
}

function HomeItemsSwipper ({ items, catalog }: HomeItemsSwipperProps) {
    const open = useCallback(async (item: any) => {
        const response = await api.get(`/cargill-catalogo/${catalog}/${item.sku}`)
        console.log(response)
        window.open(response.data.url, '_blank')
    }, [catalog])

    return <S.HomeItemsSwipper>
        <S.HomeItemsSwipperItemsContent>
            {items.map((item, index) => (
                <S.HomeItemsSwipperItem key={index} onClick={() => open(item)}>
                    <S.HomeItemsSwipperItemImage src={item.imageUrl || productNoImage} />
                    <S.HomeItemsSwipperItemTitle>{item.name}</S.HomeItemsSwipperItemTitle>
                    <S.HomeItemsSwipperItemPrice>
                        <p>A partir de</p>
                        <span>{maskPoints(item.price)} <sup>pts</sup></span>
                    </S.HomeItemsSwipperItemPrice>
                </S.HomeItemsSwipperItem>
            ))}
        </S.HomeItemsSwipperItemsContent>
    </S.HomeItemsSwipper>
}

export default HomeItemsSwipper