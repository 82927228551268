import styled from 'styled-components';

export const Login = styled.section`
    width: 100%;
    background-color: var(--black);
    color: var(--black);
    max-width: 920px;
    margin: 0 auto;
    margin-top: 70px;
    animation: fadeIn 0.4s ease-in-out;
        
    @media (min-width: 960px) {
        margin-top: 79px;
    }
`;

export const LoginContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 100%;

        @media (min-width: 960px) {
            max-height: 600px;
        }
    }
`

export const LoginContentInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--white);
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    padding: 36px;
    margin-top: -60px;
    height: auto;
    
    @media (min-width: 960px) {
        border-radius: 30px;
        margin-bottom: 32px;
    }

    p {
        font-size: 1.5rem;
        font-family: 'Gilroy-Bold', sans-serif;
        margin-bottom: 32px;
        color: var(--black);
        width: 100%;
    }
`;

export const LoginContentInputsContainerBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (min-width: 960px) {
        flex-direction: row;
        gap: 16px;

        >div {
            margin-bottom: 24px;
        }
    }

    >button {
        background-color: var(--primary);
        width: 100%;
        padding: 17px;
        font-size: 1.125rem;
        font-family: 'Gilroy-Bold', sans-serif;
        text-align: center;
        color: var(--white);
        border-radius: 28px;
        margin-bottom: 24px;
    }
`;

export const LoginContentTextAction = styled.div`
    font-size: 1rem;
    color: var(--black);
    margin-right: auto;

    button,
    a {
        text-decoration: none;
        font-family: 'Gilroy-Bold', sans-serif;
        color: var(--black);
        background: transparent;
        font-size: 1rem;
    }
`