import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Toolbar = styled.div`
    box-shadow: 0px -3px 6px 3px rgba(0,0,0,0.04);
    padding: 16px 20px 12px 20px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 40;
    display: flex;  
    align-items: center;
    justify-content: space-around;
    background-color: var(--primary);
    border-radius: 30px 30px 0px 0px;
    max-width: 900px;

    @media only screen and (min-width: 960px) {
        display: none;
    }
`;

export const ToolbarOption = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: var(--font-bold);
    font-size: 0.875rem;
    color: var(--white);
    text-decoration: none;
    img {
        fill: var(--white);
        width: 24px;
    }
`;

export const ToolbarLinkOption = styled(NavLink) <{ $active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: var(--font-bold);
    font-size: 0.875rem;
    color: ${({ $active }) => ($active ? 'var(--green-neon)' : 'var(--white)')};
    text-decoration: none;
    img {
        fill: ${({ $active }) => ($active ? 'var(--green-neon)' : 'var(--white)')};
        width: 24px;
    }
`;

export const ToolbarLinkOptionAnchor = styled.a <{ $active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: var(--font-bold);
    font-size: 0.875rem;
    color: ${({ $active }) => ($active ? 'var(--green-neon)' : 'var(--white)')};
    text-decoration: none;
    img {
        fill: ${({ $active }) => ($active ? 'var(--green-neon)' : 'var(--white)')};
        width: 24px;
    }
`;
