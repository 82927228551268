import styled from "styled-components";

export const HomeSwipperHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 36px;
    margin-top: 8px;
`;

export const HomeSwipperHeaderTitle = styled.div`
    color: var(--black);
    font-size: 1.125rem;
    font-family: var(--font-bold);
`;

export const HomeSwipperHeaderActionTitle = styled.a`
    color: var(--primary);
    cursor: pointer;
    font-size: 1.125rem;
    font-family: var(--font-bold);
    text-decoration: none;
`;