import ironBadge from './assets/iron.png'
import * as S from './style'

interface UserCategoryHubItemProps {
    subtitle?: string
    specieSelectedInfos: any
    kpi?: any
}

function UserCategoryHubItem ({ subtitle, specieSelectedInfos }: UserCategoryHubItemProps) {

    if (!specieSelectedInfos) return <></>

    if (specieSelectedInfos?.catalog === 81543) {
        return <S.UserCategoryHubItem>
            <S.UserCategoryHubItemHeader>
                Categoria {subtitle && `(${subtitle})`}
            </S.UserCategoryHubItemHeader>
            <img src={ironBadge} alt="ícone de atual categoria do usuário" />
            {specieSelectedInfos.kpis.map((kpi: any) => <S.UserCategoryHubItemProgress>
                <S.UserCategoryHubItemProgressInfos color='#956F24'>
                    <p>{kpi.name}</p>
                    <span id='user_category_progress'>{kpi.value}%</span>
                </S.UserCategoryHubItemProgressInfos>
                <S.UserCategoryHubItemProgressBar color='#956F24' $progress={kpi.value} />
            </S.UserCategoryHubItemProgress>)}
        </S.UserCategoryHubItem>
    }

    return <S.UserCategoryHubItem>
        <S.UserCategoryHubItemHeader>
            Categoria {subtitle && `(${subtitle})`}
        </S.UserCategoryHubItemHeader>
        {specieSelectedInfos.home.badge && <img src={specieSelectedInfos.home.badge} alt="ícone de atual categoria do usuário" />}
        {specieSelectedInfos.kpis.map((kpi: any) => <S.UserCategoryHubItemProgress>
            <S.UserCategoryHubItemProgressInfos color={specieSelectedInfos.home.color}>
                <p>{kpi.name}</p>
                <span id='user_category_progress'>{kpi.value}%</span>
            </S.UserCategoryHubItemProgressInfos>
            <S.UserCategoryHubItemProgressBar color={specieSelectedInfos.home.color} $progress={kpi.value} />
        </S.UserCategoryHubItemProgress>)}
    </S.UserCategoryHubItem>
}

export default UserCategoryHubItem