import { useEffect, useState } from 'react'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import Loader from '../Loader'
import * as S from './style'

enum PageState {
    LOADING,
    READY
}

interface TermosDeUsoState {
    currentState: PageState
    data: any
}

function TermosDeUsoContent () {
    const [state, setState] = useState<TermosDeUsoState>({
        currentState: PageState.LOADING,
        data: undefined
    })
    const { closeBottomModal } = useBottomModal()

    useEffect(() => {
        (async () => {
            const response = await api.get(`/term`)
            setState({
                currentState: PageState.READY,
                data: response.data
            })
        })()
    }, [])

    if (state.currentState === PageState.LOADING) return <Loader />

    return <S.RegulamentoContent>
        <h2>Termos de Uso</h2>
        <S.RegulamentoContentText dangerouslySetInnerHTML={{ __html: state.data.content }}></S.RegulamentoContentText>
        <S.RegulamentoContentButtons>
            <button className='button-outlined' onClick={closeBottomModal}>Entendi!</button>
        </S.RegulamentoContentButtons>
    </S.RegulamentoContent>
}

export default TermosDeUsoContent