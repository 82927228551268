import { useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import AddShortcut from "./pages/AddShortcut";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import ContactUsLogged from "./pages/ContactUsLogged";
import Faq from "./pages/Faq";
import Filiais from "./pages/Filial";
import ForgotPassword from "./pages/ForgotPassword";
import GoldBox from "./pages/GoldBox";
import Home from "./pages/Home";
import HowItWorksSwitcher from "./pages/HowItWorksSwitcher";
import Login from "./pages/Login";
import MyInfos from "./pages/MyInfos";
import NewAddress from "./pages/NewAddress";
import NewFilial from "./pages/NewFilial";
import NewPassword from "./pages/NewPassword";
import RecoveryPassword from "./pages/RecoveryPassword";
import Regulamento from "./pages/Regulamento";
import Shop from "./pages/Shop";
import Signup from "./pages/Signup";
import TransferYourPoints from "./pages/TransferYourPoints";
import UpdateProfile from "./pages/UpdateProfile";
import Wallet from "./pages/Wallet";
import Welcome from "./pages/Welcome";

interface PrivateRouteProps {
    Component: React.ComponentType<any>
    permission?: string
}

function PrivateRoute ( { Component, permission }: PrivateRouteProps) {
    const { userCan } = useAuth();

    const isAuthenticated = useCallback(() => {
        const token = localStorage.getItem('token');
        if (!token) return false;
        return true;
    }, []) 
    const isNotAuthenticated = !isAuthenticated()
    if (isNotAuthenticated) return <Navigate to="/login" />
    if (permission && !userCan(permission)) return <Navigate to="/inicio" />
    return <Component />
}

export default function Rotas () {
    return (
        <Routes>
            <Route element={<Login />} path="/" />
            <Route element={<Login />} path="/login" />
            <Route element={<Signup />} path="/cadastre-se" />
            <Route element={<ForgotPassword />} path="/recuperar-senha" />
            <Route element={<RecoveryPassword />} path="/recuperar-senha/:token" />
            <Route element={<ContactUs />} path="/fale-conosco" />
            <Route element={<GoldBox />} path="/gold-box" />
            <Route element={<PrivateRoute Component={NewPassword} />} path="/nova-senha" />
            <Route element={<PrivateRoute Component={ContactUsLogged} />} path="/fale-conosco-logged" />
            <Route element={<PrivateRoute Component={Welcome} />} path="/bem-vindo" />
            <Route element={<PrivateRoute Component={Home} />} path="/inicio" />
            <Route element={<PrivateRoute Component={MyInfos} />} path="/meus-dados" />
            <Route element={<PrivateRoute Component={NewAddress} />} path="/novo-endereco" />
            <Route element={<PrivateRoute Component={HowItWorksSwitcher} />} path="/como-funciona" />
            <Route element={<PrivateRoute Component={AddShortcut} />} path="/adicionar-atalho" />
            <Route element={<PrivateRoute Component={TransferYourPoints} />} path="/transfira-seus-pontos" />
            <Route element={<PrivateRoute Component={Blog} />} path="/blog" />
            <Route element={<PrivateRoute Component={Faq} />} path="/perguntas-frequentes" />
            <Route element={<PrivateRoute Component={Wallet} />} path="/carteira" />
            <Route element={<PrivateRoute Component={Shop} />} path="/loja" />
            <Route element={<PrivateRoute Component={UpdateProfile} />} path="/atualizar-perfil" />
            <Route element={<PrivateRoute Component={Filiais} />} path="/filiais" />
            <Route element={<PrivateRoute Component={NewFilial} />} path="/filiais/nova" />
            <Route element={<PrivateRoute Component={Regulamento} />} path="/regulamento" />
        </Routes>
    )
}