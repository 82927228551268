import styled from 'styled-components';

export const NewPasswordContent = styled.section`
    width: 100%;
    max-width: 485px;
    margin: 0 auto;
    background-color: var(--black);
    overflow: auto;
`;

export const NewPassword = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    margin-bottom: 16px;
    transition: all 0.3s ease;

    @media only screen and (min-width: 960px) {
        height: auto;
        min-height: auto;
        border-radius: 30px;
    }

    >h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    >p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 24px;
        max-width: 305px;

        @media only screen and (min-width: 960px) {
            max-width: none;
        }
    }
`;

export const NewPasswordRules = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    h2 {
        font-family: var(--font-bold);
        color: var(--black);
        font-size: 1rem;
        line-height: 0.875rem;
        width: 100%;
        margin-bottom: 3px;
        letter-spacing: -0.4px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
    }
    img {
        width: 16px;
        min-width: 16px;
    }
    p {
        font-size: 0.875rem;
        line-height: 0.75rem;
        color: var(--black);

    }
`

export const NewPasswordRulesTitle = styled.h2`
    font-family: var(--font-bold);
    margin-bottom: 32px;
    color: var(--black);
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 100%;
`

export const NewPasswordRulesContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
    }
`

export const NewPasswordRulesContentBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media only screen and (min-width: 960px) {
        width: 50%;
        max-width: 50%;
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
    }
    img {
        width: 16px;
        min-width: 16px;
    }
    p {
        font-size: 0.75rem;
        line-height: 1rem;
        color: var(--black);
    }
`

export const NewPasswordSupport = styled.div`
    width: 100%;
    color: var(--black);
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 32px;
    margin-bottom: 26px;

    a {
        text-decoration: none;
        font-family: var(--font-bold);
        color: var(--primary);
        line-height: 1.25rem;
        background-color: transparent;
        font-size: 1rem;
    }
`;

export const NewPasswordButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: var(--primary);
    color: var(--white);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    margin-top: auto;
`;
