import styled from 'styled-components';

export const UserPointsDesktop = styled.div`
    display: flex;
    align-items: center;
    font-family: var(--font-bold);
    position: relative;
    top: 0px;
    right: 0px;
    margin-top: 0px;
    width: 295px;
    padding: 18px 32px;
    justify-content: space-between;
    background-color: #061510;
    height: 68px;
    
    @media only screen and (max-width: 960px) {
        display: none;
    }

    .user-name-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1px;
        height: 44px;
        font-size: 0.625rem;
        height: auto;
        color: var(--white);

        span {
            color: var(--white);
            font-size: 1rem;
        }
    }
`

export const UserPoints = styled.div`
    right: 18px;
    padding: 0px;
    border-radius: 0px;
    top: 18px;
    width: 150px;
    height: 44px;
    overflow: visible;
    display: flex;
    align-items: flex-start;
    font-family: var(--font-bold);
    max-width: 400px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    justify-content: flex-start;
    z-index: 10;
    flex-direction: column;

    .background {
        width: 100%;
        height: 100%;
        background: #061510;
        backdrop-filter: blur(17px);
        transition: all 0.3s ease-in-out;
        position: absolute;
        z-index: 11;
        top: 0px;
        left: 0px;
        border-radius: inherit;
    }
`

export const UserPointsTotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .total-points {
        display: flex;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.625rem;
        transition: all 0.1s ease-in-out;
        z-index: 12;

        .total-points-number {
            color: var(--green-neon);
            font-size: 1rem;
            transition: all 0.1s ease-in-out;
        }
    }
`

export const ToggleUserPoints = styled.button`
    width: 24px;
    height: 24px;
    background-color: var(--green-neon);
    position: absolute;
    top: 5px;
    right: 12px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
`

export const ToggleUserPointsArrow = styled.div<{ $isToggled: boolean }>`
    transform-style: preserve-3d; 
    transition: transform 0.3s ease;
    transform: rotate(${({ $isToggled }) => ($isToggled ? `180deg` : `0deg`)});
    width: 12px;
    height: 12px;
    img {
        width: 100%;
    }
`

export const UserPointsButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 8px;
    z-index: 12;
    width: 100%;
    padding-right: 4px;
    
    button {
        border-radius: 18px;
        padding: 10px 13px;
        font-size: 0.6rem;
        font-family: var(--font-bold);
        white-space: nowrap;
    }
    .button-green {
        background-color: var(--green-neon);
        color: #2D473E;
    }
    .button-outlined {
        color: var(--white);
        background-color: transparent;
        border: 1px solid var(--green-neon);
    }
`

export const UserPointsDesktopMenu = styled.div<{ $isVisible: boolean }>`
    position: absolute;
    top: 50px;
    right: -18px;
    padding-bottom: 0px;
    flex-direction: column;
    height: ${({ $isVisible }) => ($isVisible ? `calc(100vh - 50px)` : `0px`)};
    overflow: hidden;
    transition: height 0.3s ease;
    background-color: #061510;
    width: 295px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-top: 2px solid #000000;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
    }
    
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border: 0px none #ffffff;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--green-neon);
    }
    &::-webkit-scrollbar-thumb:active {
        background: var(--green-neon);
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
        background: #fff;
    }
    &::-webkit-scrollbar-track:active {
        background: #fff;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
`

export const UserPointsDesktopMenuDetails = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 2px solid #000000;
`

export const UserPointsDesktopMenuLinks = styled(UserPointsDesktopMenuDetails)`
    border-bottom: 0px;

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 32px;

        a, li {
            text-decoration: none;
            font-size: 1rem;
            color: #EBF3EF;
            cursor: pointer;
            transition: color 0.3s ease;

            &:hover {
              color: #fff;
              transition: color 0.3s ease;
            }
        }
    }
`

