import styled from 'styled-components';
import wppIcon from './assets/wpp-icon.png';

export const ContactUsLoggedContainer = styled.section`
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
`;

export const ContactUsLogged = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;

    @media only screen and (min-width: 960px) {
        margin-top: -30px;
        padding-bottom: 64px;
    }

    h2.title {
        margin-bottom: 32px;

        @media only screen and (min-width: 960px) {
            font-size: 1.313rem
        }
    }
`;

export const ContactUsLoggedContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    @media only screen and (min-width: 960px) {
        flex-direction: row;
        gap: 16px;
    }

`

export const ContactUsLoggedContentForm = styled.div`
    width: 100%;
`

export const ContactUsLoggedButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: var(--primary);
    color: var(--white);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    max-width: 420px;

    @media only screen and (min-width: 960px) {
        flex-direction: row;
        gap: 16px;
    }
`;

export const ContactUsLoggedContentContactInfos = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background-color: #EBF3EF;
    position: relative;
    z-index: 2;
    gap: 12px;

    @media only screen and (min-width: 960px) {
        max-width: 50%;
        margin: 0 auto;
        border-radius : 30px;
        max-height: 216px;
        align-items: center;
        justify-content: center;
    }

    h2 {
        color: var(--black);
        font-size: 1.125rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        font-size: 1rem;
        color: var(--black);
        line-height: 1.125rem;
        
        a, span {
            color: var(--primary);
            font-family: var(--font-bold);
        }
    }
`;

export const ContactUsLoggedBottomSectionContact = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;

    .image-container {
        width: 63px;
        min-width: 63px;
        height: 63px;
        border-radius: 50%;
        background-color: var(--white);
        background-image: url(${wppIcon});
        background-position: center;
        background-size: 34px;
        background-repeat: no-repeat;
    }

    h2 {
        color: var(--black);
        font-size: 1.125rem;
        font-family: var(--font-bold);
    }

    p {
        width: 80%;
        margin: 0;
    }
`;

export const FooterCompleteContainer = styled.div`
    @media only screen and (max-width: 960px) {
        display: none;
    }
`
