import { useBottomModal } from '../../contexts/BottomModalContext'
import * as S from './style'

function PasswordChangeRequestModalContent() {
    const { closeBottomModal } = useBottomModal()
    
    return <S.PasswordChangeRequestModalContent>
        <h2>Enviado!</h2>
        <p>
            Enviamos um link de recuperação de senha para seu e-mail. Basta seguir as orientações para criar uma senha nova!
        </p>
        <S.PasswordChangeRequestModalContentButtons>
            <button className='button-outlined' onClick={closeBottomModal}>Continuar</button>
        </S.PasswordChangeRequestModalContentButtons>
    </S.PasswordChangeRequestModalContent>
}

export default PasswordChangeRequestModalContent