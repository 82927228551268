import { createGlobalStyle, css } from "styled-components";
import { default as GilroyBold, default as GilroyLight } from '../fonts/Gilroy-Bold.ttf';
import GilroyMedium from '../fonts/Gilroy-Medium.ttf';

const fonts = css`
    @font-face {
        font-family: 'Gilroy-Bold';
        src: local('Gilroy-Bold'), url(${GilroyBold}) format('truetype');
    }

    @font-face {
        font-family: 'Gilroy-Medium';
        src: local('Gilroy-Medium'), url(${GilroyMedium}) format('truetype');
    }

    @font-face {
        font-family: 'Gilroy-Light';
        src: local('Gilroy-Light'), url(${GilroyLight}) format('truetype');
    }
`

const root = css`
    :root {
        --primary: #008350;
        --white: #FFFFFF;
        --green-neon: #1DF161;
        --gray-bg: #EBF3EF;
        --black: #0C2B21;
        --iron-ranking: #956F24;
        --bronze-ranking: #F27507;
        --silver-ranking: #636D69;
        --gold-ranking: #F2A407;
        --font-bold: 'Gilroy-Bold',sans-serif;
    }
`

const rootColors = { default: root }

const GlobalStyle: any = createGlobalStyle`
    ${fonts}
    ${rootColors.default}

    *, ::after, :before { 
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        box-sizing: border-box;
    }

    *, ::before, ::after {
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        padding: 0;
    }

    button {
        cursor: pointer;
    }

    #root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        overflow: hidden;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        background-color: var(--black);
    }
    
    @keyframes fadeIn {
        0% {
            opacity: 0
        }
        
        100% {
            opacity: 100%
        }
    }
`

export default GlobalStyle