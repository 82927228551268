import { RefObject, useCallback, useEffect, useRef } from 'react'
import { useBottomModal } from '../contexts/BottomModalContext'

/**
 * Add ClickOutside event to element
 *
 * @param {() => void} onClickOutside - Callback to perform when click outside
 * @returns {[RefObject<HTMLDivElement>]} - Add this to ref on element
 */
export function useClickOutside (onClickOutside: () => void): [RefObject<HTMLDivElement>] {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { isBottomModalOpen } = useBottomModal()

  const handleClickOutside = useCallback((evt: MouseEvent) => {
    if ((wrapperRef.current != null) && !wrapperRef.current.contains(evt.target as Node)) {
      onClickOutside()
    }
  }, [wrapperRef, onClickOutside])

  useEffect(() => {
    if (!isBottomModalOpen) return

    setTimeout(() => {
      document.addEventListener('click', handleClickOutside)
    }, 600);

    return () => document.removeEventListener('click', handleClickOutside)
  }, [handleClickOutside, isBottomModalOpen])

  return [wrapperRef]
}
