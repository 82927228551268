
import { validateEmail } from "validations-br";

class EmailValidatorAdapter {
  isValid (email) {
    return validateEmail(email)
  }
}

export default EmailValidatorAdapter;
