import { useSideMenu } from '../../../contexts/SideMenuContext';
import menuIcon from './assets/menu-icon.png';
import * as S from './style';

function MenuButton() {
    const {toggleSideMenu} = useSideMenu()

    return <S.MenuButton onClick={toggleSideMenu} >
        <img src={menuIcon} alt="ícone de seta apontando para esquerda (botão de voltar)" />
    </S.MenuButton >
}

export default MenuButton