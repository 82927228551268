import styled from 'styled-components'

export const Container = styled.div`
  background-color: #EBF3EF;
  color: #0C2B21;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 12px;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 24px;
`