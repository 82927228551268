import styled from "styled-components"

export const FooterComplete = styled.footer`
    width: 100%;
    border-radius: 30px 30px 0 0;
    background-color: #2D473E;
    padding: 36px 36px 91px 36px;
    margin-top: -30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 5;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        img {
            width: 240px;
        }
        p {
            color: #88A8A4;
            font-size: 0.75rem;
            max-width: 160px;
        }
    }
`

export const FooterCompleteNav = styled.footer`
    width: 100%;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        margin-bottom: 21px;
        list-style-type: none;

        li {
            color: var(--white);
            cursor: pointer;
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }

`