import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import checkIcon from '../../assets/icons/check-icon.png'
import notCheckIcon from '../../assets/icons/not-check-icon.png'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import PasswordChangedModalContent from '../../components/PasswordChangedModalContent'
import { useBottomModal } from '../../contexts/BottomModalContext'
import api from '../../services/api'
import * as S from './style'

enum PasswordButtonState {
    READY,
    INVALID,
    SENDING
}

interface RecoveryPasswordAttributes {
    newPassword: string
    confirmPassword: string
    token: string
    documentInputDisabled: boolean
    bullets: {
        length: boolean
        hasUpperCase: boolean
        hasLowerCase: boolean
        hasNumber: boolean
        compare: boolean
    }
    passwordButton: {
        currentState: PasswordButtonState
    }
}

function RecoveryPassword () {
    const { token } = useParams()
    const [state, setState] = useState<RecoveryPasswordAttributes>({
        newPassword: '',
        confirmPassword: '',
        token: '',
        documentInputDisabled: true,
        bullets: {
            length: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            compare: false
        },
        passwordButton: {
            currentState: PasswordButtonState.INVALID
        }
    })
    const { showBottomModal } = useBottomModal()
    const navigate = useNavigate()

    const validatePassword = useCallback((newState: RecoveryPasswordAttributes) => {
        if (!newState.newPassword) {
            newState.bullets.length = false
            newState.bullets.hasUpperCase = false
            newState.bullets.hasLowerCase = false
            newState.bullets.hasNumber = false
            newState.bullets.compare = false
            setState(newState)
            return
        }
        newState.passwordButton.currentState = PasswordButtonState.READY
        newState.bullets.length = newState.newPassword.length >= 6 && newState.newPassword.length <= 12
        newState.bullets.hasUpperCase = newState.newPassword.match(/[A-Z]/) !== null
        newState.bullets.hasLowerCase = newState.newPassword.match(/[a-z]/) !== null
        newState.bullets.hasNumber = newState.newPassword.match(/[0-9]/) !== null
        newState.bullets.compare = newState.newPassword === newState.confirmPassword

        console.log(newState.bullets)
        if (!newState.bullets.length ||
            !newState.bullets.hasUpperCase ||
            !newState.bullets.hasLowerCase ||
            !newState.bullets.hasNumber ||
            !newState.bullets.compare) {
            newState.passwordButton.currentState = PasswordButtonState.INVALID
        }
        setState(newState)
    }, [])

    const handleNewPasswordRequest = useCallback(async () => {
        try {
            await api.patch('/short/password', {
                token: state?.token,
                newPassword: state?.newPassword
            })
            showBottomModal(<PasswordChangedModalContent onClose={() => navigate('/')} />)
        } catch (err) {
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
    }, [navigate, showBottomModal, state])

    const handleUpdateState = useCallback((state: RecoveryPasswordAttributes) => {
        setState(state)
        validatePassword(state)
    }, [validatePassword])

    useEffect(() => {
        if (!token) return
        setState((state) => ({
            ...state,
            token
        }))
    }, [token])

    return <S.NewPasswordContent>
        <Header element={<ReturnPageButton />} />
        <S.NewPassword>
            <h2>Cadastre sua nova senha.</h2>
            <p>
                Preencha os campos abaixo e crie sua nova senha.
            </p>
            <InputFloatingLabel inputTitle='Crie sua senha' inputType='password' onValueChange={(value: string) => handleUpdateState({ ...state, newPassword: value })} />
            <InputFloatingLabel inputTitle='Confirme a senha' inputType='password' onValueChange={(value: string) => handleUpdateState({ ...state, confirmPassword: value })} />
            <S.NewPasswordRules>
                <h2>A senha deve conter:</h2>
                <div>
                    <img src={state.bullets.length ? checkIcon : notCheckIcon} alt="Regra 1 para prosseguir com a senha: De 6 a 12 caracteres" />
                    <p>De 6 a 12 caracteres</p>
                </div>
                <div>
                    <img src={state.bullets.hasUpperCase ? checkIcon : notCheckIcon} alt="Regra 2 para prosseguir com a senha: Ao menos uma letra maicúscula" />
                    <p>Ao menos uma letra maicúscula</p>
                </div>
                <div>
                    <img src={state.bullets.hasLowerCase ? checkIcon : notCheckIcon} alt="Regra 3 para prosseguir com a senha: Ao menos uma letra minúscula" />
                    <p>Ao menos uma letra minúscula</p>
                </div>
                <div>
                    <img src={state.bullets.hasNumber ? checkIcon : notCheckIcon} alt="Regra 4 para prosseguir com a senha: Ao menos um número" />
                    <p>Ao menos um número</p>
                </div>
                <div>
                    <img src={state.bullets.compare ? checkIcon : notCheckIcon} alt="Regra 4 para prosseguir com a senha: A nova senha e a confirmação devem ser iguais" />
                    <p>A nova senha e a confirmação devem ser iguais</p>
                </div>
            </S.NewPasswordRules>
            <S.NewPasswordSupport>
                Precisa de ajuda? <Link to="/fale-conosco-logged">Fala com a gente!</Link>
            </S.NewPasswordSupport>
            <S.NewPasswordButton
                onClick={handleNewPasswordRequest}
                disabled={state.passwordButton.currentState !== PasswordButtonState.READY}
                >Enviar</S.NewPasswordButton>
        </S.NewPassword>
    </S.NewPasswordContent>
}

export default RecoveryPassword