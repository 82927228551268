import styled from 'styled-components';

export const LoaderBackdrop = styled.div`
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: rgba(12, 43, 33, 0.5);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0px;
    display: block;
    transition: all 0.3s ease-in-out;
`

export const Loader = styled.section`
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--green-neon);
    animation: l15 1s infinite linear;
    position: relative;
    top: calc(50vh - 25px);
    left: calc(50vw - 25px);

    &::before,
    &::after {
        content: "";
        grid-area: 1/1;
        margin: 2px;
        border: inherit;
        border-radius: 50%;
        animation: l15 2s infinite;
    }

    &::after {
        margin: 8px;
        animation-duration: 3s;
    }
    
    @keyframes l15{ 
        100%{transform: rotate(1turn)}
    }
`;