import { CreateComment } from "../../domain/use-case/CreateComment";
import api from "../../services/api";
import { parseComment } from "./RemoteFetchPosts";

export class RemoteCreateComment implements CreateComment {
  async handle (params: CreateComment.Params): Promise<CreateComment.Result> {
    const response = await api.patch(`/posts/${params.postId}/comment`, {
      content: params.content
    })
    return parseComment(response.data)
  }
}