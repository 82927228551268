import { useCallback, useEffect, useState } from 'react'
import grayArrow from '../../assets/gray-arrow-icon.png'
import DesktopBanner from '../../components/DesktopBanner'
import FooterComplete from '../../components/FooterComplete'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import Loader from '../../components/Loader'
import Toolbar from '../../components/Toolbar'
import api from '../../services/api'
import { maskPoints } from '../../utils/mask'
import * as S from './style'

enum State {
    LOADING,
    READY
}

interface CatalogAttributes {
    name: string
    kpis: Array<{
        name: string
        value: number
    }>
    benefitBalance: number
    reinvestBalance: number
}

interface ClientAttributes {
    name: string
    catalogs: CatalogAttributes[]
}

interface WalletAttributes {
    currentState: State
    data: ClientAttributes[]
}

function Wallet () {
    const [ClientShown, setClientShown] = useState<number | null>(null)
    const [state, setState] = useState<WalletAttributes>({
        currentState: State.LOADING,
        data: []
    })

    const toggleClient = useCallback((index: number) => {
        setClientShown(ClientShown === index ? null : index);
    }, [ClientShown])

    const handleDownloadWallet = useCallback(() => {
        const header = ["Usuário", "Espécie", "Nome KPI", "Valor KPI" ,"Saldo Reinvestimento", "Total Benefícios"];
        const lines = [header.join(';')]
    
        state.data?.forEach(client => {
            client.catalogs.forEach(catalog => {
                catalog.kpis.forEach(kpi => {
                    const line = [
                        client.name,
                        catalog.name,
                        kpi.name.toString(),
                        kpi.value + '%',
                        catalog.reinvestBalance.toString(),
                        catalog.benefitBalance.toString()
                    ];
                    lines.push(line.map(cell => `"${cell.replace(/"/g, '""')}"`).join(';'))
                })
            });
        });
        const csvContent = "\uFEFF" + lines.join('\r\n')
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
        const link = document.createElement("a")
        link.setAttribute("href", URL.createObjectURL(blob))
        link.setAttribute("download", "Carteira.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    
    }, [state.data])

    const sortClientsByName = useCallback((clients: ClientAttributes[]) => {
        return clients.sort((a: any, b: any) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })
    }, [])

    const calculateBenefitsBalance = useCallback((catalogs: CatalogAttributes[]) => {
        let sum = 0

        for (let i = 0; i < catalogs.length; i++) {
            sum += catalogs[i].benefitBalance
        }
        return sum.toFixed(2)
    }, [])

    useEffect(() => {
        (async () => {
            setState((state) => ({ ...state, currentState: State.LOADING }))
            const response = await api.get(`/wallet`)
            setState((state) => ({
                currentState: State.READY,
                data: sortClientsByName(response.data)
            }))
        })()
    }, [sortClientsByName])

    if (state.currentState === State.LOADING) return <Loader />

    return <S.WalletContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Carteira' borderBottom showDesktopWidgets />
        <DesktopBanner title='Carteira' />
        <S.Wallet>
            <h2>
                Acompanhe todos os dados de sua carteira.
            </h2>
            <S.WalletInfos>
                <div>Você tem <span>{state.data.length}</span> {state.data.length === 1 ? 'cliente' : 'clientes'}.</div>
                <button onClick={handleDownloadWallet}>Baixar carteira</button>
            </S.WalletInfos>
        </S.Wallet>
        <S.WalletBottomSection>
            <h2>Confira a lista completa:</h2>
            <div>
                {state.data.map((client, index) => (
                    <S.WalletItem key={index}>
                        <S.WalletItemHeader onClick={() => toggleClient(index)} $isToggled={ClientShown === index}>
                            <p>{client.name}</p>
                            <img src={grayArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                        </S.WalletItemHeader>
                            <S.WalletItemContent $isShown={ClientShown === index}>
                                {client.catalogs.map((catalog, index) => <S.WalletItemContentClient key={index} className=''>
                                    <h3>Espécie {catalog.name}</h3>
                                    {catalog.kpis.map(kpi => (
                                        <div>
                                            <p>{kpi.name}</p>
                                            <span>{kpi.value}%</span>
                                        </div>
                                    ))}
                                    <div>
                                        <p>Saldo Benefício</p>
                                        <span>{maskPoints(catalog.benefitBalance)}</span>
                                    </div>
                                    <div>
                                        <p>Saldo Reinvestimento</p>
                                        <span>{maskPoints(catalog.reinvestBalance)}</span>
                                    </div>
                            </S.WalletItemContentClient>)}
                            {client.catalogs.length > 1 && <>
                                <div className='line'></div>
                                <S.BenefitsBalance>
                                    <p>Total em Benefício</p>
                                    <span>{maskPoints(calculateBenefitsBalance(client.catalogs))}</span>
                                </S.BenefitsBalance>
                            </>}
                        </S.WalletItemContent>
                    </S.WalletItem>
                ))}
            </div>
            <S.WalletButton onClick={() => window.scrollTo(0, 0)}>
                Voltar ao topo
            </S.WalletButton>
        </S.WalletBottomSection>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
        <Toolbar />
    </S.WalletContent>
}

export default Wallet