import styled from 'styled-components';
import desktopBannerImage from './assets/desktopBannerImage.png';

export const DesktopBanner = styled.div`
  margin-top: 70px;
  width: 100%;
  background-image: url(${desktopBannerImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;


  @media only screen and (max-width: 960px) {
    display: none;
  }
`

export const DesktopBannerTitle = styled.p`
  font-size: 2rem;
  font-family: var(--font-bold);
  color: #FFFFFF;
  white-space: break-spaces;
  position: relative;
  top: 48px;
  left: 38px;
  max-width: 180px;
  line-height: 1.9rem;
`