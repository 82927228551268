import { useCallback } from 'react'
import { useBottomModal } from '../../contexts/BottomModalContext'
import * as S from './style'

interface PasswordChangedModalContentProps {
    onClose?: () => void
}

function PasswordChangedModalContent ({ onClose }: PasswordChangedModalContentProps) {
    const { closeBottomModal } = useBottomModal()

    const handleClose = useCallback(() => {
        if (onClose) onClose()
        closeBottomModal()
    }, [closeBottomModal, onClose])

    return <S.PasswordChangedModalContent>
        <h2>Senha alterada!</h2>
        <p>
            Agora é só acessar novamente com o seu login e sua nova senha.
        </p>
        <S.PasswordChangedModalContentButtons>
            <button className='button-outlined' onClick={handleClose}>Entendi!</button>
        </S.PasswordChangedModalContentButtons>
    </S.PasswordChangedModalContent>
}

export default PasswordChangedModalContent