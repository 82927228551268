import styled from 'styled-components';


export const UserPointsMobile = styled.div`
    width: 100%;
    background: none;
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 36px;
    font-family: var(--font-bold);
    position: relative;
    
    @media only screen and (min-width: 960px) {
        display: none;
    }

    .user-name-container {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1px;
        height: 44px;

        @media only screen and (min-width: 960px) {
            font-size: 0.625rem;
            height: auto;
        }

        span {
            color: var(--white);
            font-size: 1.313rem;
            
            @media only screen and (min-width: 960px) {
                font-size: 1rem;
            }
        }
    }
`

export const UserPoints = styled.div<{ $isExpanded: boolean, $isCatalogLeiteSelected: boolean }>`
    display: flex;
    align-items: flex-start;
    font-family: var(--font-bold);
    border-radius: 21px;
    width: ${({ $isExpanded }) => ($isExpanded ? `calc(100vw - 72px)` : `180px`)};
    max-width: 400px;
    position: absolute;
    top: 0px;
    right: 36px;
    transition: all 0.3s ease-in-out;
    justify-content: flex-start;
    height: ${({ $isExpanded, $isCatalogLeiteSelected }) => ($isExpanded ? $isCatalogLeiteSelected ? '302px' : '242px' : '44px')};
    padding: ${({ $isExpanded }) => ($isExpanded ? `16px 12px 16px 16px` : `6px 12px`)};
    z-index: 10;
    flex-direction: column;
    overflow: hidden;

    @media only screen and (min-width: 960px) {
        display: none;
    }

    .background {
        width: 100%;
        height: 100%;
        background: ${({ $isExpanded }) => ($isExpanded ? `linear-gradient(180deg, rgba(0,0,0,1) 5%, transparent 95%)` : `#000000`)};
        backdrop-filter: blur(17px);
        filter:  ${({ $isExpanded }) => ($isExpanded ? `brightness(35%)` : `none`)};
        transition: all 0.3s ease-in-out;
        position: absolute;
        z-index: 11;
        top: 0px;
        left: 0px;
        border-radius: inherit;

        @media only screen and (min-width: 960px) {
            background: #061510;
            filter: none;
        }
    }
`

export const UserPointsContainer = styled.div<{ $isExpanded: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .total-points {
        display: flex;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.7);
        font-size: ${({ $isExpanded }) => ($isExpanded ? `0.875rem` : `0.625rem`)};
        transition: all 0.1s ease-in-out;
        z-index: 12;

        @media only screen and (min-width: 960px) {
            font-size: 0.625rem;
        }

        .total-points-number {
            color: var(--green-neon);
            font-size: ${({ $isExpanded }) => ($isExpanded ? `1.313rem` : `1rem`)};
            transition: all 0.1s ease-in-out;
            
            @media only screen and (min-width: 960px) {
                font-size: 1rem;
            }
        }
    }
`

export const ToggleUserPoints = styled.button`
    width: 24px;
    height: 24px;
    background-color: var(--green-neon);
    position: absolute;
    top: 9px;
    right: 12px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;

    @media only screen and (min-width: 960px) {
        top: 5px;
    }
`

export const ToggleUserPointsArrow = styled.div<{ $isToggled: boolean }>`
    transform-style: preserve-3d; 
    transition: transform 0.3s ease;
    transform: rotate(${({ $isToggled }) => ($isToggled ? `180deg` : `0deg`)});
    width: 12px;
    height: 12px;
    img {
        width: 100%;
    }
`

export const UserPointsButtons = styled.div<{ $isVisible: boolean }>`
    display: ${({ $isVisible }) => ($isVisible ? `flex` : `none`)};
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 12px;
    z-index: 12;
    width: 100%;
    padding-right: 4px;

    @media only screen and (min-width: 960px) {
        display: none;
    }
    
    button {
        border-radius: 18px;
        padding: 10px 13px;
        font-size: 0.75rem;
        font-family: var(--font-bold);
        white-space: nowrap;
        @media only screen and (max-width: 380px) {
            font-size: 0.6rem;
        }
    }
    .button-green {
        background-color: var(--green-neon);
        color: #2D473E;
    }
    .button-outlined {
        color: var(--white);
        background-color: transparent;
        border: 1px solid var(--green-neon);
    }
`
