import { ReactElement } from "react"
import bronzeIcon from '../../assets/badges-cargill/bronze.png'
import goldAllianceIcon from '../../assets/badges-cargill/gold-alliance.png'
import goldIcon from '../../assets/badges-cargill/gold.png'
import ironIcon from '../../assets/badges-cargill/iron.png'
import silverIcon from '../../assets/badges-cargill/silver.png'
import { HowItWorkTableProps } from "./props"
import * as S from './styled'

function HowItWorkTable ({ children, hasWarning }: HowItWorkTableProps): ReactElement {
  return <S.Container>
    <S.TableScroll>
      <S.Table>
        <thead>
          <tr className="no-border">
            <td>&nbsp;</td>
            <S.ImgContainer>
              <S.Aba style={{ backgroundColor: '#A6918780' }}>
                <img src={ironIcon} alt="Iron Group" />
              </S.Aba>
            </S.ImgContainer>
            <S.ImgContainer>
              <S.Aba style={{ backgroundColor: '#FB8D4380' }}>
                <img src={bronzeIcon} alt="Bronze Group" />
              </S.Aba>
            </S.ImgContainer>
            <S.ImgContainer>
              <S.Aba style={{ backgroundColor: '#C4C8C7' }}>
                <img src={silverIcon} alt="Silver Group" />
              </S.Aba>
            </S.ImgContainer>
            <S.ImgContainer>
              <S.Aba style={{ backgroundColor: '#FDDB9E' }}>
                <img src={goldIcon} alt="Gold Group" />
              </S.Aba>
            </S.ImgContainer>
            <S.ImgContainer>
              <S.Aba style={{ backgroundColor: '#7FC1A7' }}>
                <img src={goldAllianceIcon} alt="Gold Alliance Group" />
              </S.Aba>
            </S.ImgContainer>
          </tr>
          <tr>
            <td>Gatilho de entrada</td>
            <td style={{ color: '#956F24' }}>Iron</td>
            <td style={{ color: '#f38625' }}>Bronze</td>
            <td style={{ color: '#747d79' }}>Silver</td>
            <td style={{ color: '#f2a407' }}>Gold</td>
            <td style={{ color: '#f2a407' }}>Gold <span style={{ color: '#008350' }}>Alliance</span></td>
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </S.Table>
    </S.TableScroll>
    {hasWarning && <center><p className="warning"><span className="color-red">*</span>somente adsorvente.</p></center>}
  </S.Container>
}

export default HowItWorkTable
