import { useCallback, useEffect, useState } from 'react'
import invisivel from './assets/olho.png'
import visivel from './assets/visivel.png'
import * as S from './style'

interface InputFloatingLabelProps {
    inputType: string
    inputTitle: string
    onValueChange?: (value: string) => void
    mask?: (value: string) => string
    inputCleared?: boolean
    value?: string | ((value: string) => string)
    disabled?: boolean
    maxLength?: number
}

function InputFloatingLabel ({ inputType, inputTitle, onValueChange, mask, inputCleared, value, disabled, maxLength }: InputFloatingLabelProps) {
    const [inputIsActive, setInputIsActive] = useState<boolean>(false)
    const [internalValue, setInternalValue] = useState<string>('')
    const [currentInputType, setCurrentInputType] = useState<string>('')

    const handleInputActive = useCallback(() => {
        if (internalValue === '') return setInputIsActive(false)
    }, [internalValue])

    const handleValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value
        if (mask !== null && mask !== undefined) value = mask(value)
        if (onValueChange !== null && onValueChange !== undefined) onValueChange(value)
        setInternalValue(value)
    }, [mask, onValueChange])


    const handleFocus = useCallback(() => {
        setInputIsActive(true)
    }, [])

    const togglePassword = useCallback(() => {
        setCurrentInputType(type => {
            if (type === 'password') {
                return 'text'
            }
            return 'password'
        })
    }, [])

    useEffect(() => {
        if (inputCleared) setInternalValue('')
        if (value) {
            setInternalValue(value)
            setInputIsActive(true)
        }
    }, [value, inputCleared])

    useEffect(() => {
        setCurrentInputType(inputType)
    }, [inputType])

    return <S.InputFloatingLabel $isActive={disabled ? true : inputIsActive} $isDisabled={disabled || false}>
        <label>{inputTitle}</label>
        <input
            type={currentInputType}
            onFocus={handleFocus}
            onBlur={handleInputActive}
            onChange={handleValueChange}
            autoComplete="new-password"
            value={internalValue || ''}
            disabled={disabled}
            max={maxLength || 9999}
            maxLength={maxLength || 9999}
        />
        {inputType === 'password' && <S.EyeButton onClick={togglePassword}>
            <img src={currentInputType === "password" ? invisivel : visivel} alt="" />
        </S.EyeButton>}
    </S.InputFloatingLabel>
}

export default InputFloatingLabel