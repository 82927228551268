import { useCallback, useEffect, useRef, useState } from 'react'
import * as S from './style'

interface TextAreaFloatingLabelProps {
    textAreaTitle: string
    value?: string
    onValueChange?: (value: string) => void
}
function TextAreaFloatingLabel ({ textAreaTitle, onValueChange, value }: TextAreaFloatingLabelProps) {
    const [textAreaIsActive, setTextAreaIsActive] = useState<boolean>(false)
    const [internalValue, setInternalValue] = useState<string>('')
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const handleTextAreaActive = useCallback(() => {
        const textAreaLength = textAreaRef.current?.value.length || 0
        if (!textAreaLength) return setTextAreaIsActive(false)
    }, [])

    const handleValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = e.target.value
        if (onValueChange !== null && onValueChange !== undefined) onValueChange(value)
        setInternalValue(value)
    }, [onValueChange])

    useEffect(() => {
        if (!value) return
        setInternalValue(value)
    }, [value])

    return <S.TextAreaFloatingLabel $isActive={textAreaIsActive} >
        <h3>{textAreaTitle}</h3>
        <textarea
            onFocus={() => setTextAreaIsActive(true)}
            onBlur={handleTextAreaActive}
            ref={textAreaRef}
            onChange={handleValueChange}
            value={internalValue || ''}
        />
    </S.TextAreaFloatingLabel>
}

export default TextAreaFloatingLabel