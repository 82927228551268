import styled from 'styled-components';

export const SectionSample = styled.section`
    width: 100%;
    margin: 0 auto;
    color: #fff;

    @media only screen and (min-width: 960px) {
        margin-top: 32px;
    }
`;

