import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DesktopBanner from '../../components/DesktopBanner'
import FooterComplete from '../../components/FooterComplete'
import Header from '../../components/Header'
import ReturnPageButton from '../../components/Header/ReturnPageButton'
import InputFloatingLabel from '../../components/InputFloatingLabel'
import Loader from '../../components/Loader'
import LoaderWithBackdrop from '../../components/LoaderWithBackdrop'
import { RepublicaFetchAddress } from '../../data/use-case/RepublicaFetchAddress'
import api from '../../services/api'
import { MaskCEP } from '../../utils/mask'
import * as S from './style'

enum State {
    LOADING,
    READY,
    SENDING,
    SUCCESS,
    ERROR
}

interface NewAddressAttributes {
    currentState: State,
    data: {
        cep: string
        logradouro: string
        numero: string
        complemento: string
        bairro: string
        cidade: string
        estado: string
    }
}

const fetchAddress = new RepublicaFetchAddress()

function NewAddress () {
    const [state, setState] = useState<NewAddressAttributes>({
        currentState: State.READY,
        data: {
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: ''
        }
    })
    const [loading, setLoading] = useState<boolean>(false)
    const handleNewAddressRequest = useCallback(async () => {
        try {
            setState((state) => ({ ...state, currentState: State.SENDING }))
            await api.put('/address/update', state.data)
            toast.success('Seu endereço foi atualizado com sucesso', { theme: 'colored' })
        } catch (err) {
            toast.error('Ocorreu um erro inesperado', { theme: 'colored' })
        }
        setState((state) => ({ ...state, currentState: State.READY }))
    }, [state])

    const handleChangeCEP = useCallback(async (cep: string) => {
        setState((state) => ({ ...state, data: { ...state.data, cep: cep } }))

        if (cep.length === 9) {
            setLoading(true)
            try {
                const response = await fetchAddress.handle(cep)
                setState((state) => ({
                    ...state,
                    data: {
                        ...state.data,
                        bairro: response.bairro,
                        cidade: response.cidade,
                        estado: response.uf,
                        logradouro: response.logradouro
                    }
                }))
            } catch (error) {
                console.error(error)
            }
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        (async () => {
            setState((state) => ({
                ...state,
                currentState: State.LOADING
            }))
            const response = await api.get(`/me`)
            setState({
                currentState: State.READY,
                data: {
                    cep: response.data.address.cep,
                    logradouro: response.data.address.logradouro,
                    numero: response.data.address.numero,
                    complemento: response.data.address.complemento,
                    bairro: response.data.address.bairro,
                    cidade: response.data.address.cidade,
                    estado: response.data.address.estado
                }
            })
        })()
    }, [])

    if (state.currentState === State.LOADING) return <Loader />

    return <S.NewAddressContent>
        <Header element={<ReturnPageButton />} logo={false} pageTitle='Cadastrar endereço' borderBottom showDesktopWidgets />
        <DesktopBanner title='Cadastrar endereço' />
        <S.NewAddress>
            <InputFloatingLabel value={state.data.cep} inputTitle='CEP' inputType='text' onValueChange={handleChangeCEP} mask={MaskCEP} />
            <InputFloatingLabel value={state.data.logradouro} inputTitle='Rua' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, logradouro: value } })} />
            <S.NewAddressBlock>
                <InputFloatingLabel value={state.data.numero} inputTitle='Número' inputType='number' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, numero: value } })} />
                <InputFloatingLabel value={state.data.complemento} inputTitle='Complemento' inputType='text' maxLength={20} onValueChange={(value: string) => setState({ ...state, data: { ...state.data, complemento: value } })} />
            </S.NewAddressBlock>
            <InputFloatingLabel value={state.data.bairro} inputTitle='Bairro' inputType='text' maxLength={20} onValueChange={(value: string) => setState({ ...state, data: { ...state.data, bairro: value } })} />
            <InputFloatingLabel value={state.data.cidade} inputTitle='Cidade' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, cidade: value } })} />
            <InputFloatingLabel value={state.data.estado} inputTitle='Estado' inputType='text' onValueChange={(value: string) => setState({ ...state, data: { ...state.data, estado: value } })} />
            <S.NewAddressButton onClick={handleNewAddressRequest} disabled={state.currentState === State.SENDING}>{state.currentState === State.SENDING ? 'Enviando' : 'Enviar'}</S.NewAddressButton>
        </S.NewAddress>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
        {loading && <LoaderWithBackdrop />}
    </S.NewAddressContent>
}

export default NewAddress