import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import grayArrow from '../../assets/gray-arrow-icon.png'
import DesktopBanner from '../../components/DesktopBanner'
import FooterComplete from '../../components/FooterComplete'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import * as S from './style'

const faqs = [
    {
        question: 'O que é a programa Open Farm® Cargill?',
        answer: <>
            O Open Farm® é o programa de relacionamento da Cargill que vem com um dos nossos principais objetivos como empresa, colocar o cliente no centro. O Open Farm, visa atender a real necessidade do cliente e estar presente em todos os momentos. Queremos que através do Programa o cliente reinvista em seu negócio. Acreditamos na relação de parceria e transparência entre as empresas e o Open Farm veio para atender com excelência os nossos parceiros.
        </>
    },
    {
        question: 'Como efetivar a participação no Programa?',
        answer: <>
            Para que a participação seja considerada válida, o participante deverá informar seus dados cadastrais incluindo endereço eletrônico (e-mail) e celular no site www.openfarmcargill.com.br em área específica para cadastro
        </>
    },
    {
        question: 'Como atualizar meus dados?',
        answer: <>
            Para atualizar seus dados, entre em “Menu” e depois “Meus Dados”. Na tela, você pode atualizar os seus dados pessoais e dados de suas empresas.
        </>
    },
    {
        question: 'Como realizar transferência entre contas?',
        answer: <>
            Para transferir pontos, entre em “Transfira seus pontos”, junto à sua pontuação na página principal. <br />
            Será permitido ao participante transferir pontos da “Conta Benefícios” para as “contas de reinvestimento” atreladas ao participante. Em hipótese alguma será permitido transferir pontos entre “contas de reinvestimento”. <br />
            A transferência vai seguir uma restrição de origem de pontos.
        </>
    },
    {
        question: 'Como funcionam as categorias?',
        answer: <>
            As empresas participantes do Programa serão categorizadas de acordo com o volume de produtos de nutrição animal da Cargill utilizados no negócio.<br /><br />
            A categoria pode ser alterada a cada 3 meses, de acordo com as compras realizadas durante o período.
        </>
    },
    {
        question: 'Qual validade dos pontos?',
        answer: <>
            Os pontos serão válidos por 12 meses.
        </>
    },
    {
        question: 'Como resgatar meus pontos?',
        answer: <>
            Você pode utilizar seus pontos de 3 formas:<br /><br />
            1)    Resgatando produtos para a sua produção no Catálogo de Reinvestimento, que contempla produtos e serviços específicos para o negócio. Estes catálogos contemplam ao menos 75% dos pontos creditados recorrentemente para o participante, sendo possível ainda receber pontos do “Catálogo de Benefícios” para um “Catálogo de Reinvestimento”.<br /><br />
            2)    Resgatando produtos para você no Catálogo de Benefícios, que contempla produtos e serviços voltados para o empresário fora do negócio. Estes catálogos contemplam no máximo 25% dos pontos creditados recorrentemente para o participante, sendo possível ainda enviar pontos deste “Catálogo de Benefícios” para um “Catálogo de Reinvestimento”.<br /><br />
            3)    Resgatando produtos que não estão nos catálogos por meio do Gold Box. Esse serviço é valido somente para os clientes Gold e Gold Alliance.
        </>
    },
    {
        question: 'Como saber meu saldo de pontos?',
        answer: <>
            O saldo de pontos poderá ser verificado das seguintes formas:<br /><br />
            1)    No topo da página principal, ao lado do seu nome, você confere o saldo total, saldo do catálogo de reinvestimento e o saldo do catálogo de benefícios.<br /><br />
            2)    Dentro de cada catálogo, você confere o saldo ainda mais detalhado pelo extrato.<br /><br />
        </>
    },
    {
        question: 'Problemas com Resgate/Pontuação?',
        answer: <>
            Entre em contato conosco pelo e-mail contato@openfarmcargill.com.br.
        </>
    },
    {
        question: 'Como meus dados pessoais são tratados?',
        answer: <>
            As informações coletadas pela Cargill são tratadas como confidenciais e somente serão utilizadas da forma descrita na Política de Privacidade da Cargill
        </>
    },
    {
        question: 'Produto com defeito?',
        answer: <>
            Para avaliarmos melhor o caso, é necessário que entre em contato com a nossa central de atendimento: contato@openfarmcargill.com.br
        </>
    }
]

function Faq () {
    const [faqShown, setFaqShown] = useState<number | null>(null)
    const navigate = useNavigate()

    const toggleFAQ = useCallback((index: number) => {
        setFaqShown(faqShown === index ? null : index);
    }, [faqShown])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <S.FaqContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Perguntas frequentes' showDesktopWidgets borderBottom />
        <DesktopBanner title='Perguntas frequentes' />
        <S.Faq>
            <h2>
                Tem alguma dúvida? Confira as perguntas frequentes.
            </h2>
            {faqs.map((faq, index) => (
                <S.FAQItem key={index}>
                    <S.FAQItemQuestion onClick={() => toggleFAQ(index)} $isToggled={faqShown === index}>
                        <p>{faq.question}</p>
                        <img src={grayArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                    </S.FAQItemQuestion>
                    <S.FAQItemAnswer $isShown={faqShown === index}>
                        {faq.answer}
                    </S.FAQItemAnswer>
                </S.FAQItem>
            ))}
        </S.Faq>
        <S.FaqBottomSection>
            <div>
                <h2>Não achou o que procurava?</h2>
                <p>A gente te ajuda a resolver</p>
            </div>
            <S.FaqButton onClick={() => navigate('/fale-conosco-logged')}>
                Fale conosco
            </S.FaqButton>
        </S.FaqBottomSection>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
    </S.FaqContent>
}

export default Faq