import { CreateNewPost } from "../../domain/use-case/CreateNewPost";
import api from "../../services/api";

export class RemoteCreateNewPost implements CreateNewPost {
  async handle (params: CreateNewPost.Params): Promise<void> {
    const formData = new FormData()
    if (params.image) formData.append('image', params.image)
    if (params.videoUrl) formData.append('videoUrl', params.videoUrl)
    formData.append('content', params.content)

    await api.post('/posts', formData)
  }
}