import styled, { keyframes } from 'styled-components'

export const GoogleFormsModalBackdrop = styled.div<{ $isModalOpen: boolean }>`
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 49;
    background-color: rgba(12, 43, 33, 0.5);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0px;
    display: ${({ $isModalOpen }) => ($isModalOpen ? 'flex' : 'none')};
    animation: ${({ $isModalOpen }) => ($isModalOpen ? fadeInAnimation : fadeOutAnimation)} 0.3s ease forwards;
`

export const GoogleFormsModal = styled.div`
    position: absolute;
    top: calc(50vh - 285px/2);
    left: calc(50% - (530px/2));
    transition: all 0.3s ease-in-out;
    display: flex;
    width: 530px;
    z-index: 50;
    max-width: 95%;

    @media only screen and (max-width: 600px) {
        left: 5px;
    }

    a {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            border-radius: 2px;
        }
    }

    .close-button {
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 4px;
        background-color: var(--primary);
        width: 28px;
        height: 28px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @media only screen and (max-width: 600px) {
            width: 22px;
            height: 22px;
        }

        &:hover {
            background-color: var(--green-neon);
            transition: all 0.3s ease-in-out;
        }

        img {
            width: 60%;
            
        }
    }
`

const fadeInAnimation = keyframes`
    from {
        display: none;
        opacity: 0;
    }
    to {
        display: flex;
        opacity: 1;
    }
`

const fadeOutAnimation = keyframes`
    from {
        display: flex;
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
`
