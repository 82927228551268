import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.header<{ $hasBorderBottom?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 36px;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--black);
    border-bottom: ${({ $hasBorderBottom }) => ($hasBorderBottom ? '2px solid #0000001A' : 'none')};
    z-index: 40;
    max-height: 70px;

    @media only screen and (min-width: 960px) {
        padding: 0px 0px 0px 32px;
        border-bottom: ${({ $hasBorderBottom }) => ($hasBorderBottom ? '2px solid rgba(0, 0, 0, 0.2)' : 'none')};
    }

    >a {
        max-height: 40px;
        margin: 15px 0px;
    }
    >a img {
        width: 84.1px;
    }

    >h2 {
        font-family: var(--font-bold);
        color: var(--white);
        font-size: 1.5rem;
        text-align: center;

        @media only screen and (min-width: 960px) {
            display: none;
        }
    }
`;

export const DesktopLogo = styled(Link)`
    max-height: 40px;
    margin: 15px 0px;

    @media only screen and (max-width: 960px) {
       display: none;
    }

    img {
        width: 84.1px;
    }
`

export const HeaderElement = styled.div`
    background: none;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media only screen and (min-width: 960px) {
       display: none;
    }

    img {
        width: 100%;
    }
`;

export const UserPointsDesktopWidget = styled.div`    
    margin-left: auto;
         
    @media only screen and (max-width: 960px) {
       display: none;
    }
`;

export const DesktopNav = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;
    gap: 64px;
    margin-left: 118px;

    @media only screen and (max-width: 960px) {
       display: none;
    }

    .dropdown-trigger, a, button {
        text-decoration: none;
        font-size: 1rem;
        font-family: var(--font-bold);
        color: #EBF3EF;
    }

    .dropdown-title {
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        gap: 12px;

        img {
            width: 10px;
        }
    }

    .dropdown-content {
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: #061510;
        color: #EBF3EF;
        top: 0px;
        padding: 64px 12px 12px 12px;
        border-radius: 10px;
        left: -12px;
        top: -12px;
        gap: 20px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

        button {
            background-color: transparent;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
    }

    .dropdown-trigger {
        cursor: default;
        position: relative;

        &:hover .dropdown-content {
            display: flex;
        }
    }
`

export const SelectSpeciesModalContentButtonImage = styled.div`
    width: 24px;
    height: 24px;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green-neon);
    margin-right: 9px;

    img {
        max-width: 21px;
        max-height: 13px;
    }
`