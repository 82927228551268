import styled from 'styled-components';

export const BackButton = styled.button`
    background: none;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 36px;
    img {
        width: 100%;
    }
`;