import styled from 'styled-components';

export const WalletContent = styled.section`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: var(--black);
`;

export const Wallet = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px;
    margin-top: 70px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;

    h2 {
        color: var(--black);
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 24px;
    }
    div {
        font-size: 1.313rem;
        line-height: 1.5rem;
        width: 100%;
        border-radius: 28px;
        padding: 15px;
        margin-bottom: 12px;
        background-color: #EBF3EF;
        text-align: center;

        span {
            font-size: 1.313rem;
            line-height: 1.5rem;
            color: var(--primary);
            font-family: var(--font-bold);
        }
    }
    button {
        background-color: var(--primary);
        padding: 16px;
        width: 100%;
        font-size: 1.125rem;
        font-family: var(--font-bold);
        text-align: center;
        color: var(--white);
        border-radius: 28px;
    }
`;

export const WalletBottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 78px 36px 100px 36px;
    background-color: #EBF3EF;
    margin-top: -32px;
    position: relative;
    z-index: 2;

    @media only screen and (min-width: 900px) {
        border-radius: 0 0 30px 30px;
    }

    h2 {
        color: var(--black);
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-family: var(--font-bold);
        padding-bottom: 24px;
        border-bottom: 1px solid #88A8A4;
    }
    >div {
        width: 100%;
        margin-bottom: 32px;
    }
`;

export const WalletItem = styled.div`
    width: 100%;
    border-bottom: 1px solid #88A8A4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
`

export const WalletItemHeader = styled.div<{ $isToggled: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom:${({ $isToggled }) => ($isToggled ? `12px` : `24px`)};

    p {
        color: var(--black);
        font-size: 1rem;
        line-height: 0.875rem;
        font-family: var(--font-bold);
        max-width: 90%;
        letter-spacing: -0.4px;
    }
    img {
        width: 14px;
        height: 14px;
        transition: transform 0.3s ease;
        transform: rotateX(${({ $isToggled }) => ($isToggled ? `180deg` : `0deg`)});
    }

`

export const WalletItemContent = styled.div<{ $isShown: boolean }>`
    width: 100%;
    color: var(--black);
    font-size: 0.875rem;
    line-height: 1rem;
    transition: all 0.3s ease;
    display: ${({ $isShown }) => ($isShown ? `flex` : `none`)};
    overflow: hidden;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 24px;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: #88A8A4;
            font-size: 1rem;
            line-height: 1.125rem;
        }
        span {
            color: var(--primary);
            font-size: 1rem;
            line-height: 1.125rem;
            font-family: var(--font-bold);
        }
    }
`

export const WalletButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: transparent;
    color: var(--primary);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    border: 2px solid var(--primary);
    margin-bottom: 10px;
`;
