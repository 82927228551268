import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const SideMenuBackdrop = styled.div<{ $isSideMenuOpen: string }>`
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 49;
    background-color: rgba(12, 43, 33, 0.5);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0px;
    display: ${({ $isSideMenuOpen }) => ($isSideMenuOpen === 'true' ? 'flex' : 'none')};
    transition: all 0.3s ease-in-out;
    animation: ${({ $isSideMenuOpen }) => ($isSideMenuOpen === 'true' ? slideInAnimation : slideOutAnimation)} 0.3s ease forwards;
`;

export const SideMenu = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 50;
    max-width: 500px;
    overflow: auto;
`;

export const SideMenuHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 36px 0px 36px;
    width: 100%;
`;

export const SideMenuUserName = styled.div`
    font-family: var(--font-bold);
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    padding: 0px 36px 0px 36px;
    gap: 1px;
    width: 100%;

    span {
        font-size: 1.313rem;
        color: var(--white);
        margin-bottom: 33px;
    }
`;

export const SideMenuProfilePic = styled.div`
    width: 104px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--green-neon);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 48.75px;
    }
`;

export const SideMenuNavigation = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 30px 30px 0px 0px;
    background-color: #2D473E;
    padding-top: 62px;
    margin-top: -52px;

    ul {
        display: flex;
        flex-direction: column;
    }
`;

export const SideMenuNavigationLinkOption = styled(NavLink) <{ $isDarkBg?: boolean }>`
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 1.125rem;
    color: var(--gray-bg);
    width: 100%;
    border-top: 2px solid #0000001A;
    padding: 16px 36px;
    background-color: ${({ $isDarkBg }) => ($isDarkBg ? '#243932' : '#2D473E')};
`;

export const SideMenuNavigationLinkOptionAnchor = styled.a <{ $isDarkBg?: boolean }>`
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 1.125rem;
    color: var(--gray-bg);
    width: 100%;
    border-top: 2px solid #0000001A;
    padding: 16px 36px;
    background-color: ${({ $isDarkBg }) => ($isDarkBg ? '#243932' : '#2D473E')};
`

export const SideMenuNavigationOption = styled.div <{ $isDarkBg?: boolean }>`
    text-decoration: none;
    font-family: var(--font-bold);
    font-size: 1.125rem;
    color: var(--gray-bg);
    width: 100%;
    border-top: 2px solid #0000001A;
    padding: 16px 36px;
    background-color: ${({ $isDarkBg }) => ($isDarkBg ? '#243932' : '#2D473E')};
`;

const slideInAnimation = keyframes`
    from {
        display: none;
        transform: translateX(-100%);
    }
    to {
        display: flex;
        transform: translateX(0);
    }
`;

const slideOutAnimation = keyframes`
    from {
        display: flex;
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
        display: none;
    }
`;