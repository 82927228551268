import styled from 'styled-components';

export const UserCategoryHubItemExtended = styled.div`
    width: 100%;
    background-color: var(--white);
    padding: 21px;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    p {
        color: var(--black);
        font-family: var(--font-bold);
        font-size: 0.75rem;
        margin-top: 4px;
    }
`;

export const UserCategoryHubItemExtendedHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #88A8A4;
    font-family: var(--font-bold);
    font-size: 0.875rem;
    margin-bottom: 6px;
    padding-left: 12px;
`;

export const UserCategoryHubItemExtendedBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 100%;

    img {
        width: 88px;
    }
`;

export const UserCategoryHubItemExtendedKPIs = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    height: 100%;
`;

export const UserCategoryHubItemExtendedProgress = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
`;

export const UserCategoryHubItemExtendedProgressInfos = styled.div<{ color: string }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: ${props => props.color};
    font-family: var(--font-bold);

    p {
        font-family: 'Gilroy-Medium',sans-serif;
        color: #2D473E;
        font-size: 0.875rem;
    }

    span {
        font-size: 0.875rem;
        color: var(--black);
    }
`;

export const UserCategoryHubItemExtendedProgressBar = styled.div<{ $progress: number, color: string }>`
    width: 100%;
    position: relative;
    background-color: var(--gray-bg);
    border-radius: 8px;
    height: 12px;
    &:after {
        content: '';
        width: ${({ $progress }) => ($progress + '%')};
        height: 100%;
        background-color: ${({ color }) => color};
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: inherit;
    }
`;
