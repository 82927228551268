import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import greenArrow from '../../assets/green-arrow-icon.png'
import DesktopBanner from '../../components/DesktopBanner'
import FooterComplete from '../../components/FooterComplete'
import Header from '../../components/Header'
import MenuButton from '../../components/Header/MenuButton'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import * as S from './style'

interface UserAttibutes {
    id: string
    createdAt?: Date
    createdId?: Date
    updatedAt?: Date
    updatedId?: Date
    deletedId?: Date
    deletedAt?: Date
    avatarId?: number
    channelId: number
    name: string
    email: string
    document: string
    birthday: string
    cargo: string
    cellphone: string
    matricula?: unknown
    cdgHolding?: unknown
    passwordHash: string
    active: boolean
    activationToken: string
    approved: boolean
    approvedAt?: Date
    approvedBy?: unknown
    recoveryToken?: unknown
    acceptInfo?: unknown
    currentExp: number
    currentLevel: number
    purchasedCoins: number
    earnedCoins: number
    googleSub?: unknown
    cpfRespLegal?: string
    channel: {
        id: number
        createdAt: Date
        createdId?: unknown
        updatedAt?: Date
        updatedId?: unknown
        deletedId?: unknown
        deletedAt?: Date
        orgId: number
        name: string
        regulationId: number
        teamId: number
        userId?: unknown
    }
    role: {
        id: number
        name: string
        created_at: Date
        updated_at?: Date
        deleted_at?: Date
        distributor: number
    }
    address?: {
        id?: string
        createdAt: string
        createdBy: string
        updatedAt: string
        deletedAt: string
        userId: string
        cep: string
        bairro: string
        cidade: string
        estado: string
        logradouro: string
        numero: string
        referencia: string
        complemento: string
    }
}

function MyInfos () {
    const navigate = useNavigate()
    const [userData, setUserData] = useState<UserAttibutes | null>(null)
    const { userCan } = useAuth()

    const getUserData = useCallback(async () => {
        const data = (await api.get('/me')).data
        setUserData(data)
    }, [])

    const maskDocument = useCallback((document: string) => {
        const maskedCpf = document.substring(0, 7).replace(/\d/g, '●') + document.substring(7)
        return maskedCpf
    }, [])

    const handleDate = useCallback((date: string) => {
        const nDate = new Date(date)
        nDate.setHours(nDate.getHours() + 3)
        return nDate.toLocaleDateString('pt-br')
    }, [])

    useEffect(() => {
        getUserData()
    }, [getUserData])

    return <S.MyInfosContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Meus dados' borderBottom showDesktopWidgets />
        <DesktopBanner title='Meus dados' />
        <S.MyInfos>
            <S.MyInfosBlock>
                <h2>Dados de cadastro</h2>
                <S.MyInfosBlockContent>
                    <S.MyInfosUserName>{userData?.name || ''}</S.MyInfosUserName>
                    {userCan('update:company') && 
                        <S.MyInfosBlockContentItem>
                            CPF do resp. legal
                            <div>{maskDocument(userData?.cpfRespLegal || '')}</div>
                        </S.MyInfosBlockContentItem>
                    }
                    {!userCan('update:company') &&
                        <S.MyInfosBlockContentItem>
                            CPF
                            <div>{maskDocument(userData?.document || '')}</div>
                        </S.MyInfosBlockContentItem>
                    }
                    <S.MyInfosBlockContentItem>
                        Data de nascimento
                        <div>{handleDate(userData?.birthday || '')}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Número de Celular
                        <div>{userData?.cellphone || ''}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        E-mail
                        <div>{userData?.email || ''}</div>
                    </S.MyInfosBlockContentItem>
                </S.MyInfosBlockContent>
                <S.MyInfosBlockLink onClick={() => navigate('/atualizar-perfil')}>
                    Alterar dados pessoais
                    <img src={greenArrow} alt="ícone de seta para a direita, indicando uma opção que pode ser clicada." />
                </S.MyInfosBlockLink>
                <S.MyInfosBlockLink onClick={() => navigate('/nova-senha')}>
                    Alterar senha
                    <img src={greenArrow} alt="ícone de seta para a direita, indicando uma opção que pode ser clicada." />
                </S.MyInfosBlockLink>
                {userCan('read:filiais') && <S.MyInfosBlockLink onClick={() => navigate('/filiais')}>
                    Minhas filiais
                    <img src={greenArrow} alt="ícone de seta para a direita, indicando uma opção que pode ser clicada." />
                </S.MyInfosBlockLink>}
            </S.MyInfosBlock>
            <S.MyInfosBlock>
                <h2>Endereço</h2>
                <S.MyInfosBlockContent>
                    <S.MyInfosBlockContentItem>
                        CEP
                        <div>{userData?.address?.cep || ''}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Estado
                        <div>{userData?.address?.estado}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Cidade
                        <div>{userData?.address?.cidade}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Bairro
                        <div>{userData?.address?.bairro}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Logradouro
                        <div>{userData?.address?.logradouro}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Numero
                        <div>{userData?.address?.numero}</div>
                    </S.MyInfosBlockContentItem>
                    <S.MyInfosBlockContentItem>
                        Complemento
                        <div>{userData?.address?.complemento}</div>
                    </S.MyInfosBlockContentItem>
                </S.MyInfosBlockContent>
                <S.MyInfosBlockLink onClick={() => navigate('/novo-endereco')} >
                    Atualizar endereço
                    <img src={greenArrow} alt="ícone de seta para a direita, indicando uma opção que pode ser clicada." />
                </S.MyInfosBlockLink>
            </S.MyInfosBlock>
        </S.MyInfos>
        <S.FooterCompleteContainer>
            <FooterComplete />
        </S.FooterCompleteContainer>
    </S.MyInfosContent>
}

export default MyInfos