import styled from 'styled-components';

export const PasswordChangeRequestModalContent = styled.div`
    width: 100%;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 46px;
    }
`;

export const PasswordChangeRequestModalContentButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    button {
        width: 100%;
        font-size: 1.125rem;
        padding: 17px;
        font-family: var(--font-bold);
        border-radius: 28px;
    }
    .button-outlined {
        background-color: transparent;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
`;