import Loader from "../../components/Loader"
import { useSpecies } from "../../contexts/SpeciesContext"
import HowItWorks from "../HowItWorks"
import HowItWorksNew from "../HowItWorksNew"

function HowItWorksSwitcher() {
  const { specieSelected } = useSpecies()

  if (!specieSelected) return <Loader />

    if (specieSelected.catalog === 81543) return <HowItWorksNew />
    if (specieSelected.catalog === 81541) return <HowItWorksNew />
    if (specieSelected.catalog === 81526) return <HowItWorksNew />
    if (specieSelected.catalog === 81542) return <HowItWorksNew />
    return <HowItWorks />
  
}

export default HowItWorksSwitcher