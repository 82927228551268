import { styled } from "styled-components";

export const Container = styled.div`
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
  padding-bottom: 28px;
  max-width: 1290px;
  margin: 0 auto;
  border-radius: 16px;

  &:before {
    background-color: #FFFFFF;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 10px;
    z-index: 2;
    border-radius: 16px 0 0 16px;
  }

  .warning {
    color: #0C2B21;
    font-size: 0.875rem;
    line-height: 1rem;
  }
  
  .color-red {
      color: #FF6F64;
  }

`

export const TableScroll = styled.div`
  overflow-x: scroll;
  overflow-y: visible;
  width: 100%;
  border-radius: 16px;

    @media only screen and (min-width: 900px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const ImgContainer = styled.td`
  img {
    aspect-ratio: 1;
    bottom: 0;
    margin-bottom: 0 !important;
    position: absolute;
    transform: translateY(50%);
    width: 60px !important;
  }
`

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 10px 0px;
  width: max-content;
  margin: 0 auto;

  @media only screen and (min-width: 960px) {
    width: auto;
    border-spacing: 60px 0px;
  }

  .no-border {
    td {
      border: 0 none;
    }
  }

  .rebate {
    td {
      padding: 0;
    }
  }
  
  td, th {
    background-color: #FFFFFF;
    border-bottom: 2px dotted #c5d4d3;
    border-left: 0 none;
    border-right: 0 none;
    border-top: 0 none;
    color: #11281F;
    font-family: var(--font-bold);
    font-size: 24px;
    padding: 6px 10px 10px 10px;
    position: relative;
    text-align: center;
    width: 154px;
    z-index: 1;

    &.break-words {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &:first-child {
      border-bottom: 0 none;
      font-size: 14px;
      left: 10px;
      position: sticky;
      width: 97px;
      z-index: 3;
    }

    &:last-child {
      color: #008350;
    }

    hr {
      border-bottom: 3px dotted #c5d4d3;
    }
  }

  th {
    color: #88A8A4;
  }
`

export const Aba = styled.div`
  align-items: center;
  height: 63px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  border-radius: 0 0 20px 20px;
`