import { ReactNode } from 'react'
import { useBottomModal } from '../../contexts/BottomModalContext'
import { useClickOutside } from '../../hooks/useClickOutside'
import * as S from './style'

interface BottomModalProps {
    children: ReactNode
}

function BottomModal({ children }: BottomModalProps) {
    const { isBottomModalOpen, closeBottomModal } = useBottomModal()
    const [wrapperRef] = useClickOutside(closeBottomModal)
    
    return (
        <S.BottomModalBackdrop $isBottomModalOpen={isBottomModalOpen}>
            <S.BottomModal ref={wrapperRef}>
                {children}
            </S.BottomModal>
        </S.BottomModalBackdrop>
    )
}

export default BottomModal