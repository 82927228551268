import { useBottomModal } from '../../contexts/BottomModalContext'
import { maskPoints } from '../../utils/mask'
import * as S from './style'


interface TransferSuccessModalContentProps {
    pointsTransfered: number
}
function TransferSuccessModalContent({ pointsTransfered }:TransferSuccessModalContentProps) {
    const { closeBottomModal } = useBottomModal()
    
    return <S.TransferSuccessModalContent>
        <h2>Transferindo com sucesso!</h2>
        <p>
            <span>Você transferiu {maskPoints(pointsTransfered)} </span>da sua Conta de Benefícios para sua Conta Reinvestimento.
        </p>
        <S.TransferSuccessModalContentButtons>
            <button className='button-outlined' onClick={closeBottomModal}>Continuar</button>
        </S.TransferSuccessModalContentButtons>
    </S.TransferSuccessModalContent>
}

export default TransferSuccessModalContent