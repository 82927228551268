import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBottomModal } from '../../contexts/BottomModalContext'
import * as S from './style'

function MessageSentModalContent() {
    const { closeBottomModal } = useBottomModal()
    const navigate = useNavigate()

    const handleConfirm = useCallback(() => {
        closeBottomModal()
        navigate('/inicio')
    }, [closeBottomModal, navigate])
    
    return <S.MessageSentModalContent>
        <h2>Mensagem enviada!</h2>
        <p>
            Sua mensagem foi enviada com sucesso e vamos retornar o mais breve possível!
        </p>
        <S.MessageSentModalContentButtons>
            <button className='button-outlined' onClick={handleConfirm}>Entendi!</button>
        </S.MessageSentModalContentButtons>
    </S.MessageSentModalContent>
}

export default MessageSentModalContent