import styled from 'styled-components';
import commentIcon from '../../../assets/icons/tool-comment-icon.png';
import likeIcon from '../../../assets/icons/tool-like-icon.png';

export const HomeBlogSwipper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto; 
    overflow-y: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HomeBlogSwipperPosts = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 36px;
    gap: 10px;

    @media only screen and (min-width: 960px) {
        gap: 16px;
    }
`;

export const HomeBlogSwipperPost = styled.div`
    width: 236px;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 20px;
    box-shadow: 0px 3px 16px #001D4129;
    background-color: var(--white);
    
    @media only screen and (min-width: 960px) {
        width: 311px;
    }
`;

export const HomeBlogSwipperPostImage = styled.img`
    width: 100%;
    max-height: 200px;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
    border-radius: 20px 20px 0 0;
`;
export const HomeBlogSwipperPostTools = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 7px;
    width: 100%;
`;

export const HomeBlogSwipperPostToolLike = styled.button`
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-image: url(${likeIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
`;

export const HomeBlogSwipperPostToolComment = styled.button`
    width: 16px;
    height: 16px;
    background-image: url(${commentIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
`;

export const HomeBlogSwipperPostDate = styled.p`
    color: #88A8A4;
    font-size: 0.75rem;
    margin-left: auto;
`;

export const HomeBlogSwipperPostUser = styled.h2` 
    color: var(--primary);
    font-size: 0.875rem;
    line-height: 0.75rem;
    text-overflow: ellipsis;
    margin-bottom: 6px;
    padding: 0 12px;
    font-family: var(--font-bold);
    width: 100%;
`;

export const HomeBlogSwipperPostText = styled.p`
    display: -webkit-box;
    color: #88A8A4;
    font-size: 0.75rem;
    overflow: auto;
    padding: 0 12px;
    margin-bottom: 32px;
    width: 100%;
    max-height: 105px;

    &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
    }
    
    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border: 0px none #ffffff;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--green-neon);
    }
    &::-webkit-scrollbar-thumb:active {
        background: var(--green-neon);
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
        background: #fff;
    }
    &::-webkit-scrollbar-track:active {
        background: #fff;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
`;
