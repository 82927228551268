import { useEffect } from 'react'
import { useSpecies } from '../../contexts/SpeciesContext'
import GrayHeader from '../GrayHeader'
import HowItWorkTable from '../HowItWorkTable'
import Loader from '../Loader'
import * as S from './style'

interface HowItWorksMidSectionTableProps {
  specieData: any
}

function HowItWorksMidSectionTable ({ specieData }:HowItWorksMidSectionTableProps) {
  const { specieSelected } = useSpecies()

  useEffect(() => {
    console.log(specieData.id)
  }, [specieData])

  if (!specieSelected) return <Loader />

  return <>
    <S.SectionSample>
    {specieData.table.specieKpis.title && <GrayHeader>{specieData.table.specieKpis.title}</GrayHeader>}
      <HowItWorkTable>
        {specieData.table.specieKpis.upperKpis.map((kpi: any, index: number) => <tr key={index} className={specieData.table.specieKpis.upperKpis.length -1 === index ? 'no-border' : ''}>
          <th>{kpi.name}</th>
          {kpi.values.map((value: string) => <td>{value}</td>)}
        </tr>
        )}
        <tr className='no-border rebate'>
          <td>Rebate</td>
          <td colSpan={6}>
            <hr />
          </td>
        </tr>
        {specieData.table.specieKpis.lowerKpis.map((kpi: any, index: number) => <tr key={index} className={specieData.table.specieKpis.lowerKpis.length -1 === index ? 'no-border' : ''}>
          <th>{kpi.name}</th>
          {kpi.values.map((value: string) => <td>{value}</td>)}
        </tr>
        )}
      </HowItWorkTable>
    </S.SectionSample>
    {specieData.table.childrensKpis && specieData.table.childrensKpis.map((children: any, index: any) => <S.SectionSample key={index}>
    {children.title && <GrayHeader>{children.title}</GrayHeader>}
      <HowItWorkTable hasWarning={specieData.id === 1}>
        {children.upperKpis.map((kpi: any, index: number) => <tr key={index} className={children.upperKpis.length -1 === index ? 'no-border' : ''}>
          <th>{kpi.name}</th>
          {kpi.values.map((value: string, index: number) => <td key={index}>{value}</td>)}
        </tr>
        )}
        <tr className='no-border rebate'>
          <td>Rebate</td>
          <td colSpan={6}>
            <hr />
          </td>
        </tr>
        {children.lowerKpis.map((kpi: any, index: number) => <tr key={index} className={children.lowerKpis.length -1 === index ? 'no-border' : ''}>
          <th>{kpi.name}{(specieData.id === 1 && index === 0) && <span style={{ color: "#FF6F64" }}>*</span>}</th>
          {kpi.values.map((value: string) => <td>{value}</td>)}
        </tr>
        )}
      </HowItWorkTable>
    </S.SectionSample>)

    }
  </>
}

export default HowItWorksMidSectionTable