import { useCallback, useState } from 'react';
import greenArrow from '../../assets/green-arrow-icon.png';
import Header from '../../components/Header';
import MenuButton from '../../components/Header/MenuButton';
import instructionImageAndroid1 from './assets/shortcut-instruction-android-1.png';
import instructionImageAndroid2 from './assets/shortcut-instruction-android-2.png';
import instructionImageAndroid3 from './assets/shortcut-instruction-android-3.png';
import instructionImageIphone1 from './assets/shortcut-instruction-iphone-1.png';
import instructionImageIphone2 from './assets/shortcut-instruction-iphone-2.png';
import instructionImageIphone3 from './assets/shortcut-instruction-iphone-3.png';
import * as S from './style';

const deviceOptions = [
    {
        deviceTitle: 'Em um celular Android',
        deviceDescription: 'Usando o Chrome',
        deviceInstruction1: {
            text: 'No Chrome, toque no ícone de três pontos no canto superior direito do navegador.',
            image: instructionImageAndroid1
        },
        deviceInstruction2: {
            text: 'Selecione a opção "Instalar aplicativo".',
            image: instructionImageAndroid2
        },
        deviceInstruction3: {
            text: 'Escreva "Open Farm® ", toque em "Adicionar" e pronto!',
            image: instructionImageAndroid3
        }
    },
    {
        deviceTitle: 'No iPhone',
        deviceDescription: 'Usando o Safari',
        deviceInstruction1: {
            text: 'No Safari, toque no ícone de compartilhar no centro inferior do navegador.',
            image: instructionImageIphone1
        },
        deviceInstruction2: {
            text: 'Na lista de opções, role para baixo e toque em "Adicionar à Tela de Início".',
            image: instructionImageIphone2
        },
        deviceInstruction3: {
            text: 'Escreva "Open Farm® ", toque em "Adicionar" e pronto!',
            image: instructionImageIphone3
        }
    }
]

function AddShortcut () {
    const [deviceShown, setDeviceShown] = useState<number | null>(null)

    const toggleDeviceInstructions = useCallback((index: number) => {
        setDeviceShown(deviceShown === index ? null : index);
    }, [deviceShown])

    return <S.AddShortcutContent>
        <Header element={<MenuButton />} logo={false} pageTitle='Atalho na tela inicial' />
        <S.AddShortcut>
            <S.DeviceInstructionsContainer>
                <h2>Confira o passo a passo de acordo com o tipo do seu celular.</h2>
                <h3>Escolha uma opção:</h3>
                {deviceOptions.map((device, index) => (
                    <S.DeviceInstructionsItem key={index}>
                        <S.DeviceInstructionsItemHeader onClick={() => toggleDeviceInstructions(index)} $isToggled={deviceShown === index}>
                            <div>
                                {device.deviceTitle}
                                <img src={greenArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                            </div>
                            <p>{device.deviceDescription}</p>
                        </S.DeviceInstructionsItemHeader>
                        <S.DeviceInstructionsItemContent $isShown={deviceShown === index}>
                            <S.DeviceInstructionsItemContentInstruction>
                                <div>
                                    <div>1</div>
                                    {device.deviceInstruction1.text}
                                </div>
                                <img src={device.deviceInstruction1.image} alt="Imagem de instrução para adição de atalho" />
                            </S.DeviceInstructionsItemContentInstruction>
                            <S.DeviceInstructionsItemContentInstruction>
                                <div>
                                    <div>2</div>
                                    {device.deviceInstruction2.text}
                                </div>
                                <img src={device.deviceInstruction2.image} alt="Imagem de instrução para adição de atalho" />
                            </S.DeviceInstructionsItemContentInstruction>
                            <S.DeviceInstructionsItemContentInstruction>
                                <div>
                                    <div>3</div>
                                    {device.deviceInstruction3.text}
                                </div>
                                <img src={device.deviceInstruction3.image} alt="Imagem de instrução para adição de atalho" />
                            </S.DeviceInstructionsItemContentInstruction>
                        </S.DeviceInstructionsItemContent>
                    </S.DeviceInstructionsItem>
                ))}
            </S.DeviceInstructionsContainer>
        </S.AddShortcut>
    </S.AddShortcutContent>
}

export default AddShortcut