import * as S from './style'

interface DesktopBannerProps {
  title: string
}

function DesktopBanner({ title }: DesktopBannerProps) {
  return <S.DesktopBanner>
    <S.DesktopBannerTitle>{title}</S.DesktopBannerTitle>
  </S.DesktopBanner>
}

export default DesktopBanner