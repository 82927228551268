import styled from 'styled-components';

export const ForgotPasswordContent = styled.section`
    width: 100%;
    max-width: 485px;
    margin: 0 auto;
    background-color: var(--black);
    overflow: auto;
`;

export const ForgotPassword = styled.div`
    width: 100%;
    min-height: calc(100vh - 70px);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    padding: 36px;
    background-color: var(--white);
    border-radius: 30px 30px 0 0;
    margin-top: 70px;
    margin-bottom: 16px;
    transition: all 0.3s ease;

    @media only screen and (min-width: 960px) {
        height: auto;
        min-height: 586px;
        border-radius: 30px;
    }
    h2 {
        color: var(--black);
        font-size: 1.5rem;
        font-family: var(--font-bold);
        margin-bottom: 12px;
    }
    p {
        color: var(--black);
        font-size: 0.875rem;
        margin-bottom: 24px;
        max-width: 305px;
    }
`;

export const ForgotPasswordSupport = styled.div`
    width: 100%;
    color: var(--black);
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 26px;

    a {
        text-decoration: none;
        font-family: var(--font-bold);
        color: var(--primary);
        line-height: 1.25rem;
        background-color: transparent;
        font-size: 1rem;
    }
`;

export const ForgotPasswordButton = styled.button`
    width: 100%;
    border-radius: 28px;
    background-color: var(--primary);
    color: var(--white);
    font-family: var(--font-bold);
    text-align: center;
    font-size: 1.125rem;
    padding: 16px;
    margin-top: auto;
`;
