import { useNavigate } from "react-router-dom";
import arrowLeft from '../assets/arrow-left.png';
import * as S from './style';

function ReturnPageButton() {
    const navigate = useNavigate()

    return <S.ReturnPageButton onClick={() => navigate(-1)} >
        <img src={arrowLeft} alt="ícone de seta apontando para esquerda (botão de voltar página)" />
    </S.ReturnPageButton>
}

export default ReturnPageButton